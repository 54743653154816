<template>
    <div v-if="isMounted" id="creedit-documents-summary" class="mb-base h-auto">
        <vx-card class="mb-2">
            <div class="post-header flex justify-between mb-2">
                    <div class="flex items-center">
                        <div class="vx-card__title mb-3">
                            <h4>Resumen de documentos para la solicitud de verificación de cuenta.</h4>
                            <vs-button class="mt-2" v-if="documents.length > 0"  size="small" icon-pack="feather" type="border" icon="icon-eye" @click="showAll">Ver todos</vs-button>
                        </div>
                    </div>
            </div>
            <div>
                <vs-table strip :data="documents" noDataText="Sin información">
                    <template slot="thead">
                        <vs-th class="bold">DOCUMENTO</vs-th>
                        <vs-th class="bold">STATUS</vs-th>
                        <vs-th class="bold">FECHA DE RECHAZO</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <template  v-for="(tr, indextr) in data">
                            <vs-tr v-if="indextr <= 2" :data="tr" :key="indextr">
                                <vs-td class="text-sm">
                                    {{tr.name}}
                                </vs-td>
                                <vs-td class="text-sm">
                                    <vs-chip class="bold" :color="get_status_color(tr.is_verified)"> {{tr.is_verified.toUpperCase()}}</vs-chip>
                                </vs-td>
                                <vs-td class="text-sm">
                                    <vx-tooltip
                                        color="dark"
                                        position="top"
                                        :text="logDateFormat(tr.updated_at)">
                                        <span class="text-xs time-tag">{{ timeAgoFormat(tr.updated_at) }}</span>
                                        </vx-tooltip>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </template>
                </vs-table>
            </div>
        </vx-card>

        <vs-popup v-if="isMounted" fullscreen title="Resumen de documentos para la solicitud de verificación de cuenta." :active.sync="showAllDocuments">
            <vs-table strip :data="documents" noDataText="Sin información">
                        <template slot="thead">
                            <vs-th class="bold">DOCUMENTO</vs-th>
                            <vs-th class="bold">COMENTARIOS</vs-th>
                            <vs-th class="bold">STATUS</vs-th>
                            <vs-th class="bold">FECHA DE RECHAZO</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <template  v-for="(tr, indextr) in data">
                                <vs-tr v-if="tr.clasification_2 == 'supplier'" :data="tr" :key="indextr">
                                    <vs-td class="text-sm">
                                        {{tr.name}}
                                    </vs-td>
                                    <vs-td class="text-sm">
                                        {{tr.reject_reason.toUpperCase()}}
                                    </vs-td>
                                    <vs-td class="text-sm">
                                        <vs-chip class="bold" :color="get_status_color(tr.is_verified)"> {{tr.is_verified.toUpperCase()}}</vs-chip>
                                    </vs-td>
                                    <vs-td class="text-sm">
                                        <vx-tooltip
                                            color="dark"
                                            position="top"
                                            :text="logDateFormat(tr.updated_at)">
                                            <span class="text-xs time-tag">{{ timeAgoFormat(tr.updated_at) }}</span>
                                            </vx-tooltip>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </template>
                    </vs-table>
        </vs-popup>

    </div>
    <vx-card v-else class="mb-base h-auto">
        <div class="post-header flex justify-between mb-2">
                    <div class="flex items-center">
                        <div class="vx-card__title mb-3">
                            <h4>Resumen de documentos para la solicitud de verificación de cuenta.</h4>
                        </div>
                    </div>
            </div>
            <p>Sin información que mostrar.</p>
    </vx-card>
</template>

<script>
import dateHelper from "@mixins/dateHelper";

const STATUS = ["rechazado"];
export default {
    name: "credit-documents-summary",
    mixins: [dateHelper],
    props: [],
    data() {
        return {
            documents: [],
            data: [],
            showAllDocuments: false,
            isMounted: false
        };
    },
    async mounted() {
        await this.getDocumentsSummary();
        this.isMounted = true;
    },
    computed: {
        //TODO        
    },
    methods: {
        async getDocumentsSummary(){
            this.showLoading(true);
            try{
                let payload = {is_supplier: true, status: STATUS};
                let response = await axios.post(`/api/project/${this.SupplierId}/getDocumentsSummary`, payload);
                this.data = response.data;
                if(this.data.length != 0){
                    this.documents = this.data[0].user_documents;
                }
            }catch (e) {
                this.warn(e);
            }
            this.showLoading(false);
        }, 
        async showAll() {
            await this.getDocumentsSummary();
            this.showAllDocuments = true;
        },
        get_status_color(status){
            let color = '#f44336'; // rojo
            if(status == "sin verificar"){
                color = "#f39200" // naranja
            } else if (status == "verificar"){
                color = "4caf50"; //verde
            }
            return color;
        },
        get_clasification(c){
            let clasification = "NO ESPECIFICADO";
            switch(c){
                case "applicant": clasification = "SOLICITANTE"; break;
                case "supplier": clasification = "PROVEEDOR"; break;
                case "project": clasification = "PROYECTO"; break;
                case "user": clasification = "USUARIO"; break;
                case "investor": clasification = "INVERSIONISTA"; break;
            }
            return clasification;
        }
    }

}
</script>

<style>

</style>