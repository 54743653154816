<template>
  <vx-card class="mb-base" :title="title">
    <div slot="no-body">
      <div class="post-media-container mb-6 mt-4">
        <ul class="flex post-media-list">
          <li
            class="post-media m-1 w-full">
            <video-player
              ref="player"
              class="media-video-player"
              :options="playerOptions(media)"
              v-once
            />        
          </li>
        </ul>
      </div>
    </div>
  </vx-card>
</template>
<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

export default {
  name: 'video-widget',
  props: ['media', 'title'],
  components: {
    videoPlayer
  },
  computed: {
    playerOptions () {
      return (media) => {
        return {
          height: '360',
          fluid: true,
          autoplay: false,
          //muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: this.media.sources,
          poster: this.media.poster
        }
      }
    }
  }
}
</script>