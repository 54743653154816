<template>
    <div v-if="showComponent" id="new-loans-assigned" class="mb-base">
        <div class="vx-row">
            <div class="vx-col sm:w-full md:w-2/3">
                <vx-card title="¡Tenemos buenas noticias!">
                    <p class="ml-2 mr-2">
                        Gracias a tu compromiso con la Red te asignamos los siguientes proyectos, si estás
                        interesado en ellos <span class="bold">vísitalos en los próximos 3 días hábiles y confirma tu visita en el siguiente 
                        listado</span>. Puedes ver más información dando clic en el solicitante.
                    </p>
                    <div id="collapse">
                        <vs-collapse>
                            <vs-collapse-item v-for="(loan, index) in loans" icon-arrow="info">
                                <div slot="header" class="bold">
                                    {{ loan.applicant.name }} | <span class="primary">{{ loan.familyGroup.name }}</span> - {{ mxnFormat(loan.total_cost) }}
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <div class="flex justify-between mb-0">
                                            <h5 class="mb-1">Detalle de la solicitud</h5>
                                            <div class="flex">
                                                <vs-button @click.stop="requestConfirmAction(loan.id)" size="small" icon-pack="feather" icon="icon-check">Confirmar visita</vs-button>
                                                <vs-button @click.stop="requestConfirmAction(loan.id, true)" class="ml-2" size="small" icon-pack="feather" icon="icon-x" color="danger">No me interesa</vs-button>
                                            </div>
                                            
                                        </div>
                                        <vs-divider></vs-divider>
                                        <ul class="ml-4">
                                            <li><span class="bold">Dirección: </span> {{ loan.applicant.full_address }}</li>
                                            <li><span class="bold">Contacto: </span> {{ phoneFormat(loan.applicant.phone) }} | {{ loan.applicant.email }}</li>
                                            <li><span class="bold">Fecha de asignación: </span>{{ midDateFormat(loan.requested_date) }}</li>
                                            <li><span class="bold">Fecha límite de contacto: </span>{{ midDateFormat(loan.first_contact_limit) }}</li>
                                            <li><span class="bold">Costo aproximado del sistema: </span> {{ mxnFormat(loan.total_cost) }}</li>
                                            <!-- <li><span class="bold">Gasto aproximado CFE: </span> $145,250.25 MXN</li> -->
                                        </ul>
                                    </div>
                                </div>
                            </vs-collapse-item>
                        </vs-collapse>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper.js";
import dateHelper from "@mixins/dateHelper.js";
export default {
    mixins: [formatHelper, dateHelper],
    data: () => ({
        isMounted: false,
        loans: [],
        loanSelected: null,
        isRejected: false,
    }),
    async mounted() {
        await this.getAssignedLoans();
    },
    computed: {
        showComponent() {
            return this.isMounted && this.loans.length > 0;
        }
    },
    methods: {
        async getAssignedLoans() {
            try {
                // this.showLoading(true);
                this.isMounted = false;
                const res = await axios.get(`/api/loan-onboarding/get/${this.SupplierId}/assigned-loans`);
                this.loans = res.data;
                this.isMounted = true;
                // this.showLoading(false);
            } catch (error) {
                
            }
        },
        requestConfirmAction(loanId, isRejected = false) {
            try {
                this.loanSelected = loanId;
                this.isRejected = isRejected;
                if(!isRejected) {
                    this.$vs.dialog({
                        type: 'confirm',
                        color: 'primary',
                        title: 'Confirmar visita',
                        text: `¿Estás seguro de confirmar la visita de este solicitante? Al confirmar comenzaras con el proceso normal de una solicitud de RedGirasol.`,
                        acceptText: "Confirmar visita",
                        cancelText: 'Cancelar',
                        accept: this.doAction
                    });
                } else {
                    this.$vs.dialog({
                        type: 'confirm',
                        color: 'danger',
                        title: 'Rechazar participación',
                        text: `¿Estás seguro de rechazar tu participación en éste proyecto? La solicitud será reasignada
                                y perderás la oportunidad. En caso de rechazarlo cuéntanos tus motivos.`,
                        acceptText: "Rechazar",
                        cancelText: 'Cancelar',
                        accept: this.doAction
                    });
                }
            } catch (error) {
                console.log("Falló el método para confirmar visita.")
            }
        },
        async doAction() {
            try {
                this.showLoading(true);
                const res = await axios.post(`/api/loan-onboarding/post/confirm-visit`, {
                    loan_id: this.loanSelected,
                    is_rejected: this.isRejected
                });
                this.showLoading(false);
                await this.getAssignedLoans();
                this.saveSuccessNotif();

                if(!this.isRejected) {
                    this.$vs.dialog({
                        type: 'confirm',
                        color: 'success',
                        title: 'Visita registrada',
                        text: `Se ha registrado tu visita, te agradecemos por el compromiso que tienes con la Red. Para dar seguimiento a la solicitud, lo puedes hacer como cualquier otra solicitud desde el módulo "Listado de proyectos".`,
                        acceptText: "Ir al detalle",
                        cancelText: 'Cancelar',
                        accept: this.goToProject
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        goToProject() {
            this.$router.replace({
                name: "detalleProyecto",
                params: { id: this.loanSelected }
            });
        },
    }
}
</script>