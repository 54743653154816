export default {
  methods: {
    async getUserIdentity(uid){
      try {
        const res = await axios.get(`api/v1/userInfo/${uid}/getIdentityInfo`);
        return res.data.identity;
      }
      catch (e) {
        return null;
      }
    },
    async getUserIdentityStatus(uid){
      let identity = await this.getUserIdentity(uid);
      let status = 0;
      if(identity !== null){
        this.status = 0;
        status = identity.identity_status;
      }
      return status;
    },    
    async getPersonalIdentity(ppid){
      try {
        const res = await axios.get(`api/v1/userInfo/${ppid}/personal-identity-verification`);
        return res.data.identity;
      }
      catch (e) {
        return null;
      }
    },
    async getPersonalIdentityStatus(ppid){
      let identity = await this.getPersonalIdentity(ppid);
      let status = 0;
      if(identity !== null){
        this.status = 0;
        status = identity.identity_status;
      }
      return status;
    },
    shouldRunMatiProcess(status){
      return status === 0 || status === 4 || status === 5 || status === null;
    },
    matiIsPending(status){
      return status === 0 || status === null;
    },
    matiIsRunning(status){
      return status === 3 || status === 2;
    },
    verificationIsCompleted(status){
      return status === 1;
    },
    verificationIsRejected(status){
      return status === 4;
    },
    verificationIsInProcess(status){
      return status === 3;
    },
    verificationisNewRequired(status){
      return status === 5;
    },
    verificationIsReviewNeeded(status){
      return status === 2;
    },
  }
}