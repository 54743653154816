<template>
    <div>
        <!-- CONSTANCIA DE CONOCIMIENTO -->
        <vx-card v-if="!knowledgeIsSigned" class="p-4">
            <knowledge-contract :isFromOnboarding="true" @updated="checkKnoledgeContractAsSign" />
        </vx-card>

        <!-- CONTRATO DE COMISIÓN MERCANTIL -->
        <div v-else class="p-4">
            <vx-card v-if="isMounted" class="mb-5" title="Firma de Contrato">
                <div class="vx-row p-2">
                    <div class="vx-col w-full p-16 mcc">
                        <latest-supplier-merchant-contract :fill-user-data="true"
                            :signed-date="signatureFormatDate(currentMetaData.acceptance_date)" />
                    </div>
                </div>
                <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Publicidad</p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form">
                      <div class="vx-row">
                        <div :class="colClassFull">
                            <vx-card class="border-primary">
                                <latest-advertising-contract/>
                                <vs-switch class="mt-5" style="width: 90px;" v-model="acceptAdvertising">
                                  <span slot="on">Autorizo</span>
                                  <span slot="off">No autorizo</span>
                                </vs-switch>
                              </vx-card>
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
                <!-- sí está con fad -->
                {{ merchantContractFAD }}
                <template v-if="merchantContractFAD != null && merchantContractFAD.signing_type == 3">
                    <!-- DETALLE DE CONTRATOS -->
                    <p class="bold primary mt-4 ">Detalle de contratos</p>
                    <div class="vx-row">
                        <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
                            <p class="bold mt-4 mb-2">Documentos</p>
                            <ul class="ul-bullet">
                                <li>
                                    Comisión Mercantil
                                </li>
                            </ul>
                        </div>
                        <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
                            <p class="bold mt-4 mb-2">Información adicional</p>
                            <ul class="ul-bullet">
                                <li>
                                    <strong>Creado(s): </strong>{{ regularDateFormat(merchantContractFAD.created_at) }}
                                </li>
                                <li>
                                    <strong>Estado: </strong>{{ translateStatus(merchantContractFAD.status) }}
                                </li>
                                <li>
                                    <strong>Tipo de firma: </strong>{{ signing_type(merchantContractFAD.signing_type) }}
                                </li>
                            </ul>
                        </div>
                        <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
                            <p class="bold mt-4 mb-2">Acciones</p>
                            <template v-if="translateStatus(merchantContractFAD.status) == 'Enviado'">
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <div class="flex items-center">
                                            <vs-button size="small" type="border" icon="open_in_new" class="mb-2 mr-3"
                                                @click.stop="handleFadLink(fadLink.ticket)">Abrir</vs-button>
                                            <vs-button size="small" type="border" icon="content_copy" class="mb-2 mr-3"
                                                @click.stop="copyToClipboard(fadLink.ticket)">Copiar</vs-button>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-else-if="translateStatus(merchantContractFAD.status) == 'Firmado'">
                                <vs-button size="small" type="border" class="mb-2 mr-3" target color="success"
                                    @click="handleDownload(merchantContractFAD.id, 'pdf_signed')">
                                    Descargar PDF<br>firmado
                                </vs-button>

                                <vs-button size="small" type="border" class="mb-2" target color="success"
                                    @click="handleDownload(merchantContractFAD.id, 'xml_signed')">
                                    Descargar XML<br>firmado
                                </vs-button>
                            </template>
                        </div>
                    </div>
                    <!-- DETALLE DE CONTRATOS -->
                </template>
                <template v-else>
                    <vs-button v-if="signData == null" @click.stop="requestSignCMContract()" class="m-4">Firmar
                        contrato</vs-button>
                    <div class="mb-2 p-6" v-else>
                        <vs-divider></vs-divider>
                        <h3 class="primary mb-2">Tu firma</h3>
                        <!-- <h5>Datos de la firma:</h5> -->
                        <p class="my-2"><span class="bold">Nombre del documento: </span>
                            {{ signData.metadata.document }}</p>
                        <p class="my-2"><span class="bold">Versión del documento: </span>
                            {{ signData.metadata.version }}</p>
                        <p class="my-2"><span class="bold">Fecha de aceptación: </span>
                            {{ signatureFormatDate(signData.metadata.acceptance_date) }}</p>
                        <p class="my-2"><span class="bold">Agente: </span> {{ signData.metadata.agent }}</p>
                        <p class="my-2"><span class="bold">IP: </span> {{ signData.metadata.ip }}</p>
                        <p class="my-2"><span class="bold">Método de aceptación: </span>
                            {{ signData.metadata.acceptance_method }}</p>
                        <p class="my-2"><span class="bold">Correo electrónico: </span> {{ signData.metadata.email }}
                        </p>
                        <h5 class="mt-5">Cadena de firma electrónica:</h5>
                        <p class="text-break text-xs my-2">{{ signData.signature }}</p>
                    </div>
                </template>
            </vx-card>
        </div>
    </div>
</template>
  
<script>
import LatestTermsContract from "@views/share/contracts/TermsContract/LatestTermsContract.vue";
import LatestPrivacyContract from "@views/share/contracts/PrivacyContract/LatestPrivacyContract.vue";
import LatestSupplierMerchantContract from "@views/supplier/contracts/LatestSupplierMerchantContract";
import KnowledgeClauses from "@views/supplier/contracts/KnowledgeClauses";
import KnowledgeContract from "../../contracts/KnowledgeContract.vue";
import dateHelper from "@mixins/dateHelper";
import formatHelper from '@components/mixins/formatHelper';
import LatestAdvertisingContract from "@views/share/contracts/AdvertisingContract/LatestAdvertisingContract.vue";

const tutorialFADLink = "https://youtu.be/19npgHmTUi0";
const FADLinkBaseDevelopment = "https://uatwebfad.firmaautografa.com/signList?ticketReq=";
const FADLinkBaseProduction = "https://mobile.firmaautografa.com/main?ticket=";

const columnsSignatory = [
    { title: "FIRMANTE", kye: "signatory", sortable: false }
    , { title: "NOMBRE", kye: "name", sortable: false }
    , { title: "CORREO", kye: "email", sortable: false }
    , { title: "CELULAR", kye: "telephone", sortable: false }
];

export default {
    name: "SupplierOnboardingContractsPage",
    props: ['supplier_id', 'isVerified', 'isPM', 'supplier'],
    data() {
        return {
            fixedContracts: [
                { id: 1, name: 'Términos y condiciones', url: 'terminos-y-condiciones', type: 1, buttonStyle: 'border' },
                { id: 2, name: 'Aviso de privacidad', url: 'aviso-de-privacidad', type: 2, buttonStyle: 'border' },
                { id: 3, name: 'Comisión mercantil', url: 'contrato-de-comision-mercantil-instalador', type: 5, buttonStyle: 'border' },
                { id: 4, name: 'Constancia de Conocimiento', url: 'constancia-de-conocimiento-proveedor', type: 14, buttonStyle: 'filled' }
            ],

            signedContracts: [],
            hasSignedContracts: false,
            allContracts: [],
            termsActive: false,
            privacyActive: false,
            merchantActive: false,
            knowledgeActive: false,
            popupActive: false,
            currentSignature: null,
            isMounted: false,
            currentMetaData: {
                acceptance_date: null,
                agent: null,
                alias: null,
                email: null,
                document: null,
                ip: null,
                password: null,
                validity_date: null,
                version: null,
            },
            currentSupplierId: null,
            merchantContractFAD: null,
            merchantContractFADType: 5,
            tutorialFADLink: tutorialFADLink
            , columnsSignatory: columnsSignatory
            , fadLink: {}
            , FADLinkBase: process.env.VUE_APP_ENV == "production" ? FADLinkBaseProduction : FADLinkBaseDevelopment
            , popupSpecial: false
            , knowledgeContractType: 14
            , knowledgePopUpActive: false
            , knowledgeIsSigned: false
            , CMIsSigned: false
            , CMIsGenerated: false
            , knowledgeClauses: false
            , signData: null
            , acceptAdvertising: true,

        }
    },
    mixins: [dateHelper, formatHelper],
    components: {
        LatestTermsContract,
        LatestPrivacyContract,
        LatestSupplierMerchantContract,
        KnowledgeClauses,
        KnowledgeContract,
        LatestAdvertisingContract
    },
    async mounted() {
        this.isMounted = false
        this.showLoading(true)
        await this.getContractsData();
        this.isMounted = true;
        this.showLoading(false);
        if (!this.CMIsGenerated && this.knowledgeIsSigned) {
            // Generar contrato de comisión mercantil
            await this.generateCMContract();
        }
    },
    computed: {
        accessToken() {
            return this.access_token;
        },
        merchantSignature() {
            if (this.signData != null) {
                return JSON.parse(this.signData.electronic_signature);
            }
            return null;
        }
    },
    methods: {
        async generateCMContract() {
            try {
                const res = await axios.post(`/api/supplier-onboarding/post/generate-contract`, {
                    supplier_id: this.SupplierId
                })
                // console.log(res.data);
            } catch (error) {
                console.log(error);
            }
        },
        requestSignCMContract() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: '¿Estás listo para firmar el contrato?',
                text: `Al aceptar firmar este contrato confirmas que has leído y estás de acuerdo con todas y cada una de las clausulas del << Contrato de Comisión Mercantil >>.`,
                acceptText: "Firmar",
                cancelText: 'Cancelar',
                accept: this.doRequestCMContractSign
            });
        },
        async doRequestCMContractSign() {
            try {
                this.showLoading(true);
                const res = await axios.post(`/api/supplier-onboarding/post/sign-contract`, {
                    supplier_id: this.SupplierId,
                    accept_advertising: this.acceptAdvertising
                })
                this.signData = res.data;
                await this.getContractsData();
                await this.$emit("updated", 1);
                this.showLoading(false);
                this.saveSuccessNotif();
            } catch (error) {
                this.showLoading(false);
                console.log(error);
            }
        },
        async checkKnoledgeContractAsSign() {
            await this.generateCMContract();
            this.knowledgeIsSigned = true;
        },
        async downloadContract(contract) {
            if (contract.isSigned) {
                await this.downloadSignedContract(contract);
            }
            else {
                if (contract.type !== this.knowledgeContractType) { // Constancia conocimiento
                    const url = `${this.ApiDomain}/${contract.url}`;
                    window.open(url, '_blank');
                } else {
                    this.knowledgePopUpActive = true;
                }
            }
        },
        async downloadSignedContract(obj) {
            if (obj.signature != null) {
                this.currentSignature = obj.signature;
                this.currentMetaData = obj.metadata;
                this.popupActive = true;

                switch (obj.type) {
                    case 1:
                        this.termsActive = true;
                        this.privacyActive = false;
                        this.merchantActive = false;
                        this.knowledgeActive = false;
                        break;
                    case 2:
                        this.privacyActive = true;
                        this.termsActive = false;
                        this.merchantActive = false;
                        this.knowledgeActive = false;
                        break;
                    case 5:
                        this.merchantActive = true;
                        this.privacyActive = false;
                        this.termsActive = false;
                        this.knowledgeActive = false;
                        break;
                    case 14:
                        this.merchantActive = false;
                        this.privacyActive = false;
                        this.termsActive = false;
                        this.knowledgeActive = true;
                        break;
                }
            }
        },
        async getContractsData() {
            try {
                const res = await axios.get(`api/supplier/${this.supplier_id}/get-contracts`);
                this.signedContracts = res.data.supplier;
                this.hasSignedContracts = res.data.supplier && res.data.supplier.length > 0;
                let merchantContract = null;
                this.signedContracts.forEach(c => {
                    if (c.hasOwnProperty('contract') && c.contract.type === this.merchantContractFADType) {
                        merchantContract = c;
                    }
                });

                // const merchantContract = res.data.supplier.find(c => c.contract.type === this.merchantContractFADType);
                if (merchantContract !== undefined && merchantContract !== null) {
                    this.merchantContractFAD = merchantContract.supplier_contracts;
                    if(this.merchantContractFAD.signing_type == 3) {
                        this.buildMifielLink(this.merchantContractFAD);
                    }
                    if (this.merchantContractFAD.status == "signed") {
                        this.signData = merchantContract
                        this.CMIsSigned = true;
                    } else if (this.merchantContractFAD.status == "generated" || this.merchantContractFAD.status == "sent") {
                        this.CMIsGenerated = true;
                    }
                }
                const defaultContracts = res.data.default;
                const supContracts = res.data.supplier;
                let allContracts = [];
                defaultContracts.forEach(df => {
                    if (df.type !== 5) {
                        const fsup = this.fixedContracts.find(f => f.type === df.type);
                        let sup = null;
                        if (df.type === 1 || df.type === 2) {
                            try {
                                sup = supContracts.find(f => f.contract.type === df.type);
                                if (sup != null && sup != undefined) {
                                    allContracts.push({
                                        name: df.name,
                                        type: df.type,
                                        url: fsup.url,
                                        isSigned: (sup.signature != null),
                                        signature: sup.signature ?? null,
                                        metadata: sup.metadata ?? null,
                                        buttonStyle: fsup.buttonStyle
                                    });
                                }
                            } catch (error) {
                                allContracts.push({
                                    name: fsup.name,
                                    type: df.type,
                                    url: fsup.url,
                                    isSigned: false,
                                    signature: null,
                                    metadata: null,
                                    buttonStyle: fsup.buttonStyle
                                });
                            }
                        } else if (df.type === 14) {
                            allContracts.push(this.handleKnowledgeContract(fsup, supContracts, df));
                        }
                        this.allContracts = allContracts;
                    }
                });
            }
            catch (e) {
                console.log(e);
            }
        },
        handleKnowledgeContract(fixedContract, contracts, contract) {
            let obj = {
                name: contract.name
                , type: contract.type
                , url: fixedContract.url
                , buttonStyle: fixedContract.buttonStyle
                , isSigned: false
                , signature: null
                , metadata: null
            };
            let index = contracts.findIndex(item => item.status === "generated");
            if (index === -1) {
                const c = contracts.find(item => (item.contract !== undefined ? item.contract.type : item.type) === contract.type);
                if (c !== null && c !== undefined) {
                    if (c.hasOwnProperty('metadata') && c.hasOwnProperty('contract')) {
                        obj.isSigned = (c.signature != null);
                        obj.signature = c.signature ?? null;
                        obj.metadata = c.metadata ?? null;
                        this.knowledgeIsSigned = (c.signature != null);
                    }
                }
            }

            return obj;
        },
        handleSignatories(data) {
            if (data !== undefined && data !== null) {
                let arraySignatories = JSON.parse(data.signatories);
                if (arraySignatories != null) {
                    return [arraySignatories.supplier];
                }
            }
            return [];
        },
        getRoles(role) {
            let r = "";
            switch (role) {
                case "customer":
                    r = "Solicitante";
                    break;
                case "supplier":
                    r = "Proveedor";
                    break;
                case "redgirasol":
                    r = "RedGirasol";
                    break;
                default: r = "Obligado Solidario";
            }

            return r;
        },
        signing_type(wish) {
            let r = "";
            switch (wish) {
                case 1:
                    r = "Manual Autógrafa";
                    break;
                case 2:
                    r = "Firma Electrónica (e.firma)";
                    break;
                case 3:
                    r = "Firma Autógrafa Digital (FAD)";
                    break;
                default: r = "--";
            }
            return r;
        },
        translateStatus(status) {
            if (status == 'generated') {
                return "Generado";
            }
            else if (status == 'sent') {
                return "Enviado";
            }
            else if (status == 'signed') {
                return "Firmado";
            }
        },
        buildMifielLink(contract) {
            if (contract.mifiel_object != null) {
                let requisition = JSON.parse(contract.mifiel_object);
                let index = 0;
                let allTickets = Object.values(requisition.signers);
                // console.log(allTickets);

                allTickets.forEach(ticket => {
                    if (index == 0) {
                        this.fadLink.type = "Proveedor";
                    }
                    this.fadLink.ticket = ticket;
                    index++;
                });
            }
        },
        copyToClipboard(ticket) {
            const el = document.createElement('textarea');
            el.value = `/firma-de-contratos/?reqId=${this.merchantContractFAD.id}&ticket=${ticket}`;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.successNotif("Liga copiada al portapapeles");
        },
        handleFadLink(ticket) {
            // return env("APP_FRONTEND_URL")."/firma-de-contratos/?reqId=".$this->contractId."&ticket=".$this->widgetId;
            window.open(`/firma-de-contratos/?reqId=${this.merchantContractFAD.id}&ticket=${ticket}`, '_blank');
        },
        requestFAD() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Iniciar proceso de firma',
                text: `Estás por iniciar el proceso de firma del Contrato de Comisión Mercantil, este proceso no tomará más de 5 minutos, sin embargo una vez iniciado tendrás un plazo de 7 días para concluirlo. ¿Deseas comenzar el proceso de firma?`,
                acceptText: "Si, comenzar ahora",
                cancelText: 'Cancelar',
                accept: this.doRequestFAD
            });
        },
        async doRequestFAD() {
            try {
                this.showLoading(true)
                const res = await axios.post(`api/supplier/send/merchant-contract/${this.supplier_id}`);
                this.isMounted = false
                await this.getContractsData();
                this.isMounted = true;
                this.successNotifDialog("Proceso iniciado", "Ya puedes firmar el Contrato de Comisión Mercantil a través de la Firma Autógrafa Digital dando clic desde tu dispositivo móvil en la opción <<Abrir>>")
            } catch (e) {
                console.log("No se generó la firma con FAD.");
                this.failedOperationNotif();
            } finally {
                this.showLoading(false);

            }
        },
        handleDownload(contract_id, contract_type) {
            try {
                let url = `${this.ApiDomain}/api/supplier/${contract_type}/contracts/download/contract/${contract_id}/?access_token=${this.accessToken}`;
                window.open(url, "_blank");
            } catch (error) {
                console.error("Algo salió mal con el link del tutorial.", err);
                this.errorNotif();
            }
        }
    }
}
</script>
  
<style scoped>
.mcc {
    overflow: auto;
    max-height: 35rem !important;
}
.vx-card.border-primary{
    border-style: solid;
    border-width: 1px;
}

.primary {
    color: #28de18 !important;
}</style>
