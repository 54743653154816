<template>
  <div class="card-img w-full" :class="border" :title="title">
      <img :src="media.img" alt="user-profile-cover" class="responsive img-c ">
  </div>
</template>
<script>

export default {
  name: 'vertical-image-widget',
  props: ['media', 'title', 'border'],
  computed: {
  }
}
</script>

<style>
  .card-img{
      background-color: #fff;
      -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
      box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
  }
  .orange-border {
      border: solid 3px #f8b551;
      border-radius: 5px;
  }
  .black-border {
      border: solid 3px #3b3a3e;
      border-radius: 5px;
  }
  .h-auto-c {
    min-height: auto !important; 
    max-height: none !important; 
  }
  @media (min-width: 1550px) {
    .img-c {
      height: 30rem !important;
    }
  }

</style>