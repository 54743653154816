<template>
  <div class="vx-col mb-5" :class="needsClasses ? 'lg:w-1/2 md:w-3/4 sm:w-full w-full' : 'w-full'" >
    <p class="mb-4 my-0">Indícanos en que ciudades y estados tienes operación para nuevos proyectos dentro de la Red.</p>
    <vs-button color="primary" type="border" icon-pack="feather" v-if="!disabled" class="mb-4"  @click="addLocationRequest()">Agregar ubicación</vs-button>
      <vs-list class="family_group_list" v-if="locations.length > 0">
        <vs-list-header title="Ubicaciones elegidas"/>
        
        <vs-list-item 
          v-for="(loc, index) in locations"
          :key="index"
          :title="loc.name"
          :subtitle="loc.subname">               
          
          <feather-icon icon="TrashIcon" v-if="!disabled" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="removeItemRequest(loc)" />
        </vs-list-item>
      </vs-list>

      <vs-popup
          title="Agregar ciudades"
          :active.sync="showFormPrompt">
          <div class="con-exemple-prompt">
            <vs-alert v-if="onError" class="h-full mb-5" color="danger">
              <span>{{ errorMssg }}</span>
            </vs-alert>
            <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
              <span>{{ successMssg }}</span>
            </vs-alert>

            <p class="mb-5 bold">{{formPromptIndication}}</p>

            <label for="" class="vs-input--label">Seleccione un estado</label>
            <v-select
              label="text"
              name="state_id"
              class="vs-custom w-full mb-5"
              v-model.lazy="state_id"
              :options="getStatesForSelect"
              :reduce="text => text.value"
              :clearable="false"
              :searchable="true"
              placeholder="Seleccione una opción" 
              @input="onStateChange()"
            >
            <div slot="no-options">No hay coincidencias</div>
            </v-select>

            <ul class="radios-container mb-4">
              <li class="mb-2">
                <vs-radio v-model="presence" :vs-value="0">Tengo presencia en todo el estado</vs-radio>
              </li>
              <li class="mb-2">
                <vs-radio v-model="presence" :vs-value="1">Elegir una ciudad en particular</vs-radio>
              </li>
            </ul>
  
            <label for="" v-if="state_id != null && presence == 1" class="vs-input--label">Seleccione una ciudad</label>
            <v-select
              v-if="state_id != null && presence == 1"
              label="text"
              name="city_id"
              class="vs-custom mb-base"
              v-model.lazy="city_id"
              :options="getCitiesForSelect"
              :reduce="text => text.value"
              :clearable="false"
              :searchable="true"
              placeholder="Seleccione una opción" >
            <div slot="no-options">No hay coincidencias</div>
            </v-select>
            
            <vs-button :disabled="!completeForm || $isLoading" @click="saveCity" color="primary" class="mr-5 mt-2">Agregar</vs-button>

            <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
              
          </div>
        </vs-popup>
    </div>

</template>

<script>
const indicationDef = "Selecciona un estado y define si quieres marcar presencia en todo el estado, o en una ciudad en particular. Puedes agregar todas las ciudades y estados que consideres necesario.";
const errorMssgDef = "Ha ocurrido un error al agregar la ubicación, intente más tarde."
const successMssgDef = "La ubicación ha sido agregada exitosamente"
const statePresenceVal = 0;
const cityPresenceVal = 1;

export default {
  name: 'supplier-cities-control',
  props: {
    states: { type: Array, required: true },
    disabled: {type: Boolean, default: false},
    supplier_id: {type: [String, Number], required: true},
    needsClasses:  {type: Boolean, default: true},
  },
  data(){
    return {
      state_id: null,
      city_id: null,
      presence: null,

      showFormPrompt: false,

      getStatesForSelect: [],
      getCitiesForSelect: [],
      locations: [],

      selectedLocation: null,

      onError: false,
      onSuccess: false,
      added: false,
      errorMssg: errorMssgDef,
      successMssg: successMssgDef,
      formPromptIndication: indicationDef,
    }
  },
  mounted: async function(){
    let options = [];
    this.$props.states.forEach(opt => {
      options.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vx-select 
    })
    this.getStatesForSelect = options;

    await this.loadSavedCities();
  },
  computed: {
    completeForm(){
      if(this.state_id == null || this.presence == null ){
        return false;
      }

      if(this.presence == cityPresenceVal ){
        return (this.city_id != null);
      }
      else {
        return true;
      }
    }
  },
  methods: {
    async loadSavedCities(){
      try {
        let res = await axios.get(`/api/v1/supplier/${this.supplier_id}/getSupplierCities`);
        let cities = res.data.cities;
        let states = res.data.states;
        this.locations = [];

        cities.forEach(f => {
          let folio = `ct_${f.city_id}`;
          let name = `Ciudad: ${f.name}`;
          let subname = `En el estado de ${f.state_name}`;  
          let loc = { id: f.id, folio: folio, name: name, subname: subname, presence: cityPresenceVal };
          this.locations.push(loc);
        });

        states.forEach(f => {
          let folio = `st_${f.state_id}`;
          let name = `Estado: ${f.name}`;
          let subname = "Presencia en todo el estado";
          let loc = { id: f.id, folio: folio, name: name, subname: subname, presence: statePresenceVal };
          this.locations.push(loc);
        });

      }
      catch (e) {
        this.warn(e);
      }      
    },
    removeItemRequest(loc){
      this.selectedLocation = loc;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar ubicación',
        text: `¿Realmente desea eliminar la ubicación ${name}?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {id: this.selectedLocation.id };
        await axios.post('/api/v1/supplier/removeSupplierCity', payload);
        await this.loadSavedCities();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async onStateChange(){
      if(this.state_id == null){
        return;
      }

      try {
        this.city_id = null;
        let res = await axios.get('/api/register/cities/' + this.state_id);
        let options = [];
        res.data.forEach(opt => {
          options.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vx-select 
        });
        this.getCitiesForSelect = options;
      }
      catch (e) {
        this.warn(e);
      }
    },
    async saveCity(){
      this.onSuccess = false;
      this.onError = false;

      let folio = (this.presence == statePresenceVal) ? `st_${this.state_id}` : `ct_${this.city_id}`;
      if(this.locations.find(f => f.folio == folio)){
        return;
      }

      this.showLoading(true);
      try {        
        let payload = (this.presence == statePresenceVal) ? { state_id: this.state_id } : { city_id: this.city_id };
        await axios.post(`/api/v1/supplier/${this.supplier_id}/addSupplierCity`, payload);
        await this.loadSavedCities();
        this.added = true;
        this.onSuccess = true;
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    addLocationRequest(){
      this.resetForm();
      this.showFormPrompt = true;
    },
    resetForm(){
      this.state_id = null
      this.presence = null
      this.city_id = null
      this.onError = null
      this.onSuccess = null      
      this.sent = null
      this.added = null
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },

    /* check validation */
    validate(){
      return this.locations.length > 0;
    }
  }
}
</script>