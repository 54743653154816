<template>
  <div id="mati-popup">
    <vs-popup class="mati-popup" title="Verificación de Identidad" :active.sync="isMatiPopupActive">
      <div class="vx-card" style="box-shadow:none">
        <identity-verification class="ml-2 mr-1" v-if="isMatiPopupActive" 
          :usuario="userIdToActivate"
          :personal_profile_id="personalProfileId"
          :citizenship="citizenship"
          @on-mati-process-started="onMatiProcessStarted"
          @on-mati-process-finished="onMatiProcessFinished"
           />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import IdentityVerification from '@components/mati/IdentityVerification.vue'
import dateHelper from '@mixins/dateHelper';

export default {
  name: "mati-popup",
  mixins: [dateHelper],
  components: {
    IdentityVerification
  },
  props: {
    userIdToActivate: {
      type: [String,Number],
      required: false,
    },
    personalProfileId: {
      type: [String,Number],
      required: false,
    },
    citizenship: {
      type: [String,Number],
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isMatiPopupActive: {
      get() {
        return this.$store.state.isMatiPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", val);
      }
    },
    activePromptShowEvent: {
      get(){
        return this.showEvent;
      },
      set(val){
        this.showEvent = val;
      }
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },
  },
   methods: {
    async onMatiProcessStarted(){
      this.$emit('on-mati-process-started');
    },
    async onMatiProcessFinished(){
      await this.$emit('finished');
    }
  },
};
</script>