<template>
    <div id="pwf-form">
        <vx-card>
            <vs-alert class="mb-4 bold">
                Para actualizar el estado, compártenos la información sobre el proyecto que instalarás sin financiamiento. En caso de no tener toda la información, vuelve más tarde.
            </vs-alert>
            <div class="vx-row mb-4">
                <!-- invested_amount -->
                <div class="vx-col w-1/2">
                    <vs-input 
                        :disabled="isDisabled" 
                        class="w-full mb-4" 
                        ref="currency"
                        type="text" 
                        name="invested_amount"
                        label="Costo de proyecto *" 
                        :min="0"
                        placeholder="Requerido" 
                        v-validate="ruleMoney"
                        v-model.lazy="aliasCurrency" 
                        v-money="money" 
                        :danger="hasError('invested_amount')"
                        :danger-text="getErrorText('invested_amount')" 
                        :success="isSuccessful('invested_amount')"
                        autocomplete="off"
                         />
                </div>
                <!-- installation_date -->
               <div class="vx-col w-1/2">
                    <!-- Campos tipo date -->
                    <label for="installation_date">Fecha de puesta en marcha *</label>
                    <datepicker class="w-full"                       
                        v-model.lazy="payload.installation_date"
                        name="installation_date"
                        description-text="Fecha de puesta en marcha *" 
                        :language="es"
                        v-validate="ruleRequired"
                        :danger="hasError('installation_date')"
                        :danger-text="getErrorText('installation_date')"
                        :success="isSuccessful('installation_date')"
                        placeholder="Requerido"
                        :disabled="isDisabled" 
                        format="dd/MM/yyyy"
                        initial-view="year"
                        :use-utc="true"
                        :disabledDates="disabledDates()"
                        />
               </div>
    
               <!-- tecnologías -->
               <div class="vx-col w-full mb-4">
                   <div class="vx-row">
                        <small class="ml-4 mr-4">Sí no encuentras el módelo en tu catálogo, contáctanos a través del chat para ayudarte.</small>
                        <div class="vx-col w-full">
                        <vs-divider>Paneles</vs-divider>
                        </div>
                        <div class="vx-col w-1/2">
                            <label for="" class="vs-input--label">Marca *</label>
                            <v-select
                                label="text"
                                name="brand_id"
                                class="vs-custom w-full mb-5"
                                v-model.lazy="brand_id"
                                v-validate="ruleRequired"
                                :options="getModalProductBrands(1)"
                                :reduce="text => text.value"
                                :danger="hasError('brand_id')"
                                :danger-text="getErrorText('brand_id')"
                                :success="isSuccessful('brand_id')"
                                :clearable="false"
                                :searchable="true"
                                :placeholder="getModalProductBrands(1).length < 1 ? noDataPlaceholder : selectPlaceholder"
                                :disabled="getModalProductBrands(1).length < 1"
                            >
                            <div slot="no-options">No hay coincidencias</div>
                            </v-select>
                        </div>
                        <div class="vx-col w-1/2">
                            <label for="" class="vs-input--label">Modelo *</label>
                            <v-select
                                label="text"
                                name="product_model_id"
                                class="vs-custom w-full mb-5"
                                v-model.lazy="product_model_id"
                                v-validate="ruleRequired"
                                :options="getModalProductModels"
                                :reduce="text => text.value"
                                :danger="hasError('product_model_id')"
                                :danger-text="getErrorText('product_model_id')"
                                :success="isSuccessful('product_model_id')"
                                :clearable="false"
                                :searchable="true"
                                :placeholder="getModalProductModels.length < 1 ? noDataPlaceholder : selectPlaceholder"
                                :disabled="brand_id == null || getModalProductModels.length < 1"
                            >
                            <div slot="no-options">No hay coincidencias</div>
                            </v-select>
                        </div>

                        <div class="vx-col w-1/2">
                            <vs-input
                                class="w-full mb-4" 
                                label="Cantidad *"
                                name="quantity"
                                placeholder="Requerido"
                                v-validate="ruleRequired"
                                :danger="hasError('quantity')"
                                :danger-text="getErrorText('quantity')"
                                :success="isSuccessful('quantity')"
                                :min="1"
                                type="number"
                                v-model.lazy="quantity" />  
                        </div>
                    </div>
                </div>
               <div class="vx-col w-full mb-4">
                   <div class="vx-row">
                        <div class="vx-col w-full">
                        <vs-divider>Inversores</vs-divider>
                        </div>
                        <div class="vx-col w-1/2">
                            <label for="" class="vs-input--label">Marca *</label>
                            <v-select
                                label="text"
                                name="brand_id_aux"
                                :danger="hasError('brand_id_aux')"
                                :danger-text="getErrorText('brand_id_aux')"
                                :success="isSuccessful('brand_id_aux')"
                                class="vs-custom w-full mb-5"
                                v-model.lazy="brand_id_aux"
                                :options="getModalProductBrands(2)"
                                :reduce="text => text.value"
                                v-validate="ruleRequired"
                                :clearable="false"
                                :searchable="true"
                                :placeholder="getModalProductBrands(2).length < 1 ? noDataPlaceholder : selectPlaceholder"
                                :disabled="getModalProductBrands(2).length < 1"
                            >
                            <div slot="no-options">No hay coincidencias</div>
                            </v-select>
                        </div>
                        <div class="vx-col w-1/2">
                            <label for="" class="vs-input--label">Modelo *</label>
                            <v-select
                                label="text"
                                name="product_model_id_aux"
                                class="vs-custom w-full mb-5"
                                v-model.lazy="product_model_id_aux"
                                :options="getModalProductModelsAux"
                                :reduce="text => text.value"
                                v-validate="ruleRequired"
                                :danger="hasError('product_model_id_aux')"
                                :danger-text="getErrorText('product_model_id_aux')"
                                :success="isSuccessful('product_model_id_aux')"
                                :clearable="false"
                                :searchable="true"
                                :placeholder="getModalProductModelsAux.length < 1 ? noDataPlaceholder : selectPlaceholder"
                                :disabled="brand_id_aux == null || getModalProductModelsAux.length < 1"
                            >
                            <div slot="no-options">No hay coincidencias</div>
                            </v-select>
                        </div>

                        <div class="vx-col w-1/2">
                            <vs-input
                                class="w-full mb-4" 
                                label="Cantidad *"
                                name="quantity_aux"
                                placeholder="Requerido"
                                v-validate="ruleRequired"
                                :danger="hasError('quantity_aux')"
                                :danger-text="getErrorText('quantity_aux')"
                                :success="isSuccessful('quantity_aux')"
                                :min="1"
                                type="number"
                                v-model.lazy="quantity_aux" />  
                        </div>
                    </div>
                </div>

                <div class="vx-col w-full text-right">
                    <vs-button class="mr-2" @click="handleSave()" >Guardar cambios</vs-button>
                    <vs-button color="dark">Cerrar</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
</template>
  
<script>
import inputHelper from "@mixins/inputHelper";
import currencyHelper from "@mixins/currencyHelper.js"
import Datepicker from "vuejs-datepicker";
import {VMoney} from 'v-money'
import { es } from "vuejs-datepicker/dist/locale";
export default {
    name: 'projects-without-finance-control',
    mixins: [
        inputHelper
        , currencyHelper
    ],
    components: {
        Datepicker
    },
    props: {
        currentPotentialApplicant: { required: true, type: Object }
    },
    directives: {
        money: VMoney
    },
    data() {
        return {
            ruleRequired: 'required',
            ruleEmail: 'required|email',
            rulePhone: 'required|digits:10',
            ruleMoney: "required|is_not:$0.00",
            errorMessage: null,
            aliasCurrency: 0,
            es: es,
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                suffix: '',
                precision: 2,
                masked: false
            },
            payload: {
                invested_amount: 0,
                installation_date: null
            },
            technologies: [],
            getProductGroupsForSelect: [],
            getProductBrandsForSelect: [],
            getModelsForSelect: [],
            familyGroupsForSelect: [],
            product_group_id: null,
            brand_id: null,
            brand_id_aux: null,
            product_model_id: null,
            product_model_id_aux: null,
            quantity: null,
            quantity_aux: null,
            family_group_id: 1,
            selectPlaceholder: "Seleccione una opción",
            noDataPlaceholder: "No hay opciones disponibles",
        }
    },
    async mounted() {
        this.aliasCurrency = this.currencyFormat(this.payload.invested_amount);
        await this.getAvailableProducts();
        await this.getCollections();
    },
    computed: {
        isDisabled() {
            return this.AuxGuardName == "executive_manager";
        },
        getModalProductGroups() {
            this.product_group_id = null;
            let collection = this.getProductGroupsForSelect.filter((productGroup) => productGroup.family_group_id == this.family_group_id);
            return collection.map((productGroup) => ({ value: productGroup.id, text: productGroup.name }));
        },
        getModalProductModels() {
            this.product_model_id = null;
            let collection = this.getModelsForSelect.filter((model) => model.product_brand_id == this.brand_id);
            return collection.map((model) => ({ value: model.id, text: model.model }));
        },
        getModalProductModelsAux() {
            this.product_model_id_aux = null;
            let collection = this.getModelsForSelect.filter((model) => model.product_brand_id == this.brand_id_aux);
            return collection.map((model) => ({ value: model.id, text: model.model }));
        },
        completeForm(){
            return this.product_model_id != null && this.quantity != null;
        },
    },
    methods: {
        onSelect({ dialCode }) {
            this.currentPotentialApplicant.country_calling_code = `+${dialCode}`;
        },
        async handleSave() {
            this.showLoading(true);
            try {
                this.errorMessage = null;
                const validity = await this.$validator.validateAll();
                console.log(validity);
                if (!validity) {
                    this.showLoading(false);
                    this.missingFieldsNotif();
                    return;
                }
                await this.doSaveProjectInfo();

                this.showLoading(false);
            } catch (error) {
                this.showLoading(false);
            }
        },
        handleClose() {
            this.$emit('on-close');
        },
        hasError(control) {
            return this.errors.has(control);
        },
        getErrorText(control) {
            return this.errors.first(control);
        },
        isSuccessful(control) {
            const field = this.fields[control];
            return (field && field.required && field.valid && !this.hasError(control));
        },
        clear() {
            this.errors.clear();
            this.errorMessage = null;
        },
        disabledDates() {
            return { from: new Date() };
        },
        async getAvailableProducts() {
            try {
                let {data} = await axios.get('/api/v1/forms/getProductGroups');
                if(data) 
                this.getProductGroupsForSelect = data;

                ({data} = await axios.get('/api/v1/supplier/getProductBrands'));
                if(data) 
                this.getProductBrandsForSelect = data;

                ({data} = await axios.get('/api/v1/supplier/models'));
                if(data) 
                this.getModelsForSelect = data;
            }
            catch (error) {
                console.log(error);
                this.warn(error);
                this.failed = true;	
            }
        },
        async getCollections(){
            try {
                let response = await axios.get('/api/v1/forms/getFamilyGroups');
                let options = [];
                response.data.forEach(opt => {
                options.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vx-select 
                })
                this.familyGroupsForSelect = options;
            } catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        addProduct() {
            console.log("agregar");
        },
        getModalProductBrands(product_group_id) {
            let collection = this.getProductBrandsForSelect.filter((brand) => brand.product_group_id == product_group_id);
            return collection.map((brand) => ({ value: brand.id, text: brand.brand }));
        },

        async doSaveProjectInfo(){
            try {
                this.showLoading(true);
                const payload = {
                    pgm_potential_applicant_id : this.currentPotentialApplicant.id,
                    investment_amount : this.unMoneyFormat(this.aliasCurrency),
                    installation_date : this.payload.installation_date,
                    panels_model : this.product_model_id,
                    panels_quantity : this.quantity,
                    inverter_model : this.product_model_id_aux,
                    inverter_quentity: this.quantity_aux
                }

                const res = await axios.post(`/api/kof/post/projects-without-finance`, payload);
                if(res.data == "success"){
                    this.saveSuccessNotif();
                    this.$emit('on-save');
                } else {
                    this.showLoading(false);
                }
                
            } catch (error) {
                console.error(error);
                this.showLoading(false);
            }
        }
    }

}
</script>
  
<style scope></style>