<template>
    <div id="ListNotes">
        <div class="container-fluid">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vx-card title="Notas." class="mb-2">
                        <div class="vx-row">
                            <div class="vx-col w-full" id="time-line">
                                <div class="mt-2" v-if="getNotes.length > 0">
                                    <vx-timeline :data="getNotes" :isCustom="true"></vx-timeline>
                                </div>
                                <div class="flex justify-center" v-else>
                                    <vs-chip class="mt-6 note-chip" color="dark" transparent>Sin notas.</vs-chip>
                                </div>
                            </div>
                            <vs-divider></vs-divider>
                            <div class="vx-col w-full">
                                <create-note-control
                                    ref="createNoteControl"
                                    :currentUserId="currentUserId"
                                    :potentialApplicantId="potentialApplicantId"
                                    :auxRol="auxRol"
                                    :status="status"
                                    @on-save="handleOnSave()"
                                    @on-close="isCreateNoteModalOpened = false"
                                />
                            </div>
                        </div>
                    </vx-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dateHelper from "@mixins/dateHelper";
import formatHelper from "@mixins/formatHelper";
import CreateNoteControl from "../modals/CreateNoteControl.vue"

  const statusOption = [
    { value: 0, text: "Pendientes de contactar" }
    , { value: 1, text: "Descartados por instalador" }
    , { value: 2, text: "Descartados sin interés del cliente" }
    , { value: 3, text: "Interesados en recibir propuesta" }
    , { value: 4, text: "Con propuesta realizada" }
    , { value: 5, text: "Instalado sin financiamiento" }
    , { value: 6, text: "Problemas de comunicación" }
  ];  
  
export default {
    name: "NotesControl"
    , components: {
        CreateNoteControl
    }
    , props: {
        isProgram: { type: Boolean, default: false }
        , notes: { type: Array, default: false }
        , currentUserId: { type: [Number, String], required: true }
        , potentialApplicantId: { type: [Number, String], required: true }
        , auxRol: { type: [String], default: null }
        , status: { type: [Number, String], required: true }
    }
    , mixins: [dateHelper, formatHelper]
    , data() {
        return {
            isCreateNoteModalOpened: false,
            isReload: false,
            newNotes : []
        }
    }
    , async mounted() {
        await this.reloadNotes();
    }
    , computed: {
        getNotes () {
            // if(this.isReload) {
                return this.newNotes.map((note) => ({
                    title: statusOption.find(x => x.value == note.status).text
                    , desc: note.description
                    , color: "warning"
                    , user: this.getFullName(note)
                    , time: this.timeAgoFormat(note.created_at)
                }));

            // }
            // return this.notes.map((note) => ({
            //     title: statusOption.find(x => x.value == note.status).text
            //     , desc: note.description
            //     , color: "warning"
            //     , user: this.getFullName(note)
            //     , time: this.timeAgoFormat(note.created_at)
            // }));
        }
    }
    , methods: {
        async reloadNotes(){
            try {
                const response = await axios.get(`api/kof/get/notes/${this.potentialApplicantId}`);
                this.newNotes = response.data;
                this.isReload = true;
            } catch (error) {
               console.error(error);
            }
        },
        handleOnSave() {
            this.$emit('on-save');
            this.reloadNotes();
        },
        getFullName(currentItem) {
            if(currentItem.aux_rol == "supplier") {
                let tradename = "";
                if(currentItem.user.supplier) {
                    tradename = currentItem.user.supplier.tradename;
                } else if(currentItem.user.agent) {
                    tradename = currentItem.user.agent.supplier.tradename;
                }
                return tradename;
            } else {
                let fullName = currentItem.user.personal.first_name;
    
                if(currentItem.user.personal.second_name)
                    fullName += ` ${currentItem.user.personal.second_name}`;
    
                fullName += ` ${currentItem.user.personal.last_name_1}`;
    
                if(currentItem.user.personal.last_name_2)
                    fullName += ` ${currentItem.user.personal.last_name_2}`;
                return fullName;
            }

        },
    }
}
</script>

<style>
  #createNotes {
    z-index: 100001 !important;
  }

  #time-line{
    height: 23rem !important;
    overflow: auto !important;
    background-color: #f4f5f766;
    border-radius: 10px;
  }

  .note-chip {
    color: black;
  }

    small.word-break {
        white-space: pre-wrap !important;
    }
  
</style>