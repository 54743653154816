<template>
  <div>
    <vx-card class="mb-5" title="Contratos generales.">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>
            Consulta los contratos que todos los integrantes de la Red aceptan al entrar a nuestra comunidad.
          </p>
        </div>
      </div>
      <div class="vx-row mt-6" v-if="allContracts.length > 0 && isMounted">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button v-for="(contract, idx ) in allContracts" :type="contract.buttonStyle"
              class="sm:px-base px-4 mr-4 mb-3" :key="idx" @click="downloadContract(contract)">
              {{ contract.name }}
            </vs-button>
          </div>
        </div>
      </div>

      <div class="vx-row mt-6" v-if="!isMounted">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <p>Cargando información...</p>
          </div>
        </div>
      </div>
    </vx-card>
    <vx-card v-if="isMounted && merchantContractFAD != null && merchantContractFAD.signing_type == 3" class="mb-5" title="Contrato Comisión Mercantil.">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p><strong>Tipo de firma:</strong> Firma Autógrafa Digital (FAD). </p>
          <p><strong>Nota:</strong> Se requiere la firma de este contrato para la verificación del perfil.</p>
          <p class="mt-2">
            Considera que necesitarás un dispositivo móvil (celular o tablet) para realizar la Firma Autógrafa Digital
            (FAD). Además, será necesario que ingreses los últimos 4 dígitos del celular que registraste y que se indica
            en la tabla de firmantes.
            <a class="bold" :href="tutorialFADLink" target="_blank">¿Tienes dudas? Consulta este tutorial sobre cómo
              firmar un contrato con Firma Autógrafa Digital (FAD) dando clic aquí.</a>
          </p> <br>
          <!-- TABLA DE FIRMANTES -->
          <p class="bold primary mt-4">Por favor valida que los datos de correo y celular sean correctos. </p>
          <vs-table class="mt-2 mb-8" :data="handleSignatories(merchantContractFAD)"
            noDataText="No hay datos disponibles." stripe>
            <template slot="thead">
              <vs-th class="bold" v-for="(col, idx) in columnsSignatory" v-bind:key="idx"
                :sort-key="col.sortable ? col.key : null">{{ col.title }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].type">
                  {{ getRoles(data[indextr].type) }}
                </vs-td>
                <vs-td :data="data[indextr].name">
                  {{ data[indextr].name != null && data[indextr].name != "" ? data[indextr].name : "Sin información" }}
                </vs-td>
                <vs-td :data="data[indextr].email">
                  {{ data[indextr].email != null && data[indextr].email != "" ? data[indextr].email : "Sin información"
                  }}
                </vs-td>
                <vs-td :data="data[indextr].telephone">
                  {{ data[indextr].telephone != null && data[indextr].telephone != "" ?
        phoneFormat(data[indextr].telephone) : "Sin información" }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!-- TABLA DE FIRMANTES -->
          <vs-divider></vs-divider>
          <!-- DETALLE DE CONTRATOS -->
          <p class="bold primary mt-4 ">Detalle de contratos</p>
          <div class="vx-row">
            <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
              <p class="bold mt-4 mb-2">Documentos</p>
              <ul class="ul-bullet">
                <li>
                  Comisión Mercantil
                </li>
              </ul>
            </div>
            <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
              <p class="bold mt-4 mb-2">Información adicional</p>
              <ul class="ul-bullet">
                <li>
                  <strong>Creado(s): </strong>{{ regularDateFormat(merchantContractFAD.created_at) }}
                </li>
                <li>
                  <strong>Estado: </strong>{{ status(merchantContractFAD) }}
                </li>
                <li>
                  <strong>Tipo de firma: </strong>{{ signing_type(merchantContractFAD.signing_type) }}
                </li>
              </ul>
            </div>
            <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
              <p class="bold mt-4 mb-2">Acciones</p>
              <template v-if="status(merchantContractFAD) == 'Enviado'">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="flex items-center">
                      <vs-button size="small" type="border" icon="open_in_new" class="mb-2 mr-3"
                        @click.stop="handleFadLink(fadLink.ticket)">Abrir</vs-button>
                      <vs-button size="small" type="border" icon="content_copy" class="mb-2 mr-3"
                        @click.stop="copyToClipboard(fadLink.ticket)">Copiar</vs-button>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else-if="status(merchantContractFAD) == 'Firmado'">
                <vs-button size="small" type="border" class="mb-2 mr-3" target color="success"
                  @click="handleDownload(merchantContractFAD.id, 'pdf_signed')">
                  Descargar PDF<br>firmado
                </vs-button>

                <vs-button size="small" type="border" class="mb-2" target color="success"
                  @click="handleDownload(merchantContractFAD.id, 'xml_signed')">
                  Descargar XML<br>firmado
                </vs-button>
              </template>
            </div>
          </div>
          <!-- DETALLE DE CONTRATOS -->
        </div>
      </div>
    </vx-card>

    <vx-card v-else class="mb-5" title="Contrato Comisión Mercantil.">
      <p class="mb-2">
        Es de suma importancia que firmes el Contrato de Comisión Mercantil ya que este es uno de los documentos que te
        permiten operar dentro de la Red. Sin él no podrás llevar a cabo actividades fundamentales de la plataforma,
        como la
        recepción y aprobación de una de Solicitud de Crédito o recibir pagos de tus proyectos o instalaciones.
      </p>
      <p v-if="!isVerified" class="mb-3">
        Es necesario completar la información de tu perfil y la verificación de identidad del <strong>{{ isPM ?
        'Representante Legal' : 'Responsable / Dueño del Negocio' }}</strong>; una vez completado esto, aparecera un
        botón
        para poder generarlo y posteriormente firmarlo.
      </p>

      <div class="vx-row">
        <div class="vx-col w-full">
          <!-- TABLA DE FIRMANTES -->
          <p class="bold primary mt-4">Por favor valida que los datos de correo y celular sean correctos. </p>
          <vs-table class="mt-2 mb-8" :data="supplier" noDataText="No hay datos disponibles." stripe>
            <template slot="thead">
              <vs-th class="bold" v-for="(col, idx) in columnsSignatory" v-bind:key="idx"
                :sort-key="col.sortable ? col.key : null">{{ col.title }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].type">
                  {{ getRoles(data[indextr].type) }}
                </vs-td>
                <vs-td :data="data[indextr].name">
                  {{ data[indextr].name != null && data[indextr].name != "" ? data[indextr].name : "Sin información" }}
                </vs-td>
                <vs-td :data="data[indextr].email">
                  {{ data[indextr].email != null && data[indextr].email != "" ? data[indextr].email : "Sin información"
                  }}
                </vs-td>
                <vs-td :data="data[indextr].telephone">
                  {{ data[indextr].telephone != null && data[indextr].telephone.phone != "" ? `${data[indextr].telephone.country_calling_code} ${phoneFormat(data[indextr].telephone.phone)}` : "Sin información" }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <!-- TABLA DE FIRMANTES -->
      </div>

      <div class="vx-col w-full ">
        <div class="flex flex-wrap items-center">
          <vs-button type="border" class="sm:px-base px-4 mr-4 mb-3" @click="popupSpecial = true">
            Ver Contrato Comisión Mercantil
          </vs-button>
          <!-- <vs-button v-if="isVerified" class="sm:px-base px-4 mr-4 mb-3" @click="requestFAD()">
            Generar Contrato Comisión Mercantil
          </vs-button> -->
        </div>
      </div>
    </vx-card>
    <vs-popup fullscreen :active.sync="popupActive" title="Contrato">
      <vx-card class="mb-5" title="EL CONTRATO" title-color="primary">
        <latest-terms-contract v-if="termsActive" />
        <latest-privacy-contract v-if="privacyActive" />
        <knowledge-clauses v-if="knowledgeActive" />
        <latest-advertising-contract v-if="advertisingActive" />
      </vx-card>
      <vx-card class="mb-2" title-color="primary" title="TU FIRMA">
        <h5>Datos de la firma:</h5>
        <p class="my-2"><span class="bold">Nombre del documento: </span> {{ currentMetaData.document }}</p>
        <p class="my-2"><span class="bold">Versión del documento: </span> {{ currentMetaData.version }}</p>
        <p class="my-2"><span class="bold">Fecha de aceptación: </span>
          {{ signatureFormatDate(currentMetaData.acceptance_date) }}</p>
        <p class="my-2"><span class="bold">Agente: </span> {{ currentMetaData.agent }}</p>
        <p class="my-2"><span class="bold">IP: </span> {{ currentMetaData.ip }}</p>
        <!--p class="my-2"><span class="bold">Validez del documento: </span> {{signatureValidityDate(currentMetaData.validity_date)}}</p-->
        <p class="my-2"><span class="bold">Correo electrónico: </span> {{ currentMetaData.email }}</p>


        <h5 class="mt-5">Cadena de firma electrónica:</h5>
        <p class="text-break text-xs my-2">{{ currentSignature }}</p>
      </vx-card>
    </vs-popup>

    <vs-popup fullscreen :active.sync="popupSpecial" title="Contrato">
      <vs-button v-if="signData != null" class="sm:px-base px-4 ml-2 mb-4" @click="downloadPdfContract">Descargar</vs-button>
      <vs-button v-if="signData == null" @click.stop="requestSignCMContract()" class="m-4">Firmar contrato</vs-button>
      <vx-card class="mb-5" title="EL CONTRATO" title-color="primary">
        <latest-supplier-merchant-contract :fill-user-data="true"
          :signed-date="signatureFormatDate(currentMetaData.acceptance_date)" />
          <vx-card v-if="isMounted && signData != null" class="mb-2 p-6">
              <h3 class="text-primary mb-2">Tu firma</h3>
              <vs-divider></vs-divider>
              <!-- <h5>Datos de la firma:</h5> -->
              <p class="my-2"><span class="bold">Nombre del documento: </span>
                  {{ signData.metadata.document }}</p>
              <p class="my-2"><span class="bold">Versión del documento: </span>
                  {{ signData.metadata.version }}</p>
              <p class="my-2"><span class="bold">Fecha de aceptación: </span>
                  {{ signatureFormatDate(signData.metadata.acceptance_date) }}</p>
              <p class="my-2"><span class="bold">Agente: </span> {{ signData.metadata.agent }}</p>
              <p class="my-2"><span class="bold">IP: </span> {{ signData.metadata.ip }}</p>
              <p class="my-2"><span class="bold">Método de aceptación: </span>
                  {{ signData.metadata.acceptance_method }}</p>
              <p class="my-2"><span class="bold">Correo electrónico: </span> {{ signData.metadata.email }}
              </p>
              <h5 class="mt-5">Cadena de firma electrónica:</h5>
              <p class="text-break text-xs my-2">{{ signData.signature }}</p>
          </vx-card>
      </vx-card>
    </vs-popup>
  </div>
</template>

<script>
import LatestTermsContract from "@views/share/contracts/TermsContract/LatestTermsContract.vue";
import LatestPrivacyContract from "@views/share/contracts/PrivacyContract/LatestPrivacyContract.vue";
import LatestSupplierMerchantContract from "@views/supplier/contracts/LatestSupplierMerchantContract";
import LatestAdvertisingContract from "@views/share/contracts/AdvertisingContract/LatestAdvertisingContract.vue";
import KnowledgeClauses from "@views/supplier/contracts/KnowledgeClauses";
import dateHelper from "@mixins/dateHelper";
import formatHelper from '@components/mixins/formatHelper';

const tutorialFADLink = "https://youtu.be/19npgHmTUi0";
const FADLinkBaseDevelopment = "https://uatwebfad.firmaautografa.com/signList?ticketReq=";
const FADLinkBaseProduction = "https://mobile.firmaautografa.com/main?ticket=";

const columnsSignatory = [
  { title: "FIRMANTE", kye: "signatory", sortable: false }
  , { title: "NOMBRE", kye: "name", sortable: false }
  , { title: "CORREO", kye: "email", sortable: false }
  , { title: "CELULAR", kye: "telephone", sortable: false }
];

export default {
  name: "ContractsPage",
  props: ['supplier_id', 'isVerified', 'isPM', 'supplier'],
  data() {
    return {
      fixedContracts: [
        { id: 1, name: 'Términos y condiciones', url: 'terminos-y-condiciones', type: 1, buttonStyle: 'border' },
        { id: 2, name: 'Aviso de privacidad', url: 'aviso-de-privacidad', type: 2, buttonStyle: 'border' },
        { id: 3, name: 'Comisión mercantil', url: 'contrato-de-comision-mercantil-instalador', type: 5, buttonStyle: 'border' },
        { id: 4, name: 'Constancia de Conocimiento', url: 'constancia-de-conocimiento-proveedor', type: 14, buttonStyle: 'filled' },
        { id: 5, name: 'Consentimiento Publicidad', url: '', type: 22, buttonStyle: 'border' }
      ],

      signedContracts: [],
      hasSignedContracts: false,
      allContracts: [],
      advertisingActive: false,
      termsActive: false,
      privacyActive: false,
      merchantActive: false,
      knowledgeActive: false,
      popupActive: false,
      currentSignature: null,
      isMounted: false,
      currentMetaData: {
        acceptance_date: null,
        agent: null,
        alias: null,
        email: null,
        document: null,
        ip: null,
        password: null,
        validity_date: null,
        version: null,
      },
      currentSupplierId: null,
      merchantContractFAD: null,
      merchantContractFADType: 5,
      tutorialFADLink: tutorialFADLink
      , columnsSignatory: columnsSignatory
      , fadLink: {}
      , FADLinkBase: process.env.VUE_APP_ENV == "production" ? FADLinkBaseProduction : FADLinkBaseDevelopment
      , popupSpecial: false
      , knowledgeContractType: 14
      , signData: null
    }
  },
  mixins: [dateHelper, formatHelper],
  components: {
    LatestTermsContract,
    LatestPrivacyContract,
    LatestSupplierMerchantContract,
    KnowledgeClauses,
    LatestAdvertisingContract
  },
  async mounted() {
    this.isMounted = false
    this.showLoading(true)
    await this.getContractsData();
    this.isMounted = true;
    this.showLoading(false);
  },
  computed: {
    accessToken() {
      return this.access_token;
    }
  },
  methods: {
    async downloadContract(contract) {
      if (contract.isSigned) {
        await this.downloadSignedContract(contract);
      }
      else {
        if (contract.type !== this.knowledgeContractType) { // Constancia conocimiento
          const url = `${this.ApiDomain}/${contract.url}`;
          window.open(url, '_blank');
        } else {
          this.$router.push({ name: 'KnowledgeContract' });
        }
      }
    },

    async downloadSignedContract(obj) {
      if (obj.signature != null) {
        this.currentSignature = obj.signature;
        this.currentMetaData = obj.metadata;
        this.popupActive = true;

        switch (obj.type) {
          case 1:
            this.termsActive = true;
            this.privacyActive = false;
            this.merchantActive = false;
            this.knowledgeActive = false;
            this.advertisingActive = false;
            break;
          case 2:
            this.privacyActive = true;
            this.termsActive = false;
            this.merchantActive = false;
            this.knowledgeActive = false;
            this.advertisingActive = false;
            break;
          case 5:
            this.merchantActive = true;
            this.privacyActive = false;
            this.termsActive = false;
            this.knowledgeActive = false;
            this.advertisingActive = false;
            break;
          case 14:
            this.merchantActive = false;
            this.privacyActive = false;
            this.termsActive = false;
            this.knowledgeActive = true;
            this.advertisingActive = false;
            break;
          case 22:
            this.merchantActive = false;
            this.privacyActive = false;
            this.termsActive = false;
            this.knowledgeActive = false;
            this.advertisingActive = true;
            break;
        }
      }
    },
    async getContractsData() {
      try {
        const res = await axios.get(`api/supplier/${this.supplier_id}/get-contracts`);
        this.signedContracts = res.data.supplier;
        this.hasSignedContracts = res.data.supplier && res.data.supplier.length > 0;
        const merchantContract = res.data.supplier.find(c => c.contract.type === this.merchantContractFADType);
        if (
          merchantContract !== undefined
          && merchantContract !== null) {
          this.merchantContractFAD = merchantContract.supplier_contracts;
          if(this.merchantContractFAD.signing_type === 3 && this.merchantContractFAD.fad_requisition === null) {
            this.buildFADLinks(this.merchantContractFAD);
          }
          if (this.merchantContractFAD.status == "signed") {
            this.signData = merchantContract;
          }
        }
        const defaultContracts = res.data.default;
        const supContracts = res.data.supplier;
        let allContracts = [];
        defaultContracts.forEach(df => {
          if (df.type !== 5) {
            const fsup = this.fixedContracts.find(f => f.type === df.type);
            let sup = null;
            if (df.type === 1 || df.type === 2 || df.type === 22) {
              try {
                sup = supContracts.find(f => f.contract.type === df.type);
                if (sup != null && sup != undefined) {
                  let addToContracts = true;
                  if (df.type == 22 && (sup.signature == null)) {
                    addToContracts=false;
                  }
                  if (addToContracts) {
                    allContracts.push({
                      name: df.name,
                      type: df.type,
                      url: fsup.url,
                      isSigned: (sup.signature != null),
                      signature: sup.signature ?? null,
                      metadata: sup.metadata ?? null,
                      buttonStyle: fsup.buttonStyle
                    });
                  }
                } else {
                  if (df.type != 22) {
                    allContracts.push({
                      name: fsup.name,
                      type: df.type,
                      url: fsup.url,
                      isSigned: false,
                      signature: null,
                      metadata: null,
                      buttonStyle: fsup.buttonStyle
                    });
                  }
                }
              } catch (error) {
                console.log(error);
              }
            } else if (df.type === 14) {
              allContracts.push(this.handleKnowledgeContract(fsup, supContracts, df));
            }
            this.allContracts = allContracts;
          } 
        });
      }
      catch (e) {
        console.log(e);
      }
    },
    handleKnowledgeContract(fixedContract, contracts, contract) {
      let obj = {
        name: contract.name
        , type: contract.type
        , url: fixedContract.url
        , buttonStyle: fixedContract.buttonStyle
        , isSigned: false
        , signature: null
        , metadata: null
      };
      let index = contracts.findIndex(item => item.status === "generated");
      if (index === -1) {
        const c = contracts.find(item => (item.contract !== undefined ? item.contract.type : item.type) === contract.type);
        if (c !== null && c !== undefined) {
          if (c.hasOwnProperty('metadata') && c.hasOwnProperty('contract')) {
            obj.isSigned = (c.signature != null);
            obj.signature = c.signature ?? null;
            obj.metadata = c.metadata ?? null;
          }
        }
      }

      return obj;
    },
    handleSignatories(data) {
      if (data !== undefined && data !== null) {
        let arraySignatories = JSON.parse(data.signatories);
        if (arraySignatories != null) {
          return [arraySignatories.supplier];
        }
      }
      return [];
    },
    getRoles(role) {
      let r = "";
      switch (role) {
        case "customer":
          r = "Solicitante";
          break;
        case "supplier":
          r = "Proveedor";
          break;
        case "redgirasol":
          r = "RedGirasol";
          break;
        default: r = "Obligado Solidario";
      }

      return r;
    },
    signing_type(wish) {
      let r = "";
      switch (wish) {
        case 1:
          r = "Manual Autógrafa";
          break;
        case 2:
          r = "Electrónico";
          break;
        case 3:
          r = "Firma Autógrafa Digital (FAD)";
          break;
        default: r = "--";
      }
      return r;
    },

    status(contract) {
      if (contract.status == 'generated') {
        return "Generado";
      }
      else if (contract.status == 'sent') {
        return "Enviado";
      }
      else if (contract.status == 'signed') {
        return "Firmado";
      }
    },
    buildFADLinks(contract) {
      if (contract.fad_requisition != null) {
        let requisition = JSON.parse(contract.fad_requisition);
        let index = 0;
        let allTickets = Object.values(requisition.allTickets);

        allTickets.forEach(ticket => {
          if (index == 0) {
            this.fadLink.type = "Proveedor";
          } else {
            this.fadLink.type = "N/A";
          }
          this.fadLink.ticket = ticket;
          index++;
        });
      }
    },
    copyToClipboard(ticket) {
      const el = document.createElement('textarea');
      el.value = this.FADLinkBase + ticket;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.successNotif("Liga copiada al portapapeles");
    },
    handleFadLink(ticket) {
      window.open(this.FADLinkBase + ticket, '_blank');
    },
    requestFAD() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Iniciar proceso de firma',
        text: `Estás por iniciar el proceso de firma del Contrato de Comisión Mercantil, este proceso no tomará más de 5 minutos, sin embargo una vez iniciado tendrás un plazo de 7 días para concluirlo. ¿Deseas comenzar el proceso de firma?`,
        acceptText: "Si, comenzar ahora",
        cancelText: 'Cancelar',
        accept: this.doRequestFAD
      });
    },
    async doRequestFAD() {
      try {
        this.showLoading(true)
        const res = await axios.post(`api/supplier/send/merchant-contract/${this.supplier_id}`);
        this.isMounted = false
        await this.getContractsData();
        this.isMounted = true;
        this.successNotifDialog("Proceso iniciado", "Ya puedes firmar el Contrato de Comisión Mercantil a través de la Firma Autógrafa Digital dando clic desde tu dispositivo móvil en la opción <<Abrir>>")
      } catch (e) {
        console.log("No se generó la firma con FAD.");
        this.failedOperationNotif();
      } finally {
        this.showLoading(false);

      }
    },
    handleDownload(contract_id, contract_type) {
      try {
        let url = `${this.ApiDomain}/api/supplier/${contract_type}/contracts/download/contract/${contract_id}/?access_token=${this.accessToken}`;
        window.open(url, "_blank");
      } catch (error) {
        console.error("Algo salió mal con el link del tutorial.", err);
        this.errorNotif();
      }
    },
    requestSignCMContract() {
      this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: '¿Estás listo para firmar el contrato?',
          text: `Al aceptar firmar este contrato confirmas que has leído y estás de acuerdo con todas y cada una de las clausulas del << Contrato de Comisión Mercantil >>.`,
          acceptText: "Firmar",
          cancelText: 'Cancelar',
          accept: this.doRequestCMContractSign
      });
    },
    async doRequestCMContractSign() {
        try {
            this.showLoading(true);
            const res = await axios.post(`/api/supplier-onboarding/post/sign-contract`, {
                supplier_id: this.SupplierId
            })
            this.signData = res.data;
            await this.getContractsData();
            this.showLoading(false);
            this.saveSuccessNotif();
        } catch (error) {
            this.showLoading(false);
            console.log(error);
        }
    },
    async downloadPdfContract(){
      try {
        let url = `${this.ApiDomain}/api/supplier/${this.SupplierId}/downloadPdfContract/${this.merchantContractFADType}?access_token=${this.AccessToken}&2fa_restart_cookie=true`;
        window.open(url, "_blank");
      }
      catch (e) {
        console.log(e);
        this.warn(e)
      }
    },
  }
}
</script>

<style scoped></style>