<template>
    <vx-card class="mb-64 video-card" :title="title">
        <div slot="no-body">
            <div class="post-media-container mb-6 mt-4">
                <ul class="flex post-media-list">
                    <li class="post-media m-1 w-full">
                        <iframe class="yt_player_iframe" width="100%" height="150%"
                            :src="$sanitizeUrl(media)"
                            :title="title"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </li>
                </ul>
            </div>
        </div>
    </vx-card>
</template>
<script>

export default {
    name: 'youtube-widget',
    props: ['media', 'title'],
    computed: {
    }
}
</script>

<style>

.video-card {
    /* height: 125% !important; */
    margin-bottom: 6rem;

}

</style>