<template>
    <div id="discard-dashboard">
        <div class="vx-row">
            <div v-if="supplierId == null" class="vx-col w-full mb-6">
                <vx-card title="Filtros">
                    <div class="flex pl-2 mb-4">
                        <vs-select
                            v-if="programIdSelected !== null"
                            label="Zonas"
                            v-model="zoneIdSelected"
                            @change="addFilter('zone_id', $event)"
                            class="mr-2">
                            <vs-select-item text="Todas" value="0"></vs-select-item>
                            <vs-select-item
                                v-for="zone in programSelected.zones"
                                :text="zone.name"
                                :key="zone.id"
                                :value="zone.id"></vs-select-item>
                        </vs-select>

                        <vs-select
                            :disabled="zoneIdSelected === 0 && zoneSelected.regions.length < 0"
                            label="Regiones"
                            v-model="regionIdSelected"
                            @change="addFilter('region_id', $event)">
                            <vs-select-item text="Todas" value="0"></vs-select-item>
                            <vs-select-item
                                v-for="region in zoneSelected.regions"
                                :text="region.name"
                                :key="region.id"
                                :value="region.id"></vs-select-item>
                        </vs-select>
                        <div class="w-1/3 mr-2">
                        <label class="ml-4 mb-0" for="">Proveedores</label>
                            <v-select
                                label="text"
                                v-model.lazy="supplierSelected"
                                class="vs-custom w-full ml-3"
                                v-validate="'required'"
                                name="sourceType"
                                placeholder="Seleccione una opción"
                                :clearable="false"
                                :searchable="true"
                                :options="getSuppliersList"
                                :reduce="text => text.value"
                                >
                            <div slot="no-options">No hay coincidencias</div> 
                            </v-select>
                        </div>
                        <vs-button @click.stop="getDiscardReport()" type="filled" class="mt-6 ml-4" icon="search"></vs-button>
                    </div>
                </vx-card>
            </div>

            <div class="vx-col w-full">
                <vx-card title="Prospectos descartados.">
                    <vs-table :data="discardedInfo" noDataText="Sin información">
                        <template slot="thead">
                            <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <template v-for="(tr, indextr) in data">
                                <vs-tr :key="indextr + '_rg'" v-if="indextr == 0 || indextr == 7">
                                    <vs-td class="bold td-red">
                                        {{indextr == 0 ? 'Descartado por instalador' : 'Descartado sin interés del cliente'}}
                                    </vs-td>
                                    <vs-td class="td-red"></vs-td>
                                    <vs-td class="td-red"></vs-td>
                                </vs-tr>

                                <vs-tr :key="indextr">
                                    <vs-td>
                                        {{tr.text}}
                                    </vs-td>
                                    <vs-td>
                                        {{tr.value}}
                                    </vs-td>
                                    <vs-td>
                                        <div class="flex">
                                            <vs-progress :percent="tr.percentage.toFixed(0)" :height="10" color="danger" class="mr-4 mt-2"></vs-progress>
                                            <span>{{tr.percentage.toFixed(0)}}%</span>
                                        </div>
                                    </vs-td>
                                </vs-tr>

                                <vs-tr :key="indextr + '_rg'" v-if="indextr == 6 || indextr == 12">
                                    <vs-td class="bold">
                                        Total
                                    </vs-td>
                                    <vs-td class="bold">
                                        {{indextr == 6 ? discardedInfo[0].total :  discardedInfo[7].total }}
                                    </vs-td>
                                    <vs-td class="bold">
                                        100%
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </template>
                    </vs-table>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
const columns = [
    { title: "OPCIÓN", key: "option", sortable: false }
    , { title: "CANTIDAD", key: "value", sortable: false }
    , { title: "PORCENTAJE", key: "percentage", sortable: false }
];
export default {
    name: "DiscardedDashboard",
    props: {
        supplierId: {
            type: [String, Number, ],
            required: false,
            default: null
        }
    },
    data() {
        return {
            discardedInfo: []
            , columns: columns
            , filters: {}
            , programs: []
            , zoneIdSelected: 0
            , regionIdSelected: 0
            , supplierSelected: 0
            , collections: {
               suppliers: []
            }
        }
    },
    computed: {
        programSelected() {
            let program = this.programs.filter(p => p.id === this.programIdSelected)
            return program[0] ?? null;
        },
        zoneSelected() {
            let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
            return zone[0] ?? {regions: []};
        },
        getSuppliersList() {
			return this.collections.suppliers.map((supplier) => ({ value: supplier.id, text: supplier.select_name }));
		},
        programIdSelected () {
            return this.currentProgramData.id;
        }
    },
    watch: {
        zoneIdSelected: function () {
            this.getCollections();
        },
        regionIdSelected: function () {
            this.getCollections();
        },
    },
    async created() {
        if(this.supplierId == null){
            const {data} = await axios.get(`/api/pgm/me/programs`)
            this.programs = data;
        } else {
            this.getDiscardReport(this.supplierId);
        }
        this.getCollections();
    },
    methods: {
        async getDiscardReport(supplierId = this.supplierSelected) {
            try {
                this.showLoading(true);
                const res = await axios.get(`/api/kof/get/pgm-potential-applicants/discarded?region=${this.regionIdSelected}&supplier_id=${supplierId}&programIdSelected=${this.programIdSelected}`);
                this.discardedInfo = res.data;
                this.showLoading(false);
            } catch (error) {
                this.showLoading(false); 
            }
            this.showLoading(false); 
        },
        async addFilter(filterName, ev) {
            if (ev.toString() === "0") {
                delete this.filters[filterName];
            } else {
                this.filters[filterName] = ev.toString();
            }

            if(filterName == "zone_id" && ev.toString() === "0"){
                this.zoneIdSelected = 0;
                this.regionIdSelected = 0;
            }
        },
        async getCollections(){
            try {
                let params = `program_id=${this.programIdSelected}&zone=${this.zoneIdSelected}&region=${this.regionIdSelected}`;
                const res = await axios.get(`/api/v1/forms/get-pgm-suppliers?${params}`);
                this.collections.suppliers = res.data;
                this.supplierSelected = 0;
                if(res.data.length == 0) {
                    this.missingFieldsNotif("Sin proveedores.", "No hemos encontrado proveedores en esta zona / región.");
                }
                this.collections.suppliers.unshift({
                    id: 0,
                    select_name: "Todos los proveedores"
                });
            }
            catch (e) {
                this.warn(e);
            }
        },
    }
}
</script>

<style>
    .td-red {
        background-color: #f26d615e;
    }
</style>