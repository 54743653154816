
import Vue from 'vue'
import TitleWidget from '@components/supplier/widgets/TitleWidget';
import TextWidget from '@components/supplier/widgets/TextWidget';
import ImageWidget from '@components/supplier/widgets/ImageWidget';
import VerticalImageWidget from '@components/supplier/widgets/VerticalImageWidget';
import VideoWidget from '@components/supplier/widgets/VideoWidget';
import YoutubeVideoWidget from '@components/supplier/widgets/YoutubeVideoWidget';
import ProjectsSummaryWidget from '@components/supplier/widgets/ProjectsSummaryWidget';
import CreditDocumentsSummary from '@components/supplier/widgets/CreditDocumentsSummary';
import FeedbackCard from '@components/supplier/widgets/FeedbackCard';
import YoutubeWidget from '@components/supplier/widgets/YoutubeWidget'

Vue.component(TitleWidget.name, TitleWidget)
Vue.component(TextWidget.name, TextWidget)
Vue.component(ImageWidget.name, ImageWidget)
Vue.component(VerticalImageWidget.name, VerticalImageWidget)
Vue.component(VideoWidget.name, VideoWidget)
Vue.component(YoutubeVideoWidget.name, YoutubeVideoWidget)
Vue.component(ProjectsSummaryWidget.name, ProjectsSummaryWidget)
Vue.component(CreditDocumentsSummary.name, CreditDocumentsSummary)
Vue.component(FeedbackCard.name, FeedbackCard)
Vue.component(YoutubeWidget.name, YoutubeWidget)

