<template>
  <div id="pp-form">
    <div class="vx-row mb-4">
        <div class="vx-col w-full">
            <vs-input
                :disabled="isDisabled"
                class="w-full mb-4"
                name="firstName"
                label="Nombre cliente *"
                placeholder="Requerido"
                v-validate="ruleRequired"
                v-model.lazy="currentPotentialApplicant.customer_name"
                icon-pack="feather"
                :danger="hasError('firstName')"
                :danger-text="getErrorText('firstName')"
                :success="isSuccessful('firstName')"
                v-on="{ input: e => { currentPotentialApplicant.customer_name = upper(e) } }"
            />
        </div>
        <div class="vx-col w-1/2">
            <vs-input
                :disabled="isDisabled"
                class="w-full mb-4"
                name="applicantName"
                label="Nombre prospecto *"
                placeholder="Requerido"
                v-validate="ruleRequired"
                v-model.lazy="currentPotentialApplicant.owner_name"
                icon-pack="feather"
                :danger="hasError('applicantName')"
                :danger-text="getErrorText('applicantName')"
                :success="isSuccessful('applicantName')"
                v-on="{ input: e => { currentPotentialApplicant.owner_name = upper(e) } }"
            />
        </div>
        <div class="vx-col w-1/2">
            <label for="" class="vs-input--label">Teléfono a 10 dígitos *</label>
            <div class="flex">
                <vue-country-code
                    name="dialCode"
                    class="vs-custom"
                    data-vv-name="phone"
                    :enableSearchField="true"
                    searchPlaceholderText="Buscar código de país"
                    :enabledCountryCode="true"
                    @onSelect="onSelect"
                    :defaultCountry="'MX'"
                    :preferredCountries="['MX']">
                </vue-country-code>

                <vs-input
                    :disabled="isDisabled"
                    class="w-full mb-4"
                    name="phone"
                    type="tel"
                    placeholder="Requerido"
                    v-validate="rulePhone"
                    maxlength="10"
                    v-model.lazy="currentPotentialApplicant.phone"
                    icon-pack="feather"
                    :danger="hasError('phone')"
                    :danger-text="getErrorText('phone')"
                    :success="isSuccessful('phone')"
                />
            </div>
        </div>
        <vs-divider>Dirección</vs-divider>
        <div class="vx-col w-1/2">
            <vs-input
                :disabled="isDisabled"
                class="w-full mb-4"
                name="streetName"
                label="Calle *"
                placeholder="Requerido"
                v-validate="ruleRequired"
                v-model.lazy="currentPotentialApplicant.address.street"
                icon-pack="feather"
                :danger="hasError('streetName')"
                :danger-text="getErrorText('streetName')"
                :success="isSuccessful('streetName')"
                v-on="{ input: e => { currentPotentialApplicant.address.street = upper(e) } }"
            />
        </div>
        <div class="vx-col w-1/2">
            <vs-input
                :disabled="isDisabled"
                class="w-full mb-4"
                name="zipcode"
                label="Código Postal (CP) *"
                placeholder="Requerido"
                v-validate="ruleRequired"
                v-model.lazy="currentPotentialApplicant.address.zipcode"
                icon-pack="feather"
                :danger="hasError('zipcode')"
                :danger-text="getErrorText('zipcode')"
                :success="isSuccessful('zipcode')"
                v-on="{ input: e => { currentPotentialApplicant.address.zipcode = upper(e) } }"
            />
        </div>
        <div class="vx-col w-1/2">
            <vs-input
                :disabled="isDisabled"
                class="w-full mb-4"
                name="extNumber"
                label="Número exterior *"
                placeholder="Requerido"
                v-validate="ruleRequired"
                v-model.lazy="currentPotentialApplicant.address.ext_number"
                icon-pack="feather"
                :danger="hasError('extNumber')"
                :danger-text="getErrorText('extNumber')"
                :success="isSuccessful('extNumber')"
                v-on="{ input: e => { currentPotentialApplicant.address.ext_number = upper(e) } }"
            />
        </div>
        <div class="vx-col w-1/2">
            <vs-input
                :disabled="isDisabled"
                class="w-full mb-4"
                name="intNumber"
                label="Número interior"
                placeholder="Requerido"
                v-model.lazy="currentPotentialApplicant.address.int_number"
                icon-pack="feather"
                :danger="hasError('intNumber')"
                :danger-text="getErrorText('intNumber')"
                :success="isSuccessful('intNumber')"
                v-on="{ input: e => { currentPotentialApplicant.address.int_number = upper(e) } }"
            />
        </div>
        <div class="vx-col w-1/2">
            <vs-input
                :disabled="isDisabled"
                class="w-full mb-4"
                name="betweenStreet1"
                label="Entre calle 1 *"
                placeholder="Requerido"
                v-validate="ruleRequired"
                v-model.lazy="currentPotentialApplicant.address.between_street_1"
                icon-pack="feather"
                :danger="hasError('betweenStreet1')"
                :danger-text="getErrorText('betweenStreet1')"
                :success="isSuccessful('betweenStreet1')"
                v-on="{ input: e => { currentPotentialApplicant.address.between_street_1 = upper(e) } }"
            />
        </div>
        <div class="vx-col w-1/2">
            <vs-input
                :disabled="isDisabled"
                class="w-full mb-4"
                name="betweenStreet2"
                label="Entre calle 2 *"
                placeholder="Requerido"
                v-validate="ruleRequired"
                v-model.lazy="currentPotentialApplicant.address.between_street_2"
                icon-pack="feather"
                :danger="hasError('betweenStreet2')"
                :danger-text="getErrorText('betweenStreet2')"
                :success="isSuccessful('betweenStreet2')"
                v-on="{ input: e => { currentPotentialApplicant.address.between_street_2 = upper(e) } }"
            />
        </div>
    </div>
    <div class="vx-row">
        <div class="vx-col w-full">
            <vs-alert v-if="errorMessage" color="danger">
                <span>{{ errorMessage }}</span>
            </vs-alert>
        </div>
    </div>
    <div class="vx-row my-5 justify-end">
        <div class="vx-col w-1/2">
            <div class="flex flex-wrap items-center justify-end">
                <vs-button :disabled="isDisabled" class="ml-3 mt-2" @click="handleSave()" color="dark">Guardar</vs-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
export default {
    name: 'CreatePotentialApplicantControl',
    mixins: [
        inputHelper
    ],
    props: {
        currentPotentialApplicant: { required: true, type: Object }
    },
    data() {
        return {
            ruleRequired: 'required',
            ruleEmail: 'required|email',
            rulePhone: 'required|digits:10',
            errorMessage: null,
        }
    },
    computed: {
        isDisabled() {
            return this.AuxGuardName == "executive_manager";
        }
    },
    methods: {
        onSelect({ dialCode }) {
            this.currentPotentialApplicant.country_calling_code = `+${dialCode}`;
        },
        async handleSave() {
            this.showLoading(true);
            try {
                this.errorMessage = null;
                const validity = await this.$validator.validateAll();
                if(!validity) {
                    this.showLoading(false);
                    return;
                }

                const {data} = await axios.put('api/kof/put/potentialApplicants/deepUpdate', this.currentPotentialApplicant);
                if(!data.success){
                    this.errors.clear();
                    const errors = data.message;
                    const errorKeys = Object.keys(errors);
                    for (const key of errorKeys)
                        this.errors.add({ field: key, msg: errors[key][0] });
                    this.showLoading(false);

                    if(!this.currentPotentialApplicant.id) 
                        this.saveSuccessNotif();
                    return;
                }
                
                this.$emit('on-save');
                this.showLoading(false);
            } catch (error) {
                
            }
            this.showLoading(false);
        },
        handleClose() {
            this.$emit('on-close');
        },
        hasError(control) {
            return this.errors.has(control);
        },
        getErrorText(control) {
            return this.errors.first(control);
        },
        isSuccessful(control) {
            const field = this.fields[control];
            return (field && field.required && field.valid && !this.hasError(control));
        },
        clear() {
            this.errors.clear();
            this.errorMessage = null;
        }
    }

}
</script>

<style scope>

</style>