<template>
  <div>

    <vx-card title="Lista de tecnologías" class="mb-6">

      <vs-alert v-if="!isBackoffice" icon-pack="feather" icon="icon-alert-triangle" class="h-full mb-5" color="success">
        <span class="font-regular">Es importante que solicites al menos una tecnología, dado que es requisito para poder crear proyectos.</span>
      </vs-alert>
      
      <vs-button v-if="!isBackoffice" color="primary" type="border" icon-pack="feather" class="mb-4" @click="addTechnologyRequest()">Agregar tecnología para acreditación</vs-button>      
      
      <vs-table stripe noDataText="No se han generado registros" :data="technologiesList" pagination :max-items="itemsPerPage">
        <template slot="thead">
          <vs-th class="bold">TECNOLOGÍA</vs-th>
          <vs-th class="bold">FECHA DE SOLICITUD</vs-th>
          <vs-th class="bold">FECHA DE REVISIÓN</vs-th>
          <vs-th class="bold">ESTADO</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>

        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].family_group.name">
              {{ uppercase(data[indextr].family_group.name) }}
            </vs-td>

            <vs-td :data="data[indextr].accreditation_request_date">
              {{ validatedDate(data[indextr].accreditation_request_date)  }}
            </vs-td>

            <vs-td :data="data[indextr].accreditation_date">
              {{ validatedDate(data[indextr].accreditation_date)  }}
            </vs-td>

            <vs-td :data="data[indextr].accreditation_status">
              <vs-chip :color="accreditationStatusColor(data[indextr].accreditation_status)">
                {{accreditationStatusDesc(data[indextr].accreditation_status) }}
              </vs-chip>
            </vs-td>

            <vs-td :data="data[indextr].id">
              <feather-icon :icon="actionIconForItem(data[indextr].accreditation_status)" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" :class="needPulse(data[indextr].accreditation_status) ? 'pulse-success':''" @click.stop="editAccreditationRequest(data[indextr])" />
              <feather-icon v-if="technologyCanBeDeleted(data[indextr]) && !isBackoffice" icon="TrashIcon" class="ml-4" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" @click.stop="removeTechnologyRequest(data[indextr])" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>      
    </vx-card>

    <!-- popup -->
    <vs-popup
        id="add-accreditation"
        title="Agregar una tecnología para acreditar"
        :active.sync="showTechPopup">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">Selecciona la tecnología para la que quieres aplicar</p>

          <label for="" class="vs-input--label">Sistema o tecnología</label>
          <v-select
            label="text"
            name="family_group_id"
            class="vs-custom w-full mb-5"
            v-model.lazy="family_group_id"
            :options="familyGroupsForSelect"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="false"
            placeholder="Seleccione una opción"
          >
          </v-select>

          <vs-button :disabled="!completeForm || $isLoading" @click="sendBrandForm" color="primary" class="mr-5 mt-8">Agregar tecnología</vs-button>
          <vs-button @click="closeTechPopup" :disabled="$isLoading" type="flat" color="primary" class="mt-8">Cerrar</vs-button>
            
      </vs-popup>


      <vs-popup
        v-if="showAccreditationDetail"
        fullscreen
        title="Sobre tu acreditación"
        :active.sync="showAccreditationDetail">
        <accreditation-detail :id="currentAccreditationId" :supplier_id="SupplierId" @updated="accreditationRequested"/>
      </vs-popup>

  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper"
import dateHelper from "@mixins/dateHelper"

import AccreditationDetail from "../../../views/supplier/AccreditationDetail.vue";
const addBrandIndicationDef = "Selecciona la tecnología y grupo de productos que corresponde a la marca a crear.";
const editBrandIndicationDef = 
"Puedes modificar únicamente el nombre de la marca. Si deseas cambiar el grupo de productos o la familia de tecnologías, puedes eliminar este registro y generar uno nuevo.";

const addModelIndicationDef = "Filtra por tecnología y selecciona la marca a la que pertenece el modelo a crear.";
const editModelIndicationDef = 
"Puedes modificar el nombre del modelo y la marca a la que pertenece.";
const errorMssgDef = "Ha ocurrido un error, intente más tarde."

export default {
  mixins: [inputHelper, dateHelper],
  props: ['from_profile', 'supplier_id', 'is_from_onboarding'],
  components: {
    AccreditationDetail
  },
  data(){
    return {    
      familyGroupsForSelect: [],
      getProductGroupsForSelect: [],
      getProductBrandsForSelect: [],
      
      productGroupsList: [],
      technologiesList: [],
      modelsList: [],

      brandFormMode: "add",
      modelFormMode: "add",
      
      // datos para form de marca
      family_group_id: null,
      product_group_id: null,
      brand: null,
      prev_brand: null,
      selected_technology_id: null,
      family_group_name: null,
      product_group_name: null,

      // datos para form de modelo
      model: null,
      brand_id: null,
      product_brand_id: null, 
      capacity: null,

      showTechPopup: false,
      showModelFormPrompt: false,    

      itemsPerPage: 10,
      modelsPerPage: 10,

      currentSupplierId: null,
      isBackoffice: false,

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,
      showAccreditationDetail: false, 
      currentAccreditationId: null

    }
  },
  async beforeMount(){
    this.showLoading(true);
    this.isBackoffice = process.env.VUE_APP_IS_BACKOFFICE == "true";
    this.setSupplier();
    await this.getCollections();
    await this.getSupplierFamilyGroups();
    this.showLoading(false);
  },
  watch: {
    family_group_id(newVal,oldVal){
      if(newVal == null){
        this.getProductGroupsForSelect = [];
      }
      else {
        let collection = this.productGroupsList.filter(f => f.family_group_id == this.family_group_id);
        this.getProductGroupsForSelect = [];
        collection.forEach(opt => {
          this.getProductGroupsForSelect.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vx-select 
        })
      }
    } 
  },
  computed: {   
    completeForm(){
      return this.family_group_id != null;      
    },    
  },
  methods: {
    setSupplier(){
      if(this.isBackoffice){
        this.currentSupplierId = this.$props.supplier_id;
      }
      else {
        this.currentSupplierId = this.SupplierId;
      }
    },
    technologyCanBeDeleted(fam){
      return fam.accreditation_status == 0;
    },
    async getCollections(){
      try {
        let response = await axios.get('/api/v1/forms/getFamilyGroups');
        let options = [];
        response.data.forEach(opt => {
          if(opt.name != "MINERÍA CRIPTOGRÁFICA" && opt.name != "PERSONAL EFECTIVO") {
            options.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vx-select 
          }
        })
        this.familyGroupsForSelect = options;

        let response2 = await axios.get('/api/v1/forms/getProductGroups');   
        this.productGroupsList = response2.data;
      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },
    async getSupplierFamilyGroups(){
      try {
        let res = await axios.get(`/api/v1/supplier/${this.currentSupplierId}?with[]=supplierFamilyGroups.familyGroup`);
        this.technologiesList = res.data.supplier_family_groups;        
      }
      catch (e) {
        this.warn(e);
      }
    },
    async sendBrandForm(){
      let tt = this.technologiesList.find(f => f.family_group_id == this.family_group_id);
      if(tt){
        this.onError = true;
        this.errorMssg = "Ya cuentas con una acreditación registrada para la tecnología seleccionada."
      }
      else {
        await this.addNewTechnology();
      }
    },
    async addNewTechnology(){
      this.onSuccess = false;
      this.onError = false;
      
      this.showLoading(true);
      try {        
        let payload = { family_group_id: this.family_group_id};
        let res = await axios.post(`/api/v1/supplier/${this.currentSupplierId}/addSupplierFamilyGroup`, payload);
        this.onSuccess = true;
        this.addSuccessNotif();
        await this.getSupplierFamilyGroups();
        this.closeTechPopup();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },   
    addTechnologyRequest(){
      this.resetForm();
      this.showTechPopup = true;
    },
    editAccreditationRequest(item){
      this.showAccredit(item.id, item.family_group.name);
    },
    closeTechPopup(){
      this.showTechPopup = false;
    },
    showAccredit(id, name){
      if(this.isBackoffice){
        this.$router.push({ name: 'perfilDetalleAcreditacion', params: {supplier_id: this.currentSupplierId, id: id, name: name } });
      }
      else {
        if(this.is_from_onboarding) {
          this.showAccreditationDetail = true;
          this.currentAccreditationId = id;
        } else if(this.from_profile){
          this.$router.push({ name: 'perfilDetalleAcreditacion', params: { id: id, name: name } })
        }
        else {
          this.$router.push({ name: 'detalleAcreditacion', params: { id: id, name: name } })
        }
      }
    },
    removeTechnologyRequest(item){
      this.selected_technology_id = item.id;
      
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Descartar tecnología',
        text: `¿Realmente deseas eliminar esta tecnología de tu perfil? Todos los datos que hayan sido cargados se perderán`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveTechnology
      });
    },
    async doRemoveTechnology(){
      this.onSuccess = false;
      this.onError = false;
      this.showLoading(true);
      
      try {
        let payload = {supplier_family_group_id: this.selected_technology_id};
        await axios.post(`/api/v1/supplier/${this.currentSupplierId}/removeSupplierFamilyGroup`, payload);
        this.onSuccess = true;
        this.deleteSuccessNotif();
        await this.getSupplierFamilyGroups();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    resetForm(){
      this.family_group_id = null
      this.onError = null
      this.onSuccess = null
    },
  
    accreditationStatusDesc(status){
      let str;
      switch (status) {
        case 0:
          str = "En proceso de completar";
          break;
      case 1:
          str = "Acreditación solicitada, pendiente de revisión";
          break;
      case 2:
          str = "Acreditado en la tecnología";
          break;
      case 3:
          str = "Rechazado en la tecnología";
          break;
        default:
          str = "--"
          break;
      }
      return str;
    },  
    accreditationStatusColor(status){
      let color;
      switch (status) {
        case 0:
          color = "warning";
          break;
      case 1:
          color = "warning";
          break;
      case 2:
          color = "primary"
          break;
      case 3:
          color = "danger"
          break;
        default:
          color = ""
          break;
      }
      return color;
    },
    actionIconForItem(status){
     if(status > 0 || this.isBackoffice){
       return "EyeIcon"
     }
     else {
       return "EditIcon";
     }
    },
    needPulse(status) {
      return status <= 0;
    },
    async accreditationRequested() {
      this.showAccreditationDetail = false;
      await this.getSupplierFamilyGroups();
    }
  }
}
</script>

<style>
#add-accreditation .vs-popup .vs-popup--content {
  min-height: 18rem !important;
}
</style>