<template>

  <div class="identity-verification">
    <div v-if="canDoVerification">
      <div class="vx-row" v-if="!compatible">
        <vs-alert icon-pack="feather" icon="icon-alert-triangle" class="h-full mb-5" color="danger">
          <span class="font-regular"><strong>Este navegador no es compatible con el proceso para la verificación de identidad, por favor intenta con otro.</strong></span>
        </vs-alert>
      </div>
      <div class="vx-row" v-else-if="!loading">
      </div>
      <div class="vx-row" style="display:none">
        <div class="vx-col w-full">
          <metamap-button ref="button" :color="matiBtnColor" :clientid="mati_client_id" :metadata="JSON.stringify({user_id: usuario, personal_profile_id: personal_profile_id })"  id="matibuttonverification" :flowId="mati_flow_id" @mati:userFinishedSdk="finished"/>
          <metamap-button :color="matiBtnColor" :clientid="mati_client_id" :metadata="JSON.stringify({user_id: usuario, personal_profile_id: personal_profile_id })"  id="matibuttonverificationforeign" :flowId="mati_foreign_flow_id" @mati:userFinishedSdk="finished"/>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <vs-alert v-if="error_message" icon-pack="feather" icon="icon-alert-triangle" class="h-full mb-5" color="danger">
        <span class="font-regular">{{error_message}}</span>
      </vs-alert>
    </div>
    <div id="matistartverificationtutorial" v-if="canDoVerification">
      <div class="modal-dialog">
        <div class="modal-content">
          <div style="width: 100%; position:relative;" :class="imagesClass">
            <img alt="img" :src="img1" style="width: 100%" :class="classForImage(1)">
            <img alt="img" :src="img2" style="width: 100%" :class="classForImage(2)">
            <img alt="img" :src="img3a" style="width: 100%" :class="classForImage(3)" v-if="mexicano">
            <img alt="img" :src="img3b" style="width: 100%" :class="classForImage(3)" v-if="!mexicano">
            <img alt="img" :src="img4" style="width: 100%" :class="classForImage(4)">
            <img alt="img" :src="img5" style="width: 100%" :class="classForImage(5)">
            <img alt="img" :src="img6" style="width: 100%" :class="classForImage(6)">
            <img alt="img" :src="img7" style="width: 100%" :class="classForImage(7)">
            <img alt="img" :src="img8" style="width: 100%" :class="classForImage(8)">
            <img alt="img" :src="img9" style="width: 100%" :class="classForImage(9)">
            <img alt="img" :src="img10" style="width: 100%" :class="classForImage(10)">
            <img alt="img" :src="img11" style="width: 100%; cursor:pointer;" :class="classForImage(11)" @click="startVerification()">
            <div class="tutorial-controls mb-2" v-if="!loading">
              <div class="control">
                <vs-button type="border" color="dark" @click="step--" v-if="step > 1">Anterior</vs-button>
              </div>
              <div class="control">
                <vs-button color="dark" @click="step++" v-if="step < 11">Siguiente</vs-button>
                <vs-button color="primary" @click="startVerification" v-if="step === 11">Iniciar proceso</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!canDoVerification && !loading" class="p-2">
      <h5 class="mb-4">No es posible iniciar el proceso de verificación.</h5>
      <p>Hemos detectado que ya has realizado el proceso de verificación anteriormente,
        por lo que es necesario esperar a que concluya dicho proceso.</p>
    </div>
  </div>

</template>

<script lang="js">

  export default  {
    name: 'identity-verification',
    props: ['usuario', 'personal_profile_id',"citizenship"],
    async mounted() {
      this.mati_client_id = process.env.VUE_APP_MATI_CLIENT_ID
      this.mati_flow_id = process.env.VUE_APP_MATI_FLOW_ID
      this.mati_foreign_flow_id = process.env.VUE_APP_MATI_FOREIGN_FLOW_ID
      this.loading = true
      this.showLoading(true, "Recopilando información...")
      this.verifyCompatibility();
      this.mexicano = (this.UserPersonType === 0 || this.UserPersonType === 1);
      if(this.citizenship != null && this.citizenship == "700") {
        this.mexicano = true;
      }
      await this.getIdentityInfo();
      this.loading = false
      this.showLoading(false)
      const button = document.getElementById("matibuttonverification");

      button.addEventListener('mati:userFinishedSdk', ({ detail }) => {
        this.finished();
      });
      button.addEventListener('mati:exitedSdk', ({ detail }) => {
        this.exited();
      });

    },
    data() {
      return {
        matiBtnColor: '#28de18',
        status: 0,
        loading: false,
        error_message: '',
        step: 1,
        compatible: true,
        mexicano:true,
        citizenship: false,
        mati_client_id: '',
        mati_flow_id: '',
        mati_foreign_flow_id: '',

        img1: require('@assets/images/mati/201219_TUTORIAL-01.jpg'),
        img2: require('@assets/images/mati/201219_TUTORIAL-02.jpg'),
        img3a: require('@assets/images/mati/201219_TUTORIAL-04a.jpg'),
        img3b: require('@assets/images/mati/201219_TUTORIAL-04b.jpg'),
        img4: require('@assets/images/mati/201219_TUTORIAL-05.jpg'),
        img5: require('@assets/images/mati/201219_TUTORIAL-06.jpg'),
        img6: require('@assets/images/mati/201219_TUTORIAL-07.jpg'),
        img7: require('@assets/images/mati/201219_TUTORIAL-08.jpg'),
        img8: require('@assets/images/mati/201219_TUTORIAL-09.jpg'),
        img9: require('@assets/images/mati/201219_TUTORIAL-10.jpg'),
        img10: require('@assets/images/mati/201219_TUTORIAL-11.jpg'),
        img11: require('@assets/images/mati/201219_TUTORIAL-12.jpg'),

        canDoVerification: false,
      }
    },
    methods: {
      exited() {
        this.$store.commit("SET_MATI_PROCESS_IS_FINISHED", true);
      },
      async getIdentityInfo(){
        this.canDoVerification = true;
        try {
          let response = null
          if(this.personal_profile_id != null) {
            response = await this.publicApiGet('/api/perfil/personal-identity-verification/' + this.personal_profile_id);
          } else {
            response = await axios.get('/api/perfil/identity-verification/' + this.usuario);
          }
          // const response = await this.publicApiGet('/api/perfil/identity-verification/' + this.usuario);
          if(response.data.verification != null && response.data.verification !== ''){
            this.status = response.data.verification.identity_status
              if(this.status == 3 && response.data.verification.identity_id == null) {
                this.canDoVerification = true;
              } else {
                this.canDoVerification = (this.status === 0 || this.status === 4 || this.status === 5);
              }
          }
          else {
            this.canDoVerification = true;
          }
        }
        catch (e) {
          this.canDoVerification = false;
          this.error_message = 'Ocurrió  un error al obtener los datos, inténtalo mas tarde.'
        }
      },
      classForImage(step){
        return step === this.step ? "showing" : "not-visible";
      },
      finished(){
        this.loading = true
        this.publicApiPost('/api/perfil/identity-verification', {
          user_id: this.usuario,
          identity_verifier: 'metamap',
          personal_profile_id: this.personal_profile_id
        }).then(response => {
          this.status = 3;
          this.loading = false;
          this.onMatiProcessFinished();
        }).catch(error =>{
          this.error_message = 'Ocurrió  un error al enviar tus datos, inténtalo mas tarde.'
        })
      },
      verifyCompatibility(){
          var isEdge = !isIE && !!window.StyleMedia;
          var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
          var isIE = /*@cc_on!@*/false || !!document.documentMode;
          var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

          if(isEdge || isIE || isEdgeChromium){
              this.compatible = false
          }
      },
      startVerification(){
        $(`#matistartverificationtutorial`).modal('hide');
        if(this.mexicano){
          document.getElementById('matibuttonverification').click();
          this.$emit('on-mati-process-started');
        }else{
          document.getElementById('matibuttonverificationforeign').click();
          this.$emit('on-mati-process-started');
        }
      },
      async onMatiProcessFinished() {
        this.$store.commit("SET_MATI_PROCESS_IS_FINISHED", true);
        await this.$emit('on-mati-process-finished');
      },
    },
    computed: {
      imagesClass(){
        return this.loading ? "semi-trans" : "no-trans"
      }
    }
}
</script>

<style scoped lang="scss">
  .tutorial-controls{
    display: table;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .tutorial-controls .control{
    display: table-cell;
    vertical-align: middle;
  }

  .tutorial-controls .control .step{
    width: 15px;
    height: 15px;
    border: solid 1px white;
    border-radius: 50%;
  }
</style>
