<template>
    <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">Cobertura</h1>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div v-if="isMounted" class="main-form">
                                            <supplier-cities-control
                                                :needsClasses="false"
                                                class="w-full"
                                                ref="supplier_cities_component"
                                                :supplier_id="SupplierId" 
                                                :states="collections.states" />
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <vs-alert v-if="successMssg" icon-pack="feather" icon="icon-check" class="mb-5"
                                                color="success">
                                                <span class="font-regular">{{ successMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row mt-10">
                                                <div class="vx-col w-full">
                                                    <!-- BOTONES -->
                                                    <div class="flex justify-center">
                                                        <vs-button class="mt-2 vs-button-dark"
                                                            @click="requestNextOnboardingStep()">Continuar</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                    <div class="almost-done" v-else>
                                        <div class="mt-18">
                                            <h1 class="so-center w-full mb-4">
                                                ¡Ya falta poco!
                                            </h1>
                                            <p class="so-center w-full mb-2">Sólo te quedan lo siguientes pasos por completar para formar parte de la red más grande de méxico.</p>
                                            <div class="so-center w-full mb-8">
                                                <ul>
                                                    <li>Cobertura</li>
                                                    <li>Tus contactos</li>
                                                    <li>Carga de documentos</li>
                                                    <li>Registro de acreditaciones</li>
                                                    <li>Firma de contratos</li>
                                                    <li><span class="primary">¡Contáctanos!</span></li>
                                                </ul>
                                            </div>

                                            <div class="so-center w-full">
                                                <vs-button @click="isMainOnboardingStepVisible = true" color="dark">Continuar</vs-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import SupplierCitiesControl from '@supplier/SupplierCitiesControl.vue';
export default {
    name: "SupplierOnboardingCoverage",
    props: ["onboardingStepData", "isMoral"],
    components: {
        SupplierCitiesControl
    },
    data() {
        return {
            requiredRules: "required",
            requiredRulesUrl: "required|url",
            errorMssg: null,
            successMssg: null,
            isMounted: false,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
            collections: {
                states: [],
            },
        };
    },
    async mounted() {
        await this.getCollections();
        this.isMounted = true;
    },
    computed: {
        base() {
            return this.onboardingStepData;
        },
        personal() {
            return this.base.user.personal;
        },
        business() {
            return this.base.user.business;
        },
    },
    watch: {
        loading: function (newVal, oldVal) {
            if (newVal == true) {
                this.$vs.loading();
            }
            else {
                this.$vs.loading.close();
            }
        }
    },
    methods: {
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        async getCollections() {
            try {
                let collectionsObjects = ['statesList'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                let colls = res.data;
                this.collections.states = colls.statesList;

            } catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        async requestNextOnboardingStep() {
            this.showLoading(true, "Guardando información de cobertura...")
            await this.checkIfCanUpdateSupplierOnboardingStep("coverage");
            await this.$emit("updated", 1);
            this.showLoading(false)
        }
    }
};
</script>

<style>
.almost-done {
    position: fixed;
    width: 100%;
    background-color: #FFF;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
}

    .almost-done .mt-18{
        margin-top: 15rem !important;
    }

.so-center {
    display: flex;
    justify-content: center;
}
/* 
li span {
    font-weight: 700;
    color: #28DE18;
} */

</style>
