<template>
    <div v-if="showNotification" id="supplier-restriction-data" class="w-full">
        <vs-alert v-if="isMounted" :title="getTittle" active="true" :color="getColorLevel" class="mb-4 h-auto">
            {{ getMessageLevel }}
            <br>
            <!-- <vs-button @click.stop="getRestrictionHistory()" color="danger" type="relief" class="mt-2 float-right" size="small">HISTORIAL DE RESTRICCIONES</vs-button> -->
        </vs-alert>


        <vs-popup class="modal-body" :active.sync="showInfoDialog" :title="popupTitle">
            <vs-table         
                ref="jobsTable"
                stripe
                :data="restrictionHistory"
                noDataText="No hay datos disponibles">
                <template slot="thead">
                    <vs-th>Fecha</vs-th>
                    <vs-th>Restricción</vs-th>
                    <vs-th>Usuario</vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :data="data" v-for="item in data" :key="item.id">
                        <vs-td>{{ sessionFormat(item.created_at) }}</vs-td>
                        <vs-td>{{ humanizedRestrictionLevel(item.restriction_level) }}</vs-td>
                        <vs-td>{{ item.update_reason }}</vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vs-popup>
    </div>
</template>

<script>
import dateHelper from "@mixins/dateHelper";
    export default {
        name: "SupplierRestrictedData",
        mixins: [dateHelper],
        data: () => ({
            showInfoDialog: false,
            popupTitle: 'Historial de restricciones',
            restrictionHistory: [],
            isMounted: false
        }),
        props: {
            source: String
        },
        mounted: function() {
            this.isMounted = true;
        },
        computed: {
            showNotification() {
                return this.source.restriction_level != 'no_restriction';
            },
            getTittle() {
                return `Alerta de Nivel ${this.humanizedRestrictionLevel()}`;
            },
            getMessageLevel() {
                let collectionRestrictionLevel = {
                    'no_restriction': 'Sin restricción',
                    'alert': '¡Aviso! Hemos detectado que falta un adecuado seguimiento en uno o más proyectos. Es necesario que atiendas las solicitudes o quejas de tus clientes para que no caigas en algún incumplimiento. Por favor, dale importancia a esto, ya que en caso de que no exista un seguimiento se reestringirá la cuenta y no se podrán generar más solicitudes de crédito.',
                    'medium': '¡Cuenta restringida! Tu cuenta ha sido restringida debido al incumplimiento de contrato de uno o más proyectos, por lo que no se podrá tener más de un proyecto en financiamiento o en seguimiento. Para quitar restrincciones es necesario dar seguimiento a proyectos pendientes. ',
                    'high': '¡Cuenta restringida! Tu cuenta ha sido restringida debido al incumplimiento de contrato de uno o más proyectos, por lo que no se podrá tener más de un proyecto en financiamiento o en seguimiento. Para quitar restrincciones es necesario dar seguimiento a proyectos pendientes. ',
                    'banned': `Cuenta inhabilitada`
                };

                return collectionRestrictionLevel[this.source.restriction_level] || "No definido";
            },
            getColorLevel() {
                let collectionRestrictionLevel = {
                    'no_restriction': 'Sin restricción',
                    'alert': 'dark',
                    'medium': 'warning',
                    'high': 'danger',
                    'banned': 'danger'
                };

                return collectionRestrictionLevel[this.source.restriction_level] || "No definido";
            }
        },
        methods: {
            humanizedRestrictionLevel(level = this.source.restriction_level) {
                let collectionRestrictionLevel = {
                    'no_restriction': 'Sin restricción',
                    'alert': 'Bajo',
                    'medium': 'Medio',
                    'high': 'Alto',
                    'banned': 'Inhabilitado'
                };

                return collectionRestrictionLevel[level] || "No definido";
            },
            async getRestrictionHistory() {
                try {
                    this.showLoading(true);
                    const res = await axios.get(`/api/supplier/get/supplier-restrictions/${this.source.id}`);
                    this.restrictionHistory = res.data;
                    this.showInfoDialog = true;
                    this.showLoading(false);
                } catch (error) {
                    this.showLoading(false);
                    console.error(error);
                } finally {
                    this.showLoading(false);
                }
            }
        },
    }
</script>