export default {
   methods: {
      unMoneyFormat(value) {
         if (!value) { return null }
         let str = value.toString();
         let val = str.replace("$", "");
         return this.unCurrencyFormat(val);
      },
      unCurrencyFormat(value) {
         if (!value) return 0;
         let str = value.toString();
         let val = str.replace(",", "");
         val = val.replace(",", "");
         val = val.replace(",", "");
         val = val.replace(",", "");
         val = val.replace(",", "");
         val = val.replace(",", "");
         return parseFloat(val.toString());
      },
      currencyFormat(value, decimales = 2) {
         if (!value) return 0;
         else return parseFloat(value).toFixed(decimales);
      },
      liveCostFormatNoCents(value) {
         if (!value) { return "0"; }
         if (value === "Pendiente") { return value; }
         else {
            const vv = this.unMoneyFormat(value);
            return this.format_price(vv);
         }
      },
      format_price(value, decimales = 0) {
         let val = (value / 1).toFixed(decimales).replace(",", ".");
         return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
   }
}