<template>
    <div id="knowledge">
        <vx-card class="mb-6" card-background="dark">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h1 class="rg-big-title extrabold rg-title white">Constancia de <span class="orange">Conocimiento</span>.</h1>
                </div>
            </div>
        </vx-card>
        <div v-if="!isSigned">
            <template v-if="!isAnswering">
                <vx-card class="mb-6" title="¿Qué es importante que sepas en RedGirasol?">
                    <p class="mb-4 pl-2">
                        En RedGirasol tenemos ciertas expectativas y responsabilidades que tendrás que cumplir al momento de generar <br>
                        proyectos con nosotros. Debido a esto, queremos que complementes la <strong>Constancia de Conocimiento</strong> donde encontrarás <br>
                        los puntos más importantes de tu proceso y que será fundamental que conozcas y aceptes. Si contestas <strong>"No estoy de acuerdo"</strong>
                        en algun punto, lamentablemente no podremos continuar y <strong>no podrás generar solicitudes de crédito</strong>.
                    </p>
                    <vs-alert title="IMPORTANTE" class="h-full mb-5" color="warning">
                        Los siguientes consentimientos solamente aplican para el financiamiento de proyectos de tus clientes. No aplican para préstamos de capital de trabajo que tú nos solicites.
                    </vs-alert>
                    <vs-button class="ml-2" type="filled" color="primary" @click="start()">Comenzar</vs-button>
                </vx-card>
            </template>
            <template v-else>
                <vx-card class="mb-6" title="Avance.">
                    <p class="mb-4 pl-1">El proceso de la <strong>Constancia de Conocimiento</strong> es corto y podrás consultar tu avance en la barra que se encuentra a continuación. <br> ¡Gracias por tu tiempo!</p>
                    <vs-progress :percent="percentValue" :height="20" color="primary"></vs-progress>
                </vx-card>
                <vx-card class="mb-6">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <h1 class="rg-big-title extrabold rg-title">Sobre la <span class="orange">{{stage}}</span>.</h1>
                        </div>
                    </div>
                </vx-card>

                <!-- ENCUESTA -->
                <template v-for="(clause, index) in clauses">
                    <vx-card v-if="index+1 === currentClause" :key="index" class="mb-6" :title="clause.what" >
                        <p class="mb-4 pl-2">{{clause.how}}</p>
                        <p class="mb-6 pl-2">
                            <strong v-if="clause.why !== ''">¿Por qué?</strong><br>
                            {{clause.why}}
                        </p>
                        <vs-button class="ml-2" type="filled" color="primary" @click="next()">Estoy de acuerdo</vs-button>
                        <vs-button class="ml-2 fix-btn-border fix-dark" type="border" color="dark" @click="disagreement()">No estoy de acuerdo</vs-button>
                    </vx-card>
                </template>
                <!-- ENCUESTA -->

                <!-- POPUP DESACUERDO -->
                <vs-popup id="notification" title="Atención." :active.sync="popupActive">
                    <p class="mb-6">
                        <strong>¿Estás seguro que no estás de acuerdo?</strong><br><br>
                        
                        Recuerda que al momento de responder 'No estoy de acuerdo' a cualquiera
                        de las preguntas no será posible que generes solicitudes de crédito
                        dentro de RedGirasol. <br><br>

                        Si quieres ofrecer el crédito más flexible a tus clientes y aumentar tus ventas
                        responde 'Estoy de acuerdo' a todas las preguntas que se presenten. <br><br>
                        <strong>¿Quieres continuar?</strong>
                    </p>
                    <vs-button class="ml-2" type="filled" color="primary" @click="popupActive = false">Si, regresar al cuestionario.</vs-button>
                    <vs-button class="ml-2 fix-btn-border fix-dark" type="border" color="dark" @click="doDisagreement()">No, no deseo continuar.</vs-button>
                </vs-popup>
                <!-- POPUP DESACUERDO -->
            </template>
        </div>

        <div v-else>
            <vx-card class="mb-6" title="¡Listo!">
                <p class="mb-4 pl-2">
                    Gracias por tu tiempo, a continuación podrás consultar un resumen de la <strong>Constancia de Conocimiento</strong>.<br>
                    En cualquier momento la podrás volver a consultar en la sección de <strong>Mis Contratos</strong>.
                </p>
                <vs-button class="ml-2" type="filled" color="primary" @click="popupSpecial = true">Consultar</vs-button>
            </vx-card>
        </div>

        <vs-popup fullscreen :active.sync="popupSpecial" title="Contrato">
            <vx-card class="mb-5" title="EL CONTRATO" title-color="primary" >
                <knowledge-clauses />
            </vx-card>
        </vs-popup>

    </div>
</template>

<script>

import KnowledgeClauses from "@views/supplier/contracts/KnowledgeClauses";

const clauses = [
    {
        stage_id: 1
        , stage_name: "Solicitud"
        , what: "Filtro inicial del proveedor."
        , how: "En caso de que detectes que tu cliente pueda buscar un fraude, actuar con dolo o tengas algún indicio que su proyecto pueda generar un problema de corto o mediano plazo, por favor absténte de solicitar el crédito o coméntale las observaciones a tu ejecutivo de cuenta."
        , why: "En RedGirasol consideramos a los instaladores como nuestros socios, nos complementamos para cerrar proyectos en los cuales ambas partes ganamos. También, pensamos en los instaladores como nuestros ojos y pies en los proyectos, por lo que agradecemos que nos ayuden a controlar el riesgo. Tener una alta cartera vencida compromete nuestro funcionamiento y la posibilidad de seguir otorgando créditos en el mediano y largo plazo."
        , wasAccepted: false
    },
    {
        stage_id: 2
        , stage_name: "Solicitud"
        , what: "Cambios en condiciones de crédito."
        , how: `Es posible que las condiciones de crédito que se generan de forma predeterminada en el simulador de pagos puedan ser más o menos favorables para tu cliente una vez que se realice el análisis del expediente completo.`
        , why: "En RedGirasol buscamos ser lo más justos posible, por lo que buscamos que la tasa que reciben los inversionistas, sea la más adecuada en relación al riesgo que están asumiendo por proyecto invertido."
        , wasAccepted: false
    },    {
        stage_id: 3
        , stage_name: "Solicitud"
        , what: "Monto máximo a financiar de $600,000 en primer proyecto del instalador."
        , how: `El primer proyecto que el instalador realice en la Red no podrá tener un monto a financiar mayor a los $600,000.`
        , why: "Al iniciar la colaboración, la curva de aprendizaje entre el instalador y RedGirasol puede llevar tiempo, por lo que buscamos empezar a conocernos sin experimentar con proyectos de gran tamaño que pudieran afectar la relación con los inversionistas."
        , wasAccepted: false
    },
    {
        stage_id: 4
        , stage_name: "Solicitud"
        , what: "Enganche en proyectos de $700,000 o mayores."
        , how: "Los proyectos con un monto igual o mayor a $700,0000 contarán con un enganche mínimo del 5% que podría variar de acuerdo al análisis crediticio."
        , why: "Este enganche mínimo nos permitirá reducir el riesgo crediticio en nuestro análisis, sin tener que aumentar la cantidad y especificaciones en los requisitos de proyectos de mayor tamaño."
        , wasAccepted: false
    },
    {
        stage_id: 5
        , stage_name: "Instalación"
        , what: "Forma de pago (anticipo y finiquito)."
        , how: "En RedGirasol liquidamos los proyectos en dos pagos, un anticipo y un finiquito. El anticipo corresponde a un 76% (menos la comisión sobre venta) de la factura, para esto será necesario contar con el proyecto fondeado, los contratos correctamente firmados y la factura por el 100% del proyecto subida a la plataforma. El finiquito restante se entregará una vez que los anexos faltantes se encuentren firmados y en caso de los proyectos solares, se hayan interconectado."
        , why: "Queremos que instalar el proyecto no te represente un costo financiero, por lo que liberamos un anticipo que cubre prácticamente el costo del mismo en su totalidad. En el caso de los proyectos solares, la liquidación se da posterior a la interconexión, ya que nos ayuda a que los instaladores den un mayor seguimiento a los trámites con CFE."
        , wasAccepted: false
    },
    /*{
        stage_id: 6
        , stage_name: "Instalación"
        , what: "Comisión sobre venta."
        , how: "RedGirasol cobrará al instalador una comisión del 6% sobre el valor total de la factura del proyecto. Este cobro se descuenta en el pago del anticipo."
        , why: "RedGirasol no genera ingresos por los intereses pagados por el cliente, por lo que estamos obligados a cobrar comisiones a todos los usuarios."
        , wasAccepted: false
    },*/
    {
        stage_id: 7
        , stage_name: "Instalación"
        , what: "Penalización por retraso en los trabajos del proyecto."
        , how: "En el supuesto de que existan retrasos en la entrega de todos los trabajos del proyecto que impidan concluir con la instalación o ingreso del trámite de interconexión en los 30 días posteriores a la entrega del anticipo, se generará una penalización al instalador correspondiente al 1% de la factura por cada semana de retraso, misma que se descontará del último pago y se entregará íntegramente al solicitante para cubrir sus mensualidades."
        , why: "En RedGirasol promovemos el trabajo profesional y con atención al detalle. Es por esto que nos interesa que el instalador, que realiza un proyecto que financiemos, tenga la dedicación necesaria para cumplir con la entrega en el tiempo y forma estipulados, previendo que no exista algún trabajo, trámite o cualquier otra situación adicional que impida lo anteriormente mencionado."
        , wasAccepted: false
    },
    {
        stage_id: 8
        , stage_name: "Entrega"
        , what: "Garantía de generación (Exclusivo financiamiento solar fotovoltaico)."
        , how: `Todos los proyectos son acompañados de un contrato de compraventa el cual es firmado entre el instalador y el solicitante. En dicho contrato se incluyen las siguientes garantías:

                Fallo de operación por mala instalación: En caso de un fallo de operación por mala instalación, el instalador será responsable de cualquier costo relacionado con el mantenimiento o reposición total o parcial de equipos. 

                Fallo de operación por defecto de fabricante: En caso de un fallo de operación por defecto de fabricante el instalador ejercerá las garantías de fabricantes correspondientes para dar mantenimiento o reponer los equipos afectados. 

                Garantía de generación: En caso de los proyectos solares, se refiere a la falta de cumplimiento con la generación anual estimada de energía a partir del día de puesta en operación normal del sistema solar.`
        , why: "La principal causa de incumplimiento de pago es inconformidad con la instalación, funcionamiento u otros problemas relacionados al proyecto. El ofrecimiento de estas garantías nos ayuda a asegurar el mismo estándar de alta calidad en la instalación y servicios en todos los proyectos financiados. Esto es fundamental ya que en RedGirasol trabajamos con más de 200 instaladores distribuidos por todo el país."
        , wasAccepted: false
    },
    {
        stage_id: 9
        , stage_name: "Entrega"
        , what: "Obligación de atención."
        , how: "El instalador deberá responder a la solicitud de atención que realice el acreditado en máximo 72 horas hábiles y realizar la entrega de todos los trabajos correspondientes a los escenarios para un fallo de operación en el plazo máximo de un mes contado a partir de la fecha en la que el acreditado le notifique por correo electrónico con acuse de recibo."
        , why: "La percepción de atención es fundamental para la conformidad del solicitante. Necesitamos que todos los instaladores cuenten con el mismo compromiso de servicio postventa con sus clientes."
        , wasAccepted: false
    },
    {
        stage_id: 10
        , stage_name: "Entrega"
        , what: "Apoyo en cobranza."
        , how: "El instalador deberá realizar las visitas que sean necesarias al domicilio del solicitante cuando este se encuentre ilocalizable."
        , why: "Debido a que el instalador tuvo un contacto personal y presencial durante el proceso de la venta, tiene mayor probabilidad de lograr identificar el problema de comunicación que pueda existir con el solicitante. RedGirasol es una financiera que opera de forma 100% digital, por lo que trabajando juntos podremos mantener una mejor comunicación con los solicitantes y de esta forma, que nuestros inversionistas continúen fondeando los proyectos que nos benefician a todos."
        , wasAccepted: false
    },
    {
        stage_id: 11
        , stage_name: "Entrega"
        , what: "Obligación de recuperación."
        , how: "En caso de que un crédito se encuentre vencido por falta de pago por parte del solicitante, correrá por cuenta del instalador realizar la recuperación del sistema del domicilio del solicitante y resguardarlo mientras se lleva a cabo la recompra."
        , why: "Históricamente, solamente 7 de cada 100 proyectos financiados llegan a una etapa de vencimiento, para su posterior recuperación. En la mayoría de los casos, la falta de pagos se debe al incumplimiento del instalador en algunos de los puntos mencionados anteriormente. Además, esto es algo que se pide en reciprocidad y empatía con los cientos de inversionistas que ayudaron a fondear el proyecto. En términos económicos, el instalador es el usuario que mayor margen gana por proyecto fondeado."
        , wasAccepted: false
    },    {
        stage_id: 12
        , stage_name: "Entrega"
        , what: "Mejores condiciones por desempeño de la cartera."
        , how: "La metodología de riesgo crediticio considera el desempeño histórico de la cartera del instalador como medida de análisis. Los instaladores cuyos solicitantes tienen buen comportamiento de pago, acceden a tasas más bajas para futuros proyectos, mientras que los instaladores cuyos solicitantes cuentan con mal historial de pagos, acceden a tasas más altas."
        , why: "Hemos detectado una relación directa entre proyectos vencidos e instaladores, por lo que concluímos que el instalador debe ser considerado para estimar el riesgo de los proyectos y por lo tanto, de la tasa asignada a los mismos."
        , wasAccepted: false
    },
];


export default {
    name: "KnowledgeContract",
    props: ["isFromOnboarding"],
    data(){
        return {
            isAnswering : false
            , popupActive: false
            , clauses: clauses
            , currentClause: 1
            , popupSpecial: false
            , isSigned: false
        }
    },
    components:{
        KnowledgeClauses
    },
    computed: {
        stage(){
            let st;
            if(this.currentClause >= 1 && this.currentClause <= 4){
                st = "Solicitud";
            } else if (this.currentClause >= 5 && this.currentClause <= 7){
                st = "Instalación";
            } else {
                st = "Entrega";
            }
            return st;
        },
        percentValue(){
            let total = this.clauses.length;
            return ( this.currentClause * 100 ) / total;
        }
    },
    methods: {
        start(){ // Inicio de respuestas.
            this.isAnswering = true;
        },
        next(){
            let obj = this.clauses[this.currentClause - 1];
            obj.wasAccepted = true;
            if(!this.isLast()){
                this.currentClause++;
            } else {
                this.doRequestSignature();
            }
        },
        async doRequestSignature(){
            this.showLoading(true);
            try {
                const res = await axios.post(`/api/v1/supplier/${this.SupplierId}/contracts/knowledge`);
                if(res){
                    this.saveSuccessNotif();
                    this.isSigned = true;
                    this.isAnswering = false;
                    await this.$emit("updated", 1);
                }
            } catch (error) {
                this.warn(error);
            }
            this.showLoading(false);
        },
        disagreement(){
            this.popupActive = true;
        },
        isLast(){
            let length = this.clauses.length;
            let cc = this.currentClause;
            if(length === cc){
                return true;
            }
            return false;
        },
        doDisagreement(){
            this.popupActive = false;
            console.log(this.isFromOnboarding);
            if(!this.isFromOnboarding) {
                this.$router.push({ name: 'perfil-editar' });
            }
        }
    }
}
</script>

<style>
    .rg-title {
        font-size: 3rem !important;
    }
    #notification .vs-popup--header {
    background: #28DE18 !important;
    }
    #notification .vs-popup--header .vs-popup--title h3{
        color: #fff !important;
    }

    .fix-dark:hover {
        color:#3b3a3e !important;
    }
</style>