<template>
    <div id="3_supplier_general_data" class="vx-col w-4/5 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">Contactos</h1>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div v-if="isMounted" class="main-form mt-base">
                                            <div class="vx-row">
                                                <div v-for="section in allSections" :key="section.id" :class="colClass">
                                                    <div class="mb-4">
                                                        <h4 :class="section.public_description != null ? 'mb-2' : ''">{{
                                                            section.public_name }}</h4>
                                                        <p v-if="section.public_description != null">{{
                                                            section.public_description }}</p>
                                                    </div>
                                                    <div class="vx-row">
                                                        <template v-for="f in section.fields">
                                                            <!-- Campos automaticos -->
                                                            <form-field :ref="`section_id_${section.id}`"
                                                                v-if="isAutoFormField(f)" :key="f.id" :f="f"
                                                                :dataContainer="getContainerForField(section, f)"
                                                                :collectionsRoot="collections"
                                                                :onChangeReceptor="onFieldChange"
                                                                :hasBackofficeAccess="false"
                                                                :blockedByDocument="f.blockedByDocument"
                                                                :evaluator="evaluateCondition" :inputClassLarge="false"
                                                                :countriesList="collections.countries"
                                                                :editableByBackoffice="true" />
                                                        </template>
                                                    </div>
                                                    <!-- <div class="vx-row">
                                                        <div class="vx-col w-full">
                                                            <div class="flex flex-wrap items-center justify-end">
                                                                <vs-button class="ml-3 mt-2"
                                                                    @click="saveSections(`section_id_${section.id}`)">Guardar
                                                                    cambios</vs-button>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <vs-alert v-if="successMssg" icon-pack="feather" icon="icon-check" class="mb-5"
                                                color="success">
                                                <span class="font-regular">{{ successMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row mt-10">
                                                <div class="vx-col w-full">
                                                    <!-- BOTONES -->
                                                    <div class="flex justify-center">
                                                        <vs-button class="mt-2 vs-button-dark"
                                                            @click="executeNextStepOnboardingStep()">Continuar</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';

const sectionsToContainers = [
    {
        id: 10, containers: [
            { db: 'supplier_contacts', path: 'contacts', arrayDefs: { eval: 'filterGeneralContact', onNull: 'newGeneralContact' } },
            { db: 'phones', path: 'contacts', arrayDefs: { eval: 'filterGeneralContactForNumber', onNull: 'defaultPhoneObject' } },
        ]
    },
    {
        id: 17, containers: [
            { db: 'supplier_contacts', path: 'contacts', arrayDefs: { eval: 'filterCommercialContact', onNull: 'newCommercialContact' } },
            { db: 'phones', path: 'contacts', arrayDefs: { eval: 'filterCommercialContactForNumber', onNull: 'defaultPhoneObject' } },
        ]
    },
    {
        id: 18, containers: [
            { db: 'supplier_contacts', path: 'contacts', arrayDefs: { eval: 'filterOperationsContact', onNull: 'newOperationsContact' } },
            { db: 'phones', path: 'contacts', arrayDefs: { eval: 'filterOperationsContactForNumber', onNull: 'defaultPhoneObject' } },
        ]
    },
];
const arrayPaths = ['contacts', 'contacts.phone'];

export default {
    name: "SupplierOnboardingGeneral",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper],
    components: {
        FormField
    },
    props: ["onboardingStepData", "isMoral"],
    data() {
        return {
            requiredRules: "required",
            errorMssg: null,
            successMssg: null,
            isMounted: false,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
            formId: 2,
            pmSections: [10, 17, 18],
            pfSections: [10, 17, 18],
            form: {},
            allSections: [],
            formSections: [],

            documentsFields: [],
            documentsStates: [],

            validatedDocuments: [],
            allDocumentFields: [],
            // objeto principal
            base: {},
            // objetos para actualizaciones
            basePayload: {},
            validatedFields: [],
            totalFields: 0,
            totalValidFields: 0,
            totalInvalidFields: 0,
            skipMissingWarnings: false,
            collections: {
                countries: []
            }
        };
    },
    async mounted() {
        this.isMounted = false;
        this.base = this.onboardingStepData;
        await this.getCollections();
        await this.getSections();
        this.isMounted = true;
    },
    computed: {
        personal() {
            return this.base.user.personal;
        },
        business() {
            return this.base.user.business;
        },
        newPhone() {
            return { country_calling_code: null, phone: null };
        },
    },
    watch: {
        loading: function (newVal, oldVal) {
            if (newVal == true) {
                this.$vs.loading();
            }
            else {
                this.$vs.loading.close();
            }
        }
    },
    methods: {
        async getCollections() {
            try {
                let collectionsObjects = ['countriesList'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                let colls = res.data;
                this.collections.countries = colls.countriesList;
            } catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        async getSections() {
            const sections = this.isMoral ? this.pmSections : this.pfSections;
            const res = await axios.post("/api/v1/forms/getSectionsByArrayIds", { section_ids: sections });
            this.allSections = res.data.sections;

            await this.asyncForEach(this.allSections, async (s) => {
                s.containers = this.getContainersForSection(s.id);
                s.documentFields = [];

                await this.asyncForEach(s.fields, async (f) => {
                    this.formFieldSetter(f, this);
                });
            });
        },
        getContainersForSection(section_id) {
            let c = sectionsToContainers.find(f => f.id == section_id);
            if (!c) {
                this.warn("Missing containers definition for section " + section_id);
                return null;
            }
            else {
                let sectionContainers = [];
                c.containers.forEach(cc => {
                    let con = this.setContainerFromDef(cc);
                    sectionContainers.push({ db: cc.db, path: cc.path, container: con });
                })
                return sectionContainers;
            }
        },
        getContainerForField(section, f) {
            let c = section.containers.find(sc => sc.db == f.db_table);
            if (!c) {
                this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
                return null;
            }
            else {
                return c.container;
            }
        },
        setContainerFromDef(c) {
            let container = this.nestedFieldValue(this.base, c.path);
            if (Array.isArray(container)) {
                let ac = null;
                if (c.arrayDefs) {
                    if (c.arrayDefs.getFirst == true) {
                        ac = container[0];
                    }
                    else if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval](container);
                    }
                }
                if (!ac) {
                    if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                    else { ac = {} }
                    container.push(ac);
                }
                return ac;
            }
            else {
                if (c.path == 'bank_accounts_bank') {
                    let accs = this.nestedFieldValue(this.base, 'user.business.bank_accounts');
                    let nbankAcc = accs[0];
                    return nbankAcc.bank;
                }
                if (container == null && c.arrayDefs) {
                    let ac = null;
                    if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval]();
                        return ac;
                    }
                    if (!ac) {
                        if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                        else { ac = {} }
                        container = ac;
                    }
                }
                return container;
            }
        },
        /* evaluator */
        evaluateCondition(condition) {
            return this.evaluateConditionBase(this, condition);
        },
        /* on change receptor */
        onFieldChange(method) {
            if (method != null && method in this) {
                this[method]();
            }
        },
        // GUARDAR
        async executeSave() {
            await this.saveSections("section_id_10");
            await this.saveSections("section_id_17");
            await this.saveSections("section_id_18");
        },
        async saveSections(gslug) {
            // this.loading = true;
            this.skipMissingWarnings = false;
            let result = await this.runValidations(gslug);
            if (!result) {
                if (!this.skipMissingWarnings) {
                    this.missingFieldsNotif();
                }
            } else {

                //guardar solo la informacion que se ha modificado
                this.basePayload = this.collectFormData(gslug);
                if (this.objectIsEmpty(this.basePayload) && gslug != "knowledge") {
                    this.saveSuccessNotif();
                    this.loading = false;
                    return;
                }

                this.basePayload.id = this.base.id;
                this.showLoading(true, "Guardando información...");
                try {
                    // ejecutar guardado
                    this.injectAccountMetadataToPayload(this.basePayload);
                    await axios.put(`/api/v1/supplier/${this.SupplierId}/deepUpdate`, this.basePayload);
                    // solicitar la informacion actualizada del modelo
                    this.showLoading(false);
                    // await this.checkIfCanUpdateSupplierOnboardingStep("general");
                    // await this.$emit("updated", 1);
                    this.saveSuccessNotif();
                }
                catch (error) {
                    this.showLoading(false);
                    this.warn(error);
                    this.failedOperationNotif(null, null);
                }
            }
            this.loading = false;
        },
        async runValidations(gslug) {
            // validar componentes tipo FormField
            let res1 = await this.validateFormFields(gslug);
            if (!res1) {
                return false;
            }

            // validar inputs instanciados en la vista
            let res2 = await this.$validator.validateAll();
            if (!res2) {
                return false;
            }

            // retornar true si todas las validaciones fueron positivas
            return true;
        },
        resetCounters() {
            this.totalFields = 0;
            this.totalValidFields = 0;
            this.totalInvalidFields = 0;
        },
        collectFormData(gslug) {
            let payload = {};
            const sectionId = gslug.substr(11);

            let section = this.allSections.find(s => s.id == sectionId);
            section.containers.forEach(cc => {
                let fields = this.validatedFields.filter(vf => vf.db == cc.db);
                if (fields.length < 1) {
                    return;
                }

                if (cc.path == "base") {
                    fields.forEach(f => {
                        payload[f.fname] = cc.container[f.fname];
                    });
                }
                else {
                    let obj = null;
                    if (arrayPaths.includes(cc.path)) {
                        // se integran todos los objetos de la coleccion
                        obj = this.nestedFieldValue(this.base, cc.path);
                    }
                    else {
                        obj = { id: cc.container.id };
                        fields.forEach(f => {
                            obj[f.fname] = cc.container[f.fname];
                        });
                    }
                    // ajuste para objetos tipo array
                    this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
                }
            });
            return payload;
        },
        async validateFormFields(refGroup) {
            let allValid = true;
            this.validatedFields = [];
            let grefs = this.$refs[refGroup];
            let f;
            for (let p in grefs) {
                f = grefs[p];
                let r = await f.checkForValidDirty();
                if (r.valid == false) {
                    allValid = false;
                }
                else if (r.dirty == true) {
                    let sp = f.specialValidation();
                    if (sp != null && sp.method in this) {
                        let res = await this[sp.method]();
                        let n = f.rawFieldName;
                        if (!res) {
                            if (f.db_name === "rfc" && res.length === 0) {
                                continue;
                            }
                            this.skipMissingWarnings = true;
                            allValid = false;
                            f.setError(sp.error);
                            this.errorNotif(n, sp.error, 10000);
                            continue;
                        }
                    }
                    this.validatedFields.push(r);
                }
            }
            return allValid;
        },
        /* funciones de secciones */
        filterGeneralContact(collection) {
            return collection.find(f => f.type == 1);
        },
        filterCommercialContact(collection) {
            return collection.find(f => f.type == 2);
        },
        filterOperationsContact(collection) {
            return collection.find(f => f.type == 3);
        },
        /* funciones de secciones */
        filterGeneralContactForNumber() {
            return this.base.contacts.find(f => f.type == 1);
        },
        filterCommercialContactForNumber() {
            return this.base.contacts.find(f => f.type == 2);
        },
        filterOperationsContactForNumber() {
            return this.base.contacts.find(f => f.type == 3);
        },
        newGeneralContact() {
            return { type: 1, phone: this.newPhone.phone };
        },
        newCommercialContact() {
            return { type: 2, phone: this.newPhone.phone };
        },
        newOperationsContact() {
            return { type: 3, phone: this.newPhone.phone };
        },
        defaultPhoneObject() {
            return { country_calling_code: null, phone: null };
        },
        async extraValidations() {
            let ok = true;
            // validar que los 3 juegos de datos de contacto sean distintos
            let general = this.base.contacts.find(f => f.type == 1);
            let comm = this.base.contacts.find(f => f.type == 2);
            let ops = this.base.contacts.find(f => f.type == 3);

            let n1 = general.name.toLowerCase();
            let n2 = ops.name.toLowerCase();
            let n3 = comm.name.toLowerCase();

            let e1 = general.email.toLowerCase();
            let e2 = ops.email.toLowerCase();
            let e3 = comm.email.toLowerCase();

            let p1 = general.phone;
            let p2 = comm.phone;
            let p3 = ops.phone;

            if (n1 == n2 || n1 == n3 || n3 == n2) {
                console.log("name");
                ok = false;
            }
            if (e1 == e3 || e1 == e2 || e3 == e2) {
                console.log("email");
                ok = false;
            }
            if (p1 == p2 || p1 == p3 || p2 == p3) {
                console.log("phone");
                console.log("1 " + p1);
                console.log("2 " + p2);
                console.log("3 " + p3);
                ok = false;
            }

            if (!ok) {
                this.errorNotif("Error en datos de contacto",
                    "Es necesario que los datos de contacto del Director General, Director Comercial y Director de Operaciones sean distintos entre sí.", 10000);
            }
            return ok;
        },
        async executeNextStepOnboardingStep()
        {
            try {
                // Guardar información
                await this.executeSave();
                const isValid = await this.extraValidations();
                
                if(isValid) {
                    this.showLoading(true, "Guardando información...");
                    await this.checkIfCanUpdateSupplierOnboardingStep("contacts");
                    await this.$emit("updated", 1);
                    this.showLoading(false);
                }
            } catch (error) {
                this.showLoading(false);
                this.errorMssg = "Lo sentimos, no hemos podido guardar tu información de contacto. Puedes solicitar ayuda a través del chat."
            }
        }
    },
};
</script>

<style>
.documents {
    border: 2px solid #c2c2c2;
    border-radius: 5px;
    padding: 2rem;
}

.documents ul {
    margin-left: 1rem;
}

</style>
