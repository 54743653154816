<template>
    <section>
        <div class="container-fluid">
            <div class="vx-row m-0">
                <div class="vx-col w-full p-0">
                    <h4>Consentimiento Publicidad.</h4>
                    <p class="mt-5">
                        Autorizo a RedGirasol que me envíe publicidad y que use mis datos para mejorar mi experiencia y recibir promociones que se ajusten a mis necesidades.
                    </p>
                </div>
              </div>
        </div>
    </section>
</template>

<script>
export default {
  name: "LatestAdvertisingContract"
}
</script>