<template functional>
    <vx-card class="">
        <div class="vx-row mb-2">
            <div class="vx-col w-full">
            <h3 class="bold"><strong>{{props.title}}</strong></h3>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
            <p class="black">
                {{props.content}}
            </p>
            <br>
            </div>
        </div>
    </vx-card>
</template>

<script>
export default{
  name: 'text-widget',
}
</script>
