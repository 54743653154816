<template>
    <div id="pgmPotentialApplicantNOtes">
        <div class="vx-row">
            <div class="mt-1  vx-col w-4/5">
                <vs-textarea 
                    name="note"
                    v-model.lazy="noteDescription"
                    v-validate="ruleRequired"
                    cols="30" 
                    rows="1"
                    />
            </div>
            <div class="vx-col w-1/5 mt-2">
                <vs-button :disabled="isDisabled" class="w-full" @click="handleSave()" icon="send" color="dark"></vs-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PotentialApplicantNotes",
    props: {
        currentUserId: { type: [Number, String], required: true }
        , potentialApplicantId: { type: [Number, String], required: true }
        , auxRol: { type: [String], default: null }
        , status: { type: [Number, String], required: true }
    }, 
    data() {
        return {
            ruleRequired: 'required',
            noteDescription: ""
        }
    },
    computed: {
        isDisabled() {
            return this.AuxGuardName == "executive_manager";
        },
        isSupplierOrAgent () {
            return this.$acl.check('isSupplierOrAgent');
        }
    }, 
    methods: {
        async handleSave() {
            this.showLoading(true);
            try {
                const validity = await this.$validator.validateAll();
                if(!validity) {
                    this.showLoading(false);
                    return;
                }
                const payload = {
                    description: this.noteDescription,
                    user_id: this.currentUserId,
                    potential_applicant_id: this.potentialApplicantId,
                    aux_rol: this.isSupplierOrAgent ? 'supplier' : this.auxRol,
                    status: this.status
                };

                const { data } = await axios.post(`api/kof/post/notes/create`, payload);
                this.noteDescription = "";
                this.$emit('on-save');
                
            } catch (error) {
                this.showLoading(false);
            }
            this.showLoading(false);
        },

        handleClose() {
            this.$emit('on-close');
        }
    }

}
</script>

<style>

</style>