<!-- =========================================================================================
    ACTUALIZAR LAS VARIABLES COMPUTADAS EN DONDE SEA NECESARIO PARA LLENAR LOS DATOS CORRESPONDIENTES DEL USUARIO
    ASI COMO TAMBIEN LA FECHA ACTUAL DE FIRMA DEL CONTRATO
========================================================================================== -->
<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <div class="alt-2"></div>
                <h1 class="big_title text-center">
                    Comisión Mercantil de Instalador
                    <br><br>
                </h1>

                <div id="contracts" class="WordSection1 text-justify">
                    <!-- CARÁTULA -->
                    <strong>CONTRATO DE COMISIÓN MERCANTIL</strong> (en lo sucesivo “<u>Contrato</u>”) que celebran por una parte
                    {{userName}}{{personTypeText}}{{userLegalRepresentativeName}}, o por su propio derecho (en lo sucesivo
                    "<u>Comitente</u>"),  y por la otra Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo, 
                    representada en este acto por Jorge Armando Cárdenas Tijerina (en lo sucesivo "<u>Comisionista</u>" 
                    y en conjunto con el Comitente como las “<u>Partes</u>” o individualmente cada uno
                    como una “<u>Parte</u>”); mismo que sujetan al tenor de las siguientes Declaraciones y Cláusulas.
                    <br>
                    <br>
                    <!-- ------------------------- DECLARACIONES ------------------------------------- -->
                    <div style="text-align: center"><strong>DECLARACIONES</strong></div>
                    <br>

                    <ol class="text-justify" style="font-weight:bold">
                    <li style="font-size:14px!important"><span style="font-weight:normal">
                                Declara el Comisionista a través de su representante legal que: </span><br> <br>
                        <ol class="text-justify" style="font-weight:bold">
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Es una sociedad constituida de conformidad con las leyes de los Estados Unidos Mexicanos (“<u>México</u>”) 
                                        según consta en la escritura pública número 49,903 de fecha 14 de abril de 2016 otorgada ante la fe del 
                                        licenciado Eduardo Adolfo Manautou Ayala, titular de la Notaría Pública No. 123 con ejercicio en Monterrey, 
                                        Nuevo León y cuyo primer testimonio quedó inscrito en el registro público de la ciudad de Monterrey, Nuevo León 
                                        bajo el folio mercantil electrónico número 160394*1. Asimismo, declara que realiza operaciones de financiamiento
                                        colectivo en términos de la Ley FinTech, y que cuenta con los recursos y medios necesarios para mantener dicha autorización.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Su representante cuenta con las facultades necesarias y suficientes para suscribir el presente Contrato,
                                        mismas que se encuentran vigentes y no le han sido revocadas o limitadas en forma alguna a la fecha de
                                        celebración del presente contrato.
                                    </span></li><br>
                        </ol>
                    </li>
                    <li style="font-size:14px!important"><span style="font-weight:normal">
                                Declara el Comitente, por su propio derecho o por conducto de su representante declara que: </span><br> <br>
                        <ol class="text-justify" style="font-weight:bold">
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Ser una persona física mayor de edad o una sociedad debidamente constituida conforme a las leyes de México.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Que él, o en su caso el representante legal, cuenta con las facultades necesarias para celebrar el presente
                                        contrato según poder que en este acto se tiene a la vista.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Es un usuario registrado del Sitio y reconoce y declara haber revisado, entendido y aceptado sin reserva
                                        alguna los términos y condiciones, avisos legales y cualesquiera otras cláusulas, declaraciones, derechos
                                        y/u obligaciones que contiene dicho Sitio.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Mantiene, a su nombre una cuenta bancaria que podrá ser revelada por el Comisionista a través del Sitio.
                                    </span></li><br>
                        </ol>
                    </li>
                    <li style="font-size:14px!important"><span style="font-weight:normal">Declaran las PARTES que :</span><br> <br>
                        <ol class="text-justify" style="font-weight:bold">
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Entienden y conocen la terminología empleada en el presente Contrato, no obstante la utilización de
                                        tecnicismos propios de la materia que aquí se regula y que son empleados en la determinación de los
                                        derechos y obligaciones que aquí se pactan.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Se reconocen mutuamente la personalidad y, en su caso, representación con la cual cada una de las Partes
                                        comparece a la celebración del presente Contrato.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Están de acuerdo en celebrar el presente Contrato, sujetándolo al tenor de las siguientes cláusulas,
                                        apéndices, anexos y convenios accesorios que eventualmente se llegaren a celebrar entre las Partes
                                        incluyendo aquellos que se celebren con o sin la intervención o comparecencia de terceros.
                                    </span></li><br>
                        </ol>
                    </li>
                    </ol>

                    En virtud de lo anterior, las Partes, de común acuerdo, se sujetan a las siguientes:<br>

                    <br>
                    <div style="text-align: center"><strong>CLÁUSULAS</strong></div>
                    <br>

                    <ol class="text-justify" style="font-weight:bold">
                    <li style="font-size:14px!important"><u>Objeto del Contrato.</u><span style="font-weight:normal">&nbsp;
                                Por virtud del presente Contrato, el Comitente otorga y confiere una comisión mercantil al Comisionista para prestar en
                                su nombre y representación los Servicios, correspondientes en promover el financiamiento del Monto requerido por el Solicitante 
                                para la adquisición de los Productos propiedad del Comitente de manera no exclusiva dentro del Territorio, e incluyendo sin 
                                limitar la actividades listadas en la Cláusula 3 del presente. <br><br>

                                Por este mismo medio, El Comisionista acepta expresamente la comisión mercantil objeto del presente Contrato, 
                                obligándose con el Comitente a desempeñarla de conformidad con las disposiciones de este Contrato, sin exceder de las facultades 
                                conferidas en el mismo y sus accesorios. 
                            </span></li> <br> <br>

                    <li style="font-size:14px!important"><u>Desempeño de la comisión.</u><span style="font-weight:normal">&nbsp;
                                El Comisionista, para el desempeño de sus obligaciones, deberá obrar con buena fe y
                                realizará sus mejores esfuerzos para el cumplimiento adecuado de la comisión aquí encomendada. El Comisionista deberá
                                hacer su mejor esfuerzo por conocer en todo momento las mejores prácticas de promoción de los Productos del Proyecto
                                en el Sitio.<br><br>

                                Las Partes, asimismo, reconocen y aceptan que una vez que se hubieren instalado los Productos
                                en el domicilio de los Solicitantes, la reubicación de los mismos, por cualquier causa en beneficio de
                                los Solicitantes no tendrá ningún costo para el Comisionista.
                            </span> </li> <br> <br>

                    <li style="font-size:14px!important"><u>Actividades que comprende la comisión.</u><span style="font-weight:normal">&nbsp;
                                Durante la vigencia de este Contrato, el Comisionista estará facultado
                                para desarrollar las siguientes actividades con el fin de promover los Productos con los Solicitantes, Inversionistas
                                y/o el Comitente:</span> <br> <br>
                        <ol class="text-justify" style="font-weight:bold">
                        <li style="font-size:14px!important">Promoción.<span style="font-weight:normal">&nbsp;
                                        El Comisionista estará facultado para promocionar, de la manera más amplia y a su
                                        discreción, los Productos de una manera comercialmente razonable, pudiendo transmitir material
                                        promocional e información diversa a los Solicitantes e Inversionistas según sea
                                        necesario para cumplir el objeto de este Contrato. La publicidad y promoción podrá ser efectuada
                                        mediante todos aquellos medios que el Comisionista considere adecuados para el correcto desarrollo de
                                        las actividades de promoción.
                                    </span></li><br>
                        <li style="font-size:14px!important">Entrega de Información.<span style="font-weight:normal">&nbsp;
                                        El Comisionista estará facultado para entregar toda información relacionada a
                                        los Productos.
                                    </span></li><br>
                        <li style="font-size:14px!important">Medio de venta.<span style="font-weight:normal">&nbsp;
                                        El Comisionista a través de su Sitio celebrará los siguientes actos jurídicos para la venta
                                        de los Productos, sin limitar a: (i) este contrato de comisión mercantil, (ii) contrato de comisión
                                        mercantil del Comisionista con los Inversionistas, (iii) contrato de comisión mercantil
                                        del Comisionista con los Solicitantes y (iv) los Documentos del Crédito.
                                    </span></li><br>
                        </ol>
                    </li>

                    <li style="font-size:14px!important"><u>Actividades que el Comitente se obliga a realizar.</u><span style="font-weight:normal">&nbsp;
                                Durante la vigencia de este Contrato, el Comitente se obliga a desarrollar las siguientes actividades:</span><br><br>
                        <ol class="text-justify" style="font-weight:bold">
                        <li style="font-size:14px!important">Firma del contrato de crédito.<span style="font-weight:normal">&nbsp;
                                        En caso de que el Solicitante no cuente con su firma
                                        electrónica avanzada emitida por el Servicio de Administración Tributaria o bien la firma electrónica
                                        emitida por la Secretaría de Economía, el Comitente deberá obtener la firma de los Documentos del
                                        Crédito que celebra con cada Solicitante. El Comitente se obliga a entregar los originales de los
                                        Documentos del Crédito que habrá de celebrarse entre cada Solicitante y los Inversionistas.
                                        El Comitente antes de realizar cualquier instalación de los Productos deberá
                                        recibir firmados los Documentos del Crédito, mismos que deberá mandar físicamente
                                        al Comisionista. Los gastos derivados de los documentos señalados en esta Cláusula serán cubiertos por el
                                        Comitente.
                                    </span></li><br>
                        <li style="font-size:14px!important">Garantía.<span style="font-weight:normal">&nbsp;
                                        El Comitente se obliga a mantener al Solicitante a salvo, conforme a las
                                        políticas de garantía que se ofrezcan a través del contrato de compraventa de los Productos.
                                    </span></li><br>
                        <li style="font-size:14px!important">Precio.<span style="font-weight:normal">&nbsp;
                                        El Comitente se obliga a mantener el precio de los Productos que tengan registrados en la solicitud
                                        del Solicitante a través del Sitio. En virtud de lo cual, el Comitente no podrá modificar
                                        los precios de los Productos que ya hubieren sido pactados con cada Solicitante.
                                    </span></li><br>
                        <li style="font-size:14px!important">Tipo de cambio.<span style="font-weight:normal">&nbsp;
                                        El Comitente se obliga a respetar el tipo de cambio fix publicado en el Diario Oficial de
                                        la Federación desde el momento en que el Comisionista publica en el Sitio el proyecto para el
                                        financiamiento de los Productos por parte de los Inversionistas y hasta 60 (sesenta) días
                                        naturales posteriores a dicha fecha.</span></li><br>
                        <li style="font-size:14px!important">Forma de pago.<span style="font-weight:normal">&nbsp;
                                        El Comitente reconoce y acepta que la forma de pago para los Productos será a través
                                        del Comisionista en nombre y representación y por cuenta del Solicitante, mismo que se
                                        pagará bajo las condiciones que el contrato de compraventa de los Productos establezca, incluyendo
                                        sin limitar, a que el Comisionista reciba en original los Documentos del Crédito debidamente
                                        firmados por el Solicitante y el Comitente (en los contratos en los que participe).
                                    </span></li><br>
                        </ol>
                    </li>

                    <li style="font-size:14px!important"><u>Contraprestación.</u><span style="font-weight:normal">&nbsp;
                                Las Partes acuerdan que el Comisionista percibirá una contraprestación, equivalente al 6%
                                (seis por ciento) sobre el valor de la factura de los Productos, más el correspondiente Impuesto al Valor
                                Agregado cuando el Comisionista obtenga el Monto, por medio de los Inversionistas <br> <br>

                                Esta contraprestación será pagada mediante retención realizada por el Comisionista al primer pago que realice 
                                al Comitente por la adquisición de los Productos establecido en la Cláusula 4.5 del presente Contrato.
                            </span> </li> <br>

                    <li style="font-size:14px!important"><u>Pago de la contraprestación.</u><span style="font-weight:normal">&nbsp;
                                El derecho del Comisionista a recibir la contraprestación bajo éste Contrato se
                                generará una vez que el Comisionista, realice el pago de los Productos al Comitente.</span></li> <br>

                    <li style="font-size:14px!important"><u>Vigencia.</u><span style="font-weight:normal">&nbsp;
                                El presente Contrato tendrá una duración indefinida, y empezará a surtir sus efectos a partir de la fecha
                                de su firma, pudiendo darse por terminado anticipadamente, sin necesidad de expresión de causa por cualquiera de las
                                Partes y sin necesidad de declaración judicial, previa notificación con 30 (treinta) días hábiles de anticipación a la fecha en
                                la que la Parte solicitante pretenda dar por terminado el presente Contrato, en el entendimiento de que aquellas
                                obligaciones pendientes de realizar y que hayan sido adquiridas por cualquiera de las Partes con antelación a la
                                cancelación del mismo, deberán ser cumplidas en su totalidad.
                            </span></li> <br>

                    <li style="font-size:14px!important"><u>Rescisión.</u><span style="font-weight:normal">&nbsp;
                                Las Partes podrán rescindir el presente Contrato sin previa declaración judicial, por falta de
                                cumplimiento de su contraparte de cualquiera de sus obligaciones derivadas del mismo y en general por los casos de
                                incumplimiento que al afecto señalen las disposiciones legales correspondientes, para lo cual bastará que la Parte afectada
                                notifique por escrito a la otra con 10 (diez) días naturales de anticipación a la fecha en que deba de surtir efectos la misma.
                                La Parte que se encuentra en incumplimiento tendrá 30 (treinta) días naturales para solventar la acción que originó
                                el incumplimiento a partir de la notificación de incumplimiento de la contraparte. Además, las Partes convienen en forma
                                expresa que las Partes podrán rescindir el presente Contrato, sin necesidad de declaración judicial, en caso de que se
                                presente alguna de las siguientes circunstancias:</span> <br> <br>
                        <ol class="text-justify" style="font-weight:bold">
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Han proporcionado datos falsos al registrarse o al formular el Contrato, o si durante su vigencia y con
                                        motivo de las obligaciones derivadas del mismo, proporcionare información falsa, errónea, insuficiente, o
                                        si la información solicitada no se proporcionare o se hiciere en forma extemporánea.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Si se declaran en disolución, liquidación, quiebra o suspensión de pagos.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Si son emplazados a huelga, o existe amenaza de que se inicie el procedimiento correspondiente.
                                    </span></li><br>
                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Si incumplen, cumplen en forma parcial, suspenden, o de cualquier forma prestan las actividades aquí
                                        establecidas de manera irregular, deficiente, negligente o irresponsable; o bien por violar o incumplir
                                        cualquiera de las obligaciones a su cargo, con motivo o derivadas del presente contrato.
                                    <br></span></li><br>
                        </ol>
                        <span style="font-weight:normal">
                                    No obstante lo anterior, el Comisionista podrá rescindir el presente Contrato en cualquier momento, sin justificación
                                    alguna y sin necesidad de declaración judicial cuando el Comitente reciba un pago del Solicitante,
                                    siempre y cuando dicho pago esté relacionado con los Documentos del Crédito.
                                </span> <br> <br>
                    </li>

                    <li style="font-size:14px!important"><u>Efectos de la Terminación.</u><span style="font-weight:normal">&nbsp;
                                Con excepción de lo establecido en las cláusulas 7 y 8, una vez terminado el presente
                                Contrato, los derechos y obligaciones de las Partes terminarán.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Relación entre las Partes.</u><span style="font-weight:normal">&nbsp;
                                Queda expresamente convenido que de ninguna manera el presente contrato deberá o
                                podrá ser considerado o interpretado para crear una coinversión, sociedad, representación, asociación, asociación en
                                participación o relación de agencia entre las Partes para ningún propósito.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>No competencia.</u><span style="font-weight:normal">&nbsp;
                                Desde la firma del presente Contrato y durante su vigencia y hasta 3 (tres) años después de la
                                terminación del mismo, el Comitente no podrá, ni por cuenta propia ni a través de uno o varios terceros:</span> <br><br>
                        <ol class="text-justify" style="font-weight:bold">
                        <li class="dosdigitos" style="font-size:14px!important"><span style="font-weight:normal">
                                        Contactar o captar el negocio de (o procurar o asistir en el contacto o captación del negocio de) cualquier
                                        Solicitante para servicios de financiamiento colectivo;
                                    </span></li><br>
                        <li class="dosdigitos" style="font-size:14px!important"><span style="font-weight:normal">
                                        Aceptar (o procurar o asistir en la aceptación de) cualquier negocio de cualquier Solicitante
                                        para servicios de financiamiento colectivo;
                                    </span></li><br>
                        <li class="dosdigitos" style="font-size:14px!important"><span style="font-weight:normal">
                                        Emplear, ofrecer empleo a, o solicitar el empleo o compromiso de, o de otra manera alentar a cualquier
                                        individuo que sea empleado, directo o indirecto de Red Girasol a terminar su relación laboral con los
                                        mismos, sin importar si dicho individuo pudiere incurrir en algún incumplimiento de su contrato o los
                                        términos del empleo al terminar el empleo con Red Girasol;
                                    </span></li><br>
                        <li class="dosdigitos" style="font-size:14px!important"><span style="font-weight:normal">
                                        Procurar o asistir a cualquier Persona para emplear, ofrecer empleo a, o solicitar el empleo o compromiso
                                        de, o de otra manera alentar a cualquier individuo a terminar su relación laboral con el Comisionista; o
                                    </span></li><br>
                        <li class="dosdigitos" style="font-size:14px!important"><span style="font-weight:normal">
                                        Interferir o intentar interferir con las actividades de Red Girasol, como sociedad y como Comisionista
                                        bajo el presente Contrato, o persuadir o intentar persuadir a cualquier Solicitante,
                                        Proveedor o empleado de Red Girasol para descontinuar o alterar la relación de dicha Persona con Red
                                        Girasol.
                                    </span></li><br>
                        </ol>
                    </li>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Impuestos.</u><span style="font-weight:normal">&nbsp;
                                Todos y cada uno de los impuestos, contribuciones, derechos y obligaciones que deban pagarse con
                                motivo de las operaciones derivadas de las obligaciones incluidas en el presente Contrato, correrán por cuenta de quien
                                para tales efectos sea señalado como causante por las leyes fiscales aplicables.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Avisos y notificaciones.</u><span style="font-weight:normal">&nbsp;
                                Todos los avisos, notificaciones, demandas y comunicaciones requeridas conforme a este
                                Contrato deberán ser enviados por correo electrónico y por escrito y se considerará que se notificó debidamente si se
                                entrega personalmente o se envía por correo certificado, correo registrado con el importe pre-pagado. Las Partes señalan
                                como sus domicilios y direcciones convencionales para efectos de oír y recibir notificaciones, los siguientes:
                                </span><br><br>

                        Al Comisionista: <br><br>
                        <span style="font-weight:normal">
                                Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo <br>
                                Domicilio: Eugenio Garza Sada 3820 Int. 801, Colonia Mas Palomas, <br>
                                Monterrey, Nuevo León México, C.P. 64780 <br>
                                Tel: (81) 1107 0504<br>
                                Correo: contacto@redgirasol.com<br>
                                Atención: Jorge Armando Cárdenas Tijerina<br><br>
                                </span>

                        Al Comitente: <br><br>
                        <span style="font-weight:normal">
                                Nombre: {{ userName }}{{ userLegalRepresentativeName }}<br>
                                Tel: {{ userPhone }}<br>
                                Correo: {{ userEmail }}<br>
                                Fecha: {{ currentDate }}<br><br>

                                Cualquier cambio o modificación de los domicilios aquí convenidos, deberá ser debidamente notificado por correo
                                electrónico con acuse de recibo a la otra Parte para que surta efectos con por lo menos 10 (diez) días previos a la fecha en
                                que pretenda la Parte notificante hacer cumplir dicho cambio de domicilio. De no cumplirse dicho requisito, la
                                notificación hecha al último domicilio conocido surtirá efectos plenos. <br> <br>
                                </span>
                    </li>

                        <li class="dosdigitos" style="font-size:14px!important"><u>Cesión de Derechos.</u><span style="font-weight:normal">&nbsp;
                            El Comitente no podrá ceder ni transferir los derechos y obligaciones adquiridos por virtud del presente Contrato, sin el consentimiento 
                            previo y por escrito del Comisionista. En caso contrario, aquella cesión o trasferencia de derechos y/u obligaciones sin el consentimiento 
                            de la otra Parte será nula. 
                        </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Información Confidencial.</u><span style="font-weight:normal">
                                Las Partes convienen en que cualquier información revelada o proporcionada por las
                                Partes (cualquiera de sus filiales, sociedad controladora o subsidiarias), así como toda la información generada con
                                relación al cumplimiento del objeto del presente Contrato, ya sea de manera escrita, verbal, electrónica, magnética o
                                cualquier otra tecnología y demás información confidencial, o de cualquier otra índole, será considerada propiedad de
                                dicha Parte. <br> <br>

                                Las Partes no revelarán a terceras Personas ninguna información propiedad de la otra Parte, debiendo utilizarla
                                exclusivamente para el desarrollo del objeto de este instrumento. Una vez terminado este Contrato, las Partes deberán
                                entregar en forma inmediata a la otra Parte toda la información y materiales que le hayan sido proporcionados.
                            </span> <br> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Protección de Datos Personales.</u><span style="font-weight:normal">&nbsp;
                                Los datos personales que llegara a recabar cada una de las Partes de personas físicas, ya sean dependientes de la otra y/o de terceros, 
                                que sean utilizados para el objeto de este Contrato, serán utilizados únicamente para tales fines y cumplimiento del presente Contrato, 
                                comprometiéndose cada una de ellas a no vender, ceder o transferir a terceros ajenos los datos personales a que tengan acceso, sin su 
                                consentimiento previo. Sin perjuicio de lo anterior, cada una de las Partes podrá transferir los datos personales cuando dicha transferencia 
                                se encuentre prevista en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. Cada una de las Partes adoptará las 
                                medidas de seguridad, administrativas, técnicas y físicas, necesarias para proteger los datos personales contra daño, pérdida, alteración, 
                                destrucción o el uso, acceso o tratamiento no autorizado. <br><br>

                                Las personas físicas de las cuales se transmita datos personales en virtud de la celebración de este instrumento, como titular de datos personales, 
                                deberá autorizar a las Partes para que utilice los datos conforme al objeto de este contrato y podrá ejercitar ante éstas, los derechos de acceso, 
                                rectificación, cancelación y oposición (derechos ARCO) establecidos por la Ley Federal de Protección de Datos Personales en Posesión de los 
                                Particulares. Asimismo, podrá revocar en todo momento, el consentimiento que ha otorgado y que fuere necesario para el tratamiento de sus datos personales, 
                                así como limitar el uso o divulgación de los mismos. Lo anterior, a través del envío de su petición por escrito al domicilio de cada una de las Partes. <br><br>

                                A la terminación del presente Contrato, cada una de las Partes devolverá a la otra todos los datos personales que haya recibido de la otra y destruirá o 
                                borrará los registros o soportes en los cuales consten dichos datos personales, siempre y cuando no exista obligación de conservarlos, en los términos de la ley.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Acuerdo Único y modificaciones.</u><span style="font-weight:normal">&nbsp;
                                Lo contenido en este Contrato constituye el acuerdo total entre las Partes
                                respecto al objeto de este Contrato, sin perjuicio de las demás relaciones jurídicas que pudieren sostener las Partes
                                mediante diversos acuerdos, los cuales para efectos de la presente cláusula quedan excluidos, y por lo tanto prevalecerá
                                sobre cualquier otro acuerdo, entendimiento, negociación o discusión oral o escrita anterior que haya sido formulada por
                                las Partes respecto al objeto del presente, a no ser que se incorporen a este Contrato, de mutuo acuerdo entre ambas.
                                Cualquier modificación al presente Contrato deberá ser acordado de común acuerdo entre las Partes y formalizado por
                                escrito, celebrándose a su efecto el convenio modificatorio por escrito correspondiente, mismo que deberá estar firmado
                                por ambas Partes.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Caso fortuito y fuerza mayor.</u><span style="font-weight:normal">&nbsp;
                                Las Partes no serán responsables por la falta de realización de las actividades
                                establecidas en el presente Contrato, cuando existan causas fuera de su control incluyendo órdenes de cualesquiera
                                autoridades gubernamentales, civiles y/o militares, huelgas, inundaciones, epidemias, guerras, disturbios y demás causas
                                análogas de caso fortuito o fuerza mayor.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Ejercicio de Derechos.</u><span style="font-weight:normal">&nbsp;
                                El hecho de que alguna de las Partes no ejerza alguno de sus derechos derivados del
                                presente contrato, no significa, ni deberá ser considerado, como una renuncia o pérdida de tales derechos.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Correlación de documentos.</u><span style="font-weight:normal">&nbsp;
                                Las PARTES no podrán alegar desconocimiento o inaplicabilidad de alguna de las
                                normas que en cualquier documento se establezcan, por lo que cualquier incumplimiento de los mismos será un
                                incumplimiento a los términos y condiciones pactados en este Contrato, así también no podrá alegarse por las Partes el
                                desconocimiento de la terminología empleada en este Contrato, sus accesorios y demás instrumentos legales relacionados
                                con este Contrato y con la relación jurídica que sostengan las Partes por diversos acuerdos.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Firma.</u><span style="font-weight:normal">&nbsp;
                                Las Partes acuerdan que para la firma del presente Contrato podrán: (i) firmar autógrafamente, (ii) por
                                medio de la Firma Electrónica Avanzada emitida por el Servicio de Administración Tributaria, y de conformidad con los
                                artículos 2, 7, 8, 22 y demás aplicables de la Ley de Firma Electrónica Avanzada, (iii) por medio de la firma electrónica
                                emitida por la Secretaría de Economía o (iv) mediante clic en el recuadro de ACEPTO junto con el ingreso de la
                                contraseña respectiva. Cualquiera de los métodos previamente anunciados tendrá como efecto el sometimiento expreso a
                                las disposiciones del presente y por lo tanto surtirán efectos plenos para las Partes, frente a ellos mismos y frente a
                                terceros. Así mismo, el Comitente expresamente renuncia a argumentar desconocimiento de la firma del presente
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Definciones.</u><span style="font-weight:normal">&nbsp;
                                Las Partes expresamente acuerdan que los términos definidos establecidos en el Apéndice 1 del
                                presente y usados en el clausulado del presente contrato, tendrán el alcance y significado que en los mismos se establece y
                                no su significado literal. Asimismo, las Partes convienen en utilizar dichos términos en los Anexos del presente contrato
                                que al efecto se celebren.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Encabezados y Títulos de las cláusulas.</u><span style="font-weight:normal">&nbsp;
                                Los encabezados y los títulos de las Cláusulas que aparecen en el
                                presente Contrato, se han puesto con el exclusivo propósito de facilitar su lectura, por tanto, no necesariamente definen ni
                                limitan el contenido de las mismas. Para efectos de interpretación de cada Cláusula deberá atenderse exclusivamente a su
                                contenido y de ninguna manera a su título.
                            </span></li> <br>

                    <li class="dosdigitos" style="font-size:14px!important"><u>Legislación  y jurisdicción aplicable.</u><span style="font-weight:normal">&nbsp;
                                Las Partes se someten expresamente para la interpretación, cumplimiento y
                                ejecución de este Contrato a lo establecido en el Código de Comercio, así como a la jurisdicción de los tribunales
                                competentes de la ciudad de Monterrey, Nuevo León, México, respecto a cualquier acción o procedimiento, interpretación
                                y cumplimiento de todo lo pactado en este Contrato, renunciando expresamente a cualquier jurisdicción que les pudiere
                                corresponder en la actualidad o en el futuro por virtud de sus domicilios actuales o futuros o por cualquier otra razón.
                                <br><br>
                                El presente Contrato se firma de conformidad por las Partes que intervienen en el mismo, sin que mediare entre ellos vicio
                        del consentimiento alguno, en la Ciudad de Monterrey, Nuevo León, el día {{ currentDate }}. </span> </li><br> <br>
                    </ol>

                    <br>
                    <div style="text-align: center"><strong><u>APÉNDICE 1</u></strong></div>
                    <div style="text-align: center"><strong>Definciones</strong></div>
                    <br>

                    Para los efectos de este contrato, los siguientes términos tendrán el significado que a continuación se señalan: <br> <br>

                    <ol class="text-justify" style="font-weight:bold">
                    <li style="font-size:14px!important">"Contrato": <span style="font-weight:normal">
                                Significa el presente Contrato de Comisión, sus apéndices y anexos.
                            </span></li> <br>
                    <li style="font-size:14px!important">"Crédito": <span style="font-weight:normal">
                                Significa el contrato de crédito a ser celebrado por y entre los Inversionistas, representados por
                                el Comisionista, con el Solicitante para la adquisición de los Productos.
                            </span></li> <br>
                    <li style="font-size:14px!important">“Documentos del Crédito”: <span style="font-weight:normal">
                                Significa (i) el Crédito, (ii) un contrato de prenda sobre los Productos entre los
                                Inversionistas y el Comitente, (iii) un pagaré a ser firmado por el Comitente en favor de los Inversionistas; y (iv)
                                un contrato de compraventa de bien inmueble por accesión entre el Comitente y el Proveedor.
                            </span></li> <br>
                    <li style="font-size:14px!important">"Inversionistas": <span style="font-weight:normal">
                                Es la o las personas físicas o morales que en conjunto financiarán a un
                                Solicitante para la adquisición de los Productos.
                            </span></li> <br>
                    <li style="font-size:14px!important">"Ley FinTech": <span style="font-weight:normal">
                                Significa la Ley para Regular las Instituciones de Tecnología Financiera.
                            </span></li> <br>
                    <li style="font-size:14px!important">"Monto": <span style="font-weight:normal">
                                Es la cantidad que los Inversionistas deberán financiar al Solicitante para la
                                adquisición de los Productos.
                            </span></li> <br>
                    <li style="font-size:14px!important">"Parte": <span style="font-weight:normal">
                                Lo constituyen el Comisionista o el Comitente en forma indistinta y separada, según corresponda.
                            </span></li> <br>
                    <li style="font-size:14px!important">"Partes": <span style="font-weight:normal">
                                Lo constituyen el Comisionista y el Comitente en forma conjunta.
                            </span></li> <br>
                    <li style="font-size:14px!important">"Persona": <span style="font-weight:normal">
                                Significa e incluye personas físicas, corporaciones, sociedades, sociedades en comandita simple,
                                sociedades de responsabilidad limitada, empresas de responsabilidad limitada, sociedades generales, sociedades
                                anónimas, coinversiones, asociaciones, empresas, fundaciones de intereses privados, fideicomisos, bancos,
                                fiduciarios, fideicomisos inmobiliarios, fideicomisos comerciales u otras organizaciones, sean personas morales o
                                no, y todas las Autoridades Gubernamentales.
                            </span></li> <br>
                    <li class="dosdigitos" style="font-size:14px!important">"Productos": <span style="font-weight:normal">
                                Son los activos propiedad del Comitente que desea vender a los Solicitantes.
                            </span></li> <br>
                    <li class="dosdigitos" style="font-size:14px!important">"Proveedor": <span style="font-weight:normal">
                                Es la persona física o moral que pretende vender productos a Solicitantes a través del Sitio.
                            </span></li> <br>
                    <li class="dosdigitos" style="font-size:14px!important">"Red Girasol": <span style="font-weight:normal">
                                Es la persona moral Red Girasol, S.A.P.I. de C.V.
                            </span></li> <br>
                    <li class="dosdigitos" style="font-size:14px!important">"Servicios": <span style="font-weight:normal">
                                Serán todo tipo de actividades tendientes a conseguir financiamiento para la adquisición de
                                los Productos del Comitente, las cuales entre otras incluirán, la exhibición de la información del
                                Comitente, la realización de todo tipo de actividades mediante las cuales se promueva la inversión para la
                                adquisición de los Productos, incluyendo la entrega de todo tipo de información relativa a
                                la transacción.
                            </span></li> <br>
                    <li class="dosdigitos" style="font-size:14px!important">"Solicitante": <span style="font-weight:normal">
                                Es toda persona física o moral, susceptible de adquirir cualquier Producto derivado de la
                                promoción efectuada por el Comisionista a través del financiamiento de los Inversionistas.
                            </span></li> <br>
                    <li class="dosdigitos" style="font-size:14px!important">"Sitio": <span style="font-weight:normal">
                                Significa el dominio <a href="https://www.redgirasol.com/" style="color: blue"><u>https://www.redgirasol.com/</u></a>
                                localizado en el Internet.
                            </span></li> <br>
                    <li class="dosdigitos" style="font-size:14px!important">"Territorio": <span style="font-weight:normal">
                                Significa el territorio que comprenden los Estados Unidos Mexicanos.
                            </span></li> <br>
                    </ol>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import investorInfoHelper from "@/helpers/investorInfoHelper";
import dateHelper from '@mixins/dateHelper';

export default {
name: "LatestSupplierMerchantContract",
props: {
    fillUserData: {
      type: Boolean
      , default: false
    },
    signedDate: { required: false, default:null },
    onlyCoverPage: { required: false, default:false }
  },
  mixins: [investorInfoHelper, dateHelper],
computed:{
    userName: function () {
        if (this.fillUserData && this.user) {
            return this.user.complete_name;
        } else {
            return "";
        }
      },
      personTypeText: function () {
        if (this.fillUserData && this.user) {
            if (!this.userIsMoral(this.user.user_person_type)) {
                return " por su propio derecho";
            }
        } else {
            return "";
        }
      },
      userLegalRepresentativeName: function () {
        if (this.fillUserData && this.user) {
            let name = "";
            if (this.userIsMoral(this.user.user_person_type)) {
                name = " representada en este acto por "+this.user.legal_representative_name;                
            }
            return name;
        } else {
            return "";
        }
      },
      userEmail: function () {
        if (this.fillUserData) {
            return (this.user === null) ? "-" : this.user.email;
        } else {
            return "";
        }
      },
      userPhone: function () {
        if (this.fillUserData) {
            return (this.user === null) ? "-" : this.user.phone;
        } else {
            return "";
        }
      },
      currentDate: function () {
        if (this.signedDate) {
            return this.signedDate;
        } else {
            return this.signatureFormatDate(new Date());
        }
      },
  },
}
</script>

<style scoped>
        .page-break { page-break-before: always; }

        li ul > li:before {
            list-style-type: none !important;
            content: none!important;
            counter-increment: none!important;
        }

        #contracts ol {
            counter-reset: item;
            padding: 2.5rem;
        }

        #contracts li {
            display: block;
        }

        #contracts li:before {
            content: counters(item, ".") ".";
            margin-left: -2em;
            margin-right: 7px;
            counter-increment: item
        }

        #contracts li ol > li {
            margin-left: 5px;
        }

        #contracts li ol > li:before {
            margin-left: -30px;
            margin-right: 0px;
        }

        #contracts #second li ol > li {
            margin-left: 15px;
        }

        #contracts #second li ol > li:before {
            margin-left: -40px;
            margin-right: 0px;
        }

        #contracts ol li p {
            display: inline;
        }

        #footer {
            position: fixed;
            color: black;
            font-size: 0.9em;
            text-align: right;
        }
        #footer {
            bottom: 0;
            border-top: 0.1pt solid black;
        }
        .page-number:before {
            content: counter(page);
        }
		.text-justify {
			text-align: justify !important;
		}
		.text-center {
			text-align: center;
		}
		.text-bold {
			font-weight: bold;
		}
		.text-normal {
			font-weight: normal;
		}
		.custom-class {
			font-size:10.0pt;
			font-family:'Arial',sans-serif; 
		}
    </style>