<template>
    <vx-card class="overlay-card overflow-hidden" @click="popUpFunction">
        <template>
            <img :src="media.img" alt="user-profile-cover" class="responsive">
            <div class="card-overlay text-white flex flex-col justify-between">
                <h4 class="text-white">{{ text }}</h4>
            </div>
        </template>
    </vx-card>
</template>
<script>

export default {
  name: 'feedback-card',
  props: {
      text: {
          type: String,
          required: true
      },
      popUpFunction: {
          type: Function,
          required: true
      }
  },
  data() {
      return {
            media: { img: require('@assets/images/figs/fig1.png') },
      }
  }
}
</script>
<style scoped>
div.card-overlay{
    padding-top: 2rem !important;
    height: none !important;
}

.overlay-card {
    cursor: pointer;
}
</style>