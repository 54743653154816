<template>
  <span class="vs-input-hint">
    <slot></slot>
  </span>
</template>

<script>
export default {
    name: "VsInputHint"
}
</script>

<style scoped>
.vs-input-hint {
    color: #999999;
    font-size: 12px;
}
</style>