<template functional>
    <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
      <feather-icon
        icon="AwardIcon"
        class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow"
        svgClasses="h-8 w-8"
      ></feather-icon>
      <h1 class="mb-6 text-white">{{props.main}}{{ props.span }}{{ props.after_span }}</h1>
      <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
        Panel de administración - <strong>Proveedores</strong>.
      </p>
    </vx-card>
</template>
<script>
export default {
  name: "title-widget"
};
</script>
