<template>
   <vx-card class="mb-base h-auto" title="Resumen de proyectos.">
      <vs-table ref="table" stripe :data="slas" noDataText="No hay datos disponibles">          
           <template slot="thead">
              <vs-th class="bold">ESTADO</vs-th>
              <vs-th class="bold">¿QUÉ FALTA?</vs-th>
              <vs-th class="bold">CANTIDAD</vs-th>
              <vs-th class="bold">PENDIENTE<br>PROVEEDOR</vs-th>
              <vs-th class="bold">PENDIENTE<br>REDGIRASOL</vs-th>
              <vs-th class="bold">MONTO O TOTAL</vs-th>
            </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
              <vs-td>
                <p class="bold text-md">{{ tr.title }}</p>
              </vs-td>
              <vs-td>
                  <p class="pre-formatted text-sm">{{ tr.description }}</p>
              </vs-td>
              <vs-td>
                 <vs-button type="line" @click="showProjectsForStatus(tr.status)">{{tr.total}}</vs-button>
              </vs-td>
              <vs-td>
                 <p class="text-sm">
                    <span class="bold">En tiempo: </span>{{ tr.onTime != "na" ? tr.onTime : "N/A" }}<br>
                    <span class="bold">Retrasado: </span>{{ tr.delayed != "na" ? tr.delayed : "N/A" }}
                 </p>
              </vs-td>
               <vs-td>
                  <p class="text-sm">
                     <span class="bold">En tiempo: </span>{{ tr.rgOnTime != "na" ? tr.rgOnTime : "N/A" }}<br>
                     <span class="bold">Retrasado: </span>{{ tr.rgDelayed != "na" ? tr.rgDelayed : "N/A" }}                
                  </p>
              </vs-td>

              <vs-td>
                {{ tr.amount }}
              </vs-td>
              
            </vs-tr>
          </template>
        </vs-table>
   </vx-card>
</template>

<script>
const reviewText = "Verifica si existe algún comentario por parte \ndel equipo de RedGirasol en algún documento de la solicitud,\nde lo contrario RedGirasol aun esta en proceso de revisión."
const preapprovedText = "Verifica si existe algún comentario por parte \ndel equipo de RedGirasol en algún documento de la solicitud,\nde lo contrario RedGirasol aun esta en proceso de revisión."
const approvedText = "El Solicitante debe aceptar el crédito y realizar el pago inicial \npara que el proyecto pueda ser publicado para fondeo.\nSi ya fue realizado lo anterior, entonces RedGirasol \npublicará el proyecto para iniciar el fondeo."
const fundingText = "La Red debe fondear el Proyecto \ndentro de los 30 días una vez se publica el proyecto."
const contractsText = "Debes subir las facturas correspondientes al proyecto, \nagregar los datos técnicos (números de serie) e indicar cómo deseas\nfirmar el contrato (electrónico o de forma autógrafa) \npara que RedGirasol pueda generar dichos contratos.\nUna vez firmados los contratos, \nRedGirasol te realiza el pago para iniciar la instalación."
const installationText = "Debes subir la evidencia y solicitar la verificación de la misma, \nuna vez realizado lo anterior, el quipo de RedGirasol te notificará \ncuando haya concluido la verificación \ny de esa forma realizar el pago de finiquito."
import formatHelper from '@mixins/formatHelper';

export default {
   name: 'projects-summary-widget',
   mixins: [formatHelper],
   data(){
      return {
         slas: [],
         summary: null,
      }
   },
   async beforeMount(){
      this.showLoading(true);
      await this.getProjectsInfo();
      this.showLoading(false);
   },

   methods: {
      async getProjectsInfo(){
         try {
            let response = await axios.get(`api/v1/supplier/${this.SupplierId}/getProjectsSummary`);
            let summary = response.data;

            let review = summary.review;
            let preapproved = summary.preapproved;
            let contracts = summary.contracts;
            let approved = summary.approved;
            let funding = summary.funding;
            let installation = summary.installation;

            this.slas.push({
               title: "En revisión",
               description: reviewText,
               onTime: review.info.onTime,
               delayed: review.info.delayed,
               rgOnTime: review.rgInfo.onTime,
               rgDelayed: review.rgInfo.delayed,
               amount: this.costFormat(review.amount),
               total: review.total,
               status: 2,
            });

            this.slas.push({
               title: "Pre-aprobado",
               description: preapprovedText,
               onTime: preapproved.info.onTime,
               delayed: preapproved.info.delayed,
               rgOnTime: preapproved.rgInfo.onTime,
               rgDelayed: preapproved.rgInfo.delayed,
               amount: this.costFormat(preapproved.amount),
               total: preapproved.total,
               status: 3,
            });

            this.slas.push({
               title: "Aprobado",
               description: approvedText,
               onTime: approved.info.onTime,
               delayed: approved.info.delayed,
               rgOnTime: approved.rgInfo.onTime,
               rgDelayed: approved.rgInfo.delayed,
               amount: this.costFormat(approved.amount),
               total: approved.total,
               status: 4,
            });

            this.slas.push({
               title: "En fondeo",
               description: fundingText,
               onTime: funding.info.onTime,
               delayed: funding.info.delayed,
               rgOnTime: funding.rgInfo.onTime,
               rgDelayed: funding.rgInfo.delayed,
               amount: this.costFormat(funding.amount),
               total: funding.total,
               status: 5,
            });

            this.slas.push({
               title: "En firma de contratos",
               description: contractsText,
               onTime: contracts.info.onTime,
               delayed: contracts.info.delayed,
               rgOnTime: contracts.rgInfo.onTime,
               rgDelayed: contracts.rgInfo.delayed,
               amount: this.costFormat(contracts.amount),
               total: contracts.total,
               status: 6,
            });

            this.slas.push({
               title: "En instalación",
               description: installationText,
               onTime: installation.info.onTime,
               delayed: installation.info.delayed,
               rgOnTime: installation.rgInfo.onTime,
               rgDelayed: installation.rgInfo.delayed,
               amount: this.costFormat(installation.amount),
               total: installation.total,
               status: 7,
            });

         } catch (error) {
            this.warn(error);
         }
      },

      async showProjectsForStatus(status){
         await this.$router.push({name: 'misProyectos', query: {status: status}});
      },
   }
}
</script>

<style scoped>
   .pre-formatted {
      white-space: pre;
   }
</style>