<template>
    <div id="supplier_first_check" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">Verifica tu información</h1>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div v-if="isMounted" class="main-form mt-base">
                                            <div class="vx-row">
                                                <!-- SOCIAL NETWORKS -->
                                                <div :class="colClass">
                                                    <h6 class="mb-2">Redes sociales</h6>
                                                    <template v-if="!isEditing">
                                                        <ul class="ul-auto">
                                                            <li v-for="(sn, index) in currentSocialNetworks" :key="index">
                                                                <strong>{{ sn.snNamed }}: </strong> {{ sn.url }}
                                                            </li>
                                                        </ul>
                                                    </template>
                                                    <template v-else>
                                                        <!-- forms -->
                                                        <div class="w-full mb-4">
                                                            <p class="bold">Tus redes sociales</p>
                                                            <div class="vx-row">
                                                                <div class="vx-col sm:w-full md:w-2/5">
                                                                    <!-- TIPO DE RED SOCIAL -->
                                                                    <vs-select placeholder="Selecciona una opción"
                                                                        class="w-full" label="Red social"
                                                                        v-model.lazy="socialNetwork" name="socialNetwork"
                                                                        v-validate="requiredRules"
                                                                        :danger="hasError('socialNetwork')"
                                                                        :danger-text="errorText('socialNetwork')"
                                                                        :success="isSuccess('socialNetwork')">
                                                                        <vs-select-item :key="index" :value="item.value"
                                                                            :text="item.label"
                                                                            v-for="(item, index) in collections.socialNetworkList" />
                                                                    </vs-select>
                                                                </div>
                                                                <div class="vx-col sm:w-full md:w-2/5">
                                                                    <!-- URL DE LA RED SOCIAL -->
                                                                    <vs-input class="w-full mb-4" label="Url" type="url"
                                                                        name="url" v-validate="requiredRulesUrl"
                                                                        :danger="hasError('url')"
                                                                        :danger-text="errorText('url')"
                                                                        :success="isSuccess('url')" v-model.lazy="url" />    
                                                                    <vs-input-hint><i>https://www.example.com/red-social/</i></vs-input-hint>
                                                                </div>
                                                                <div class="vx-col sm:w-full md:w-1/5">
                                                                    <!-- ACCIONES -->
                                                                    <vs-button @click.stop="addSocialNetwork()" color="dark"
                                                                        class="mt-6">Agregar</vs-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <vs-divider></vs-divider>
                                                        <!-- LISTA DE REDES SOCIALES -->
                                                        <div class="w-full mb-4">
                                                            <div v-for="(sn, index) in socialNetworkArray"
                                                                class="mb-2 social-network">
                                                                <div class="close-btn"
                                                                    @click.stop="deleteSocialNetwork(index)">
                                                                    <feather-icon icon="TrashIcon"
                                                                        svgClasses="w-5 h-5 hover:text-primary stroke-current cursor-pointer" />
                                                                </div>
                                                                <p>
                                                                    <feather-icon v-if="hasSocialMediaIcon(sn.snNamed)" :icon="`${sn.snNamed}Icon`" svgClasses="w-5 h-4 stroke-current" class="mt-1 mr-1" />
                                                                    <feather-icon v-else icon="GlobeIcon" svgClasses="w-5 h-4 stroke-current" class="mt-1 mr-1" />
                                                                    <strong>{{ sn.snNamed }}:</strong> <a :href="sn.url">{{
                                                                        sn.url }}</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <vs-divider></vs-divider>
                                                </div>
                                                <!-- COVERAGE -->
                                                <div :class="colClass">
                                                    <h6 class="mb-2">Cobertura</h6>
                                                    <template v-if="!isEditing">
                                                        <ul class="ul-auto contacts">
                                                            <li v-for="(location, index) in locations" :key="index">
                                                                <strong>{{ location.name }}</strong> <br> {{
                                                                    location.subname }}
                                                            </li>
                                                        </ul>
                                                    </template>
                                                    <template v-else>
                                                        <supplier-cities-control :needsClasses="false" class="w-full"
                                                            ref="supplier_cities_component" :supplier_id="SupplierId"
                                                            :states="collections.states" />
                                                    </template>
                                                    <vs-divider></vs-divider>
                                                </div>
                                                <!-- CONTACTS -->
                                                <div v-for="section in allSections" :key="section.id" :class="colClass">
                                                    <div class="mb-4">
                                                        <h6 :class="section.public_description != null ? 'mb-2' : ''">{{
                                                            section.public_name }}</h6>
                                                    </div>
                                                    <div class="vx-row">
                                                        <!-- Campos automaticos -->
                                                        <ul v-if="!isEditing" class="ul-auto contacts">
                                                            <div v-if="section.id == 23">
                                                                <li>
                                                                    <strong>Dirección: </strong>{{
                                                                        business.address.full_address }}
                                                                </li>
                                                            </div>
                                                            <div v-else>
                                                                <li v-for="f in section.fields" v-if="isAutoFormField(f) && f.db_field != 'country_calling_code'">
                                                                    <div>
                                                                        <strong>{{ f.field_name }}:</strong> {{
                                                                            getContainerForField(section, f)[f.fname] }}
                                                                    </div>
                                                                </li>
                                                            </div>
                                                        </ul>
                                                        <template v-else-if="isEditing">
                                                            <form-field v-for="f in section.fields"
                                                                :ref="`section_id_${section.id}`" v-if="isAutoFormField(f)"
                                                                :key="f.id" :f="f"
                                                                :dataContainer="getContainerForField(section, f)"
                                                                :collectionsRoot="collections"
                                                                :onChangeReceptor="onFieldChange"
                                                                :hasBackofficeAccess="false"
                                                                :blockedByDocument="f.blockedByDocument"
                                                                :evaluator="evaluateCondition" :inputClassLarge="true"
                                                                :countriesList="collections.countries"
                                                                :editableByBackoffice="true" />
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <vs-alert v-if="successMssg" icon-pack="feather" icon="icon-check" class="mb-5"
                                                color="success">
                                                <span class="font-regular">{{ successMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row mt-10">
                                                <div class="vx-col w-full">
                                                    <!-- BOTONES -->
                                                    <div class="flex justify-center">
                                                        <vs-button type="border" class="mt-2 vs-button-dark"
                                                            @click="isEditing = true">Editar</vs-button>
                                                        <vs-button v-if="isEditing" class="mt-2 ml-10 vs-button-dark"
                                                            @click="executeSaveSections()">Guardar</vs-button>
                                                        <vs-button v-else class="mt-2 ml-10 vs-button-dark"
                                                            @click="nextOnboardingStep()">Continuar</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import FormField from '@components/forms/FormField.vue';
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';
import SupplierCitiesControl from '@supplier/SupplierCitiesControl.vue';
import { mapActions, mapMutations } from 'vuex';
import VsInputHint from "@components/vsInput/VsInputHint.vue";

const statePresenceVal = 0;
const cityPresenceVal = 1;
const sectionsToContainers = [
    {
        id: 10, containers: [
            { db: 'supplier_contacts', path: 'contacts', arrayDefs: { eval: 'filterGeneralContact', onNull: 'newGeneralContact' } },
            { db: 'phones', path: 'contacts', arrayDefs: { eval: 'filterGeneralContactForNumber', onNull: 'defaultPhoneObject' } },
        ]
    },
    {
        id: 17, containers: [
            { db: 'supplier_contacts', path: 'contacts', arrayDefs: { eval: 'filterCommercialContact', onNull: 'newCommercialContact' } },
            { db: 'phones', path: 'contacts', arrayDefs: { eval: 'filterCommercialContactForNumber', onNull: 'defaultPhoneObject' } },
        ]
    },
    {
        id: 18, containers: [
            { db: 'supplier_contacts', path: 'contacts', arrayDefs: { eval: 'filterOperationsContact', onNull: 'newOperationsContact' } },
            { db: 'phones', path: 'contacts', arrayDefs: { eval: 'filterOperationsContactForNumber', onNull: 'defaultPhoneObject' } },
        ]
    },
];
const arrayPaths = ['contacts', 'contacts.phone'];
const socialNetworkList = [
    { value: "sn_01_facebook", label: "Facebook" },
    { value: "sn_02_twitter", label: "Twitter" },
    { value: "sn_03_instagram", label: "Instagram" },
    { value: "sn_04_linkedin", label: "LinkedIn" },
    { value: "sn_05_tiktok", label: "TikTok" },
    { value: "sn_06_youtube", label: "Youtube" },
    { value: "sn_07_web", label: "Página Web" },
]

export default {
    name: "SupplierOnboardingSecondCheckPoint",
    mixins: [inputHelper, formatHelper, docsHelper, formHelper],
    components: {
        FormField,
        SupplierCitiesControl,
        VsInputHint
    },
    props: ["onboardingStepData", "isMoral"],
    data() {
        return {
            requiredRules: "required",
            requiredRulesUrl: "required|url",
            errorMssg: null,
            successMssg: null,
            isMounted: false,
            isEditing: false,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
            formId: 2,
            pmSections: [10, 17, 18],
            pfSections: [10, 17, 18],
            form: {},
            allSections: [],
            formSections: [],

            documentsFields: [],
            documentsStates: [],

            validatedDocuments: [],
            allDocumentFields: [],
            // objeto principal
            base: {},
            // auxiliares para evaluacion de condiciones
            mexicoCountryId: 700,
            // objetos para actualizaciones
            basePayload: {},
            validatedFields: [],
            totalFields: 0,
            totalValidFields: 0,
            totalInvalidFields: 0,
            skipMissingWarnings: false,
            saveSuccesfull: [],
            collections: {
                cities: [],
                states: [],
                countries: [],
                foreign_countries: [],
                family_groups: [],
                tools: [],
                customer_types: [],
                banks: [],
                fiscal_states: [],
                fiscal_cities: [],
                fiscal_neighborhoods: [],
                neighborhoods: [],

                cities_personal_address: [],
                states_personal_address: [],
                neighborhoods_personal_address: [],
                states_personal_fiscal_address: [],
                cities_personal_fiscal_address: [],
                neighborhoods_personal_fiscal_address: [],
                states_business_address: [],
                cities_business_address: [],
                neighborhoods_business_address: [],
                states_business_fiscal_address: [],
                cities_business_fiscal_address: [],
                neighborhoods_business_fiscal_address: [],
                states_business_owner_personal_address: [],
                cities_business_owner_personal_address: [],
                neighborhoods_business_owner_personal_address: [],
                states_personal_business_owner_address: [],
                cities_personal_business_owner_address: [],
                neighborhoods_personal_business_owner_address: [],
                socialNetworkList: socialNetworkList
            },
            locations: [],
            socialNetwork: null,
            url: null,
            socialNetworkArray: [],
        };
    },
    async mounted() {
        this.showLoading(true);
        this.isMounted = false;
        this.base = this.onboardingStepData;
        await this.getCollections();
        await this.getSections();
        await this.loadSavedCities();
        if (this.base.social_networks != null) {
            this.socialNetworkArray = JSON.parse(this.base.social_networks);
        }
        this.isMounted = true;
        this.showLoading(false);
    },
    computed: {
        current_section() {
            return this.isMoral ? this.pmSections[0] : this.pfSections[0];
        },
        personal() {
            return this.base.user.personal;
        },
        business() {
            return this.base.user.business;
        },
        should_fill_personal_fiscal_address() {
            return !this.base.user.personal.fiscal_address.address_equals_fiscal_address;
        },
        should_fill_business_fiscal_address() {
            return !this.business.fiscal_address.address_equals_fiscal_address;
        },
        should_fill_business_mexican_address() {
            return this.business.address.country_id == this.mexicoCountryId;
        },
        should_fill_business_foreign_address() {
            return this.business.address.country_id != this.mexicoCountryId;
        },
        should_fill_business_mexican_fiscal_address() {
            return this.should_fill_business_fiscal_address
                && this.business.fiscal_address.country_id == this.mexicoCountryId;
        },
        should_fill_business_foreign_fiscal_address() {
            return this.should_fill_business_fiscal_address
                && this.business.fiscal_address.country_id != this.mexicoCountryId;
        },
        newPhone() {
            return { country_calling_code: null, phone: null };
        },
        currentSocialNetworks() {
            return JSON.parse(this.base.social_networks);
        }
    },
    watch: {
        loading: function (newVal, oldVal) {
            if (newVal == true) {
                this.$vs.loading();
            }
            else {
                this.$vs.loading.close();
            }
        }
    },
    methods: {
        ...mapActions('auth', ['getUserInfo']),
        /* funciones de secciones */
        filterGeneralContact(collection) {
            return collection.find(f => f.type == 1);
        },
        filterCommercialContact(collection) {
            return collection.find(f => f.type == 2);
        },
        filterOperationsContact(collection) {
            return collection.find(f => f.type == 3);
        },
        /* funciones de secciones */
        filterGeneralContactForNumber() {
            return this.base.contacts.find(f => f.type == 1);
        },
        filterCommercialContactForNumber() {
            return this.base.contacts.find(f => f.type == 2);
        },
        filterOperationsContactForNumber() {
            return this.base.contacts.find(f => f.type == 3);
        },
        newGeneralContact() {
            return { type: 1, phone: this.newPhone.phone };
        },
        newCommercialContact() {
            return { type: 2, phone: this.newPhone.phone };
        },
        newOperationsContact() {
            return { type: 3, phone: this.newPhone.phone };
        },
        defaultPhoneObject() {
            return { country_calling_code: null, phone: null };
        },
        async extraValidations() {
            let ok = true;
            // validar que los 3 juegos de datos de contacto sean distintos
            let general = this.base.contacts.find(f => f.type == 1);
            let comm = this.base.contacts.find(f => f.type == 2);
            let ops = this.base.contacts.find(f => f.type == 3);

            let n1 = general.name.toLowerCase();
            let n2 = ops.name.toLowerCase();
            let n3 = comm.name.toLowerCase();

            let e1 = general.email.toLowerCase();
            let e2 = ops.email.toLowerCase();
            let e3 = comm.email.toLowerCase();

            let p1 = general.phone;
            let p2 = comm.phone;
            let p3 = ops.phone;

            if (n1 == n2 || n1 == n3 || n3 == n2) {
                console.log("name");
                ok = false;
            }
            if (e1 == e3 || e1 == e2 || e3 == e2) {
                console.log("email");
                ok = false;
            }
            if (p1 == p2 || p1 == p3 || p2 == p3) {
                console.log("phone");
                console.log("1 " + p1);
                console.log("2 " + p2);
                console.log("3 " + p3);
                ok = false;
            }

            if (!ok) {
                this.errorNotif("Error en datos de contacto",
                    "Es necesario que los datos de contacto del Director General, Director Comercial y Director de Operaciones sean distintos entre sí.", 10000);
            }
            return ok;
        },
        async loadSavedCities() {
            try {
                let res = await axios.get(`/api/v1/supplier/${this.SupplierId}/getSupplierCities`);
                let cities = res.data.cities;
                let states = res.data.states;
                this.locations = [];

                cities.forEach(f => {
                    let folio = `ct_${f.city_id}`;
                    let name = `Ciudad: ${f.name}`;
                    let subname = `En el estado de ${f.state_name}`;
                    let loc = { id: f.id, folio: folio, name: name, subname: subname, presence: cityPresenceVal };
                    this.locations.push(loc);
                });

                states.forEach(f => {
                    let folio = `st_${f.state_id}`;
                    let name = `Estado: ${f.name}`;
                    let subname = "Presencia en todo el estado";
                    let loc = { id: f.id, folio: folio, name: name, subname: subname, presence: statePresenceVal };
                    this.locations.push(loc);
                });

            }
            catch (e) {
                this.warn(e);
            }
        },
        async getCollections() {
            try {
                let collectionsObjects = ['countriesList', 'foreignCountriesList', 'statesList', 'familyGroups', 'availableSupplierTools',
                    'supplierCustomerTypesCatalog', 'banksForMexico'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
                let colls = res.data;
                this.collections.countries = colls.countriesList;
                this.collections.foreign_countries = colls.foreignCountriesList;
                this.collections.states = colls.statesList;
                this.collections.family_groups = colls.familyGroups;
                this.collections.tools = colls.availableSupplierTools;
                this.collections.customer_types = colls.supplierCustomerTypesCatalog;
                this.collections.banks = colls.banksForMexico;

            } catch (e) {
                this.warn(e);
                this.failed = true;
            }
        },
        async getSections() {
            const sections = this.isMoral ? this.pmSections : this.pfSections;
            const res = await axios.post("/api/v1/forms/getSectionsByArrayIds", { section_ids: sections });
            this.allSections = res.data.sections;

            await this.asyncForEach(this.allSections, async (s) => {
                s.containers = this.getContainersForSection(s.id);
                s.documentFields = [];

                await this.asyncForEach(s.fields, async (f) => {
                    this.formFieldSetter(f, this);
                });
            });
        },
        getContainersForSection(section_id) {
            let c = sectionsToContainers.find(f => f.id == section_id);
            if (!c) {
                this.warn("Missing containers definition for section " + section_id);
                return null;
            }
            else {
                let sectionContainers = [];
                c.containers.forEach(cc => {
                    let con = this.setContainerFromDef(cc);
                    sectionContainers.push({ db: cc.db, path: cc.path, container: con });
                })
                return sectionContainers;
            }
        },
        getContainerForField(section, f) {
            let c = section.containers.find(sc => sc.db == f.db_table);
            if (!c) {
                this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
                return null;
            }
            else {
                return c.container;
            }
        },
        setContainerFromDef(c) {
            let container = this.nestedFieldValue(this.base, c.path);
            if (Array.isArray(container)) {
                let ac = null;
                if (c.arrayDefs) {
                    if (c.arrayDefs.getFirst == true) {
                        ac = container[0];
                    }
                    else if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval](container);
                    }
                }
                if (!ac) {
                    if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                    else { ac = {} }
                    container.push(ac);
                }
                return ac;
            }
            else {
                if (c.path == 'bank_accounts_bank') {
                    let accs = this.nestedFieldValue(this.base, 'user.business.bank_accounts');
                    let nbankAcc = accs[0];
                    return nbankAcc.bank;
                }
                if (container == null && c.arrayDefs) {
                    let ac = null;
                    if (c.arrayDefs.eval) {
                        // llamado en funciones de secciones
                        ac = this[c.arrayDefs.eval]();
                        return ac;
                    }
                    if (!ac) {
                        if (c.arrayDefs.onNull) { ac = this[c.arrayDefs.onNull](); }
                        else { ac = {} }
                        container = ac;
                    }
                }
                return container;
            }
        },
        /* evaluator */
        evaluateCondition(condition) {
            return this.evaluateConditionBase(this, condition);
        },
        /* on change receptor */
        onFieldChange(method) {
            if (method != null && method in this) {
                this[method]();
            }
        },
        async nextOnboardingStep() {
            this.showLoading(true, "Verificando información...");
            await this.checkIfCanUpdateSupplierOnboardingStep("2-checkpoint");
            await this.getUserInfo("supplier");
            await this.$router.replace({name: 'home'});
            this.showLoading(false);
        },
        async executeSaveSections() {
            const sections = this.isMoral ? this.pmSections : this.pfSections;
            this.saveSuccesfull = [];
            this.showLoading(true, "Guardando información...");

            // PRIMERO SE GUARDAN LAS REDES SOCIALES.
            await this.saveSocialNetwork();

            await this.asyncForEach(sections, async (s) => {
                await this.saveSections(`section_id_${s}`);
            });

            if (this.saveSuccesfull.length == 0) {
                this.isEditing = false;
            }

            this.showLoading(false);
        },
        // GUARDAR
        async saveSections(gslug) {
            this.loading = true;
            this.skipMissingWarnings = false;
            let result = await this.runValidations(gslug);
            if (!result) {
                if (!this.skipMissingWarnings) {
                    this.saveSuccesfull.push(0);
                    this.missingFieldsNotif();
                }
            } else {

                //guardar solo la informacion que se ha modificado
                this.basePayload = this.collectFormData(gslug);
                if (this.objectIsEmpty(this.basePayload) && gslug != "knowledge") {
                    this.saveSuccessNotif();
                    this.loading = false;
                    return;
                }

                this.basePayload.id = this.base.id;
                try {
                    // ejecutar guardado
                    this.injectAccountMetadataToPayload(this.basePayload);
                    await axios.put(`/api/v1/supplier/${this.SupplierId}/deepUpdate`, this.basePayload);
                    // solicitar la informacion actualizada del modelo
                    this.showLoading(false);
                    await this.$emit("updated", 1);
                    this.saveSuccessNotif();
                }
                catch (error) {
                    this.warn(error);
                    this.failedOperationNotif(null, null);
                }
            }
            this.loading = false;
        },
        async runValidations(gslug) {
            // validar componentes tipo FormField
            let res1 = await this.validateFormFields(gslug);
            if (!res1) {
                return false;
            }

            // validar inputs instanciados en la vista
            let res2 = await this.extraValidations();
            if (!res2) {
                return false;
            }

            // retornar true si todas las validaciones fueron positivas
            return true;
        },
        resetCounters() {
            this.totalFields = 0;
            this.totalValidFields = 0;
            this.totalInvalidFields = 0;
        },
        collectFormData(gslug) {
            let payload = {};
            const sectionId = gslug.substr(11);

            let section = this.allSections.find(s => s.id == sectionId);
            section.containers.forEach(cc => {
                let fields = this.validatedFields.filter(vf => vf.db == cc.db);
                if (fields.length < 1) {
                    return;
                }

                if (cc.path == "base") {
                    fields.forEach(f => {
                        payload[f.fname] = cc.container[f.fname];
                    });
                }
                else {
                    let obj = null;
                    if (arrayPaths.includes(cc.path)) {
                        // se integran todos los objetos de la coleccion
                        obj = this.nestedFieldValue(this.base, cc.path);
                    }
                    else {
                        obj = { id: cc.container.id };
                        fields.forEach(f => {
                            obj[f.fname] = cc.container[f.fname];
                        });
                    }
                    // ajuste para objetos tipo array
                    this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
                }
            });
            return payload;
        },
        async validateFormFields(refGroup) {
            let allValid = true;
            this.validatedFields = [];
            let grefs = this.$refs[refGroup];
            let f;
            for (let p in grefs) {
                f = grefs[p];
                let r = await f.checkForValidDirty();
                if (r.valid == false) {
                    allValid = false;
                }
                else if (r.dirty == true) {
                    let sp = f.specialValidation();
                    if (sp != null && sp.method in this) {
                        let res = await this[sp.method]();
                        let n = f.rawFieldName;
                        if (!res) {
                            if (f.db_name === "rfc" && res.length === 0) {
                                continue;
                            }
                            this.skipMissingWarnings = true;
                            allValid = false;
                            f.setError(sp.error);
                            this.errorNotif(n, sp.error, 10000);
                            continue;
                        }
                    }
                    this.validatedFields.push(r);
                }
            }
            return allValid;
        },
        runAddresses() {
            if (this.isMoral) {
                this.set_zip_code_values_business_fiscal_address();
                this.set_zip_code_values_business_address();
                this.set_zip_code_business_owner_personal_address();
            } else {
                this.set_zip_code_personal_business_owner_address();
                this.set_zip_code_values_personal_address();
                this.set_zip_code_values_personal_fiscal_address();
            }
        },

        verify_zip_code_business_fiscal_address() {
            this.verifyZipCode(this.business.fiscal_address, {
                zipCodeVariable: 'zip_code_business_fiscal_address',
                statesArray: 'states_business_fiscal_address',
                citiesArray: 'cities_business_fiscal_address',
                neighborhoodsArray: 'neighborhoods_business_fiscal_address'
            });
        },
        set_zip_code_values_business_fiscal_address() {
            this.setZipCodeAddressValues(this.business.fiscal_address_id, this.business.fiscal_address, {
                zipCodeVariable: 'zip_code_business_fiscal_address',
                statesArray: 'states_business_fiscal_address',
                citiesArray: 'cities_business_fiscal_address',
                neighborhoodsArray: 'neighborhoods_business_fiscal_address'
            });
        },

        verify_zip_code_business_address() {
            this.verifyZipCode(this.business.address, {
                zipCodeVariable: 'zip_code_business_address',
                statesArray: 'states_business_address',
                citiesArray: 'cities_business_address',
                neighborhoodsArray: 'neighborhoods_business_address'
            });
        },
        set_zip_code_values_business_address() {
            this.zip_code_business_fiscal_address = '';
            this.setZipCodeAddressValues(this.business.address_id, this.business.address, {
                zipCodeVariable: 'zip_code_business_address',
                statesArray: 'states_business_address',
                citiesArray: 'cities_business_address',
                neighborhoodsArray: 'neighborhoods_business_address'
            });
        },

        verify_zip_code_personal_address() {
            this.verifyZipCode(this.personal.business_address, {
                zipCodeVariable: 'zip_code_personal_address',
                statesArray: 'states_personal_address',
                citiesArray: 'cities_personal_address',
                neighborhoodsArray: 'neighborhoods_personal_address'
            });
        },
        set_zip_code_values_personal_address() {
            this.zip_code_personal_fiscal_address = '';
            this.setZipCodeAddressValues(this.personal.business_address_id, this.personal.business_address, {
                zipCodeVariable: 'zip_code_personal_address',
                statesArray: 'states_personal_address',
                citiesArray: 'cities_personal_address',
                neighborhoodsArray: 'neighborhoods_personal_address'
            });
        },

        verify_zip_code_personal_fiscal_address() {
            this.verifyZipCode(this.personal.fiscal_address, {
                zipCodeVariable: 'zip_code_personal_fiscal_address',
                statesArray: 'states_personal_fiscal_address',
                citiesArray: 'cities_personal_fiscal_address',
                neighborhoodsArray: 'neighborhoods_personal_fiscal_address'
            });
        },
        set_zip_code_values_personal_fiscal_address() {
            this.setZipCodeAddressValues(this.personal.fiscal_address_id, this.personal.fiscal_address, {
                zipCodeVariable: 'zip_code_personal_fiscal_address',
                statesArray: 'states_personal_fiscal_address',
                citiesArray: 'cities_personal_fiscal_address',
                neighborhoodsArray: 'neighborhoods_personal_fiscal_address'
            });
        },

        verify_zip_code_business_owner_personal_address() {
            this.verifyZipCode(this.business.personal.address, {
                zipCodeVariable: 'zip_code_business_owner_personal_address',
                statesArray: 'states_business_owner_personal_address',
                citiesArray: 'cities_business_owner_personal_address',
                neighborhoodsArray: 'neighborhoods_business_owner_personal_address'
            });
        },
        set_zip_code_business_owner_personal_address() {
            this.setZipCodeAddressValues(this.business.personal.address_id, this.business.personal.address, {
                zipCodeVariable: 'zip_code_business_owner_personal_address',
                statesArray: 'states_business_owner_personal_address',
                citiesArray: 'cities_business_owner_personal_address',
                neighborhoodsArray: 'neighborhoods_business_owner_personal_address'
            });
        },

        verify_zip_code_personal_business_owner_address() {
            this.verifyZipCode(this.personal.address, {
                zipCodeVariable: 'zip_code_personal_business_owner_address',
                statesArray: 'states_personal_business_owner_address',
                citiesArray: 'cities_personal_business_owner_address',
                neighborhoodsArray: 'neighborhoods_personal_business_owner_address'
            });
        },
        set_zip_code_personal_business_owner_address() {
            this.setZipCodeAddressValues(this.personal.address_id, this.personal.address, {
                zipCodeVariable: 'zip_code_personal_business_owner_address',
                statesArray: 'states_personal_business_owner_address',
                citiesArray: 'cities_personal_business_owner_address',
                neighborhoodsArray: 'neighborhoods_personal_business_owner_address'
            });
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        isValidUrl(url) {
            try {
                new URL(url);
                return true;
            } catch (err) {
                return false;
            }
        },
        async addSocialNetwork() {
            if (this.socialNetwork == null || this.url == null || this.url == "") {
                this.missingFieldsNotif();
                return;
            }

            if (!this.isValidUrl(this.url)) {
                this.missingFieldsNotif(null, "La URL proporcionada no tiene el formato necesario.");
                return;
            }

            let res2 = await this.$validator.validateAll();
            if (!res2) {
                return;
            }

            const urlResponse = await this.validate_url_access(this.url);
            if (!urlResponse) {
                this.missingFieldsNotif(null, "No hemos recibido una respuesta positiva (200 - success) de la URL de monitoreo que nos proporcionaste.");
                return;
            }
            const index = this.socialNetworkArray.length;
            const newSocialNetwork = {
                index: index,
                type: this.socialNetwork,
                url: this.url,
                snNamed: this.getSocialNetworkName(this.socialNetwork).label
            };

            this.socialNetworkArray.push(newSocialNetwork);
            this.socialNetwork = null;
            this.url = null;
            this.$validator.reset();
        },
        getSocialNetworkName(socialNetwork) {
            return this.collections.socialNetworkList.find(sn => sn.value == socialNetwork);
        },
        async validate_url_access(url) {
            try {
                // The custom URL entered by user
                await fetch(url, { mode: 'no-cors' });
                return true;
            } catch (error) {
                return false
            }
        },
        deleteSocialNetwork(id) {
            this.socialNetworkArray.splice(id, 1);
        },
        async saveSocialNetwork() {
            if (this.socialNetworkArray.length <= 0 || this.socialNetworkArray == null) {
                this.saveSuccesfull.push(0);
                this.errorMssg = "Antes de continuar, cuentanos sobre tus redes sociales.";
            } else {
                try {
                    const res = await axios.post(`/api/supplier-onboarding/post/supplier-social-network`, {
                        supplier_id: this.SupplierId,
                        social_networks: JSON.stringify(this.socialNetworkArray)
                    });
                } catch (error) {
                    this.saveSuccesfull.push(0);
                    this.errorMssg = "Tuvimos problemas para guardar la información sobre tus redes sociales. Nos puedes contactar a través del chat para recibir ayuda."
                }
            }
        },
        hasSocialMediaIcon(socialNetwork) {
            return !(
                socialNetwork != "Facebook"
                && socialNetwork != "Instagram"
                && socialNetwork != "Linkedin"
                && socialNetwork != "Twitter"
            );
        }
    },
};
</script>

<style>
.documents {
    border: 2px solid #c2c2c2;
    border-radius: 5px;
    padding: 2rem;
}

.documents ul {
    margin-left: 1rem;
}

.ul-auto {
    padding-left: 3rem;
    padding-right: 3rem;
}

.ul-auto.contacts {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.ul-auto li {
    text-align: justify;
    margin-right: 1rem;
}
</style>
