<template>
  <vx-card class="mb-base" :title="title">
    <div slot="no-body">
      <div class="post-media-container mb-6 mt-4">
        <ul class="flex post-media-list">
          <li
            class="post-media m-1 w-full">
            <a :href="$sanitizeUrl(blog)" target="_blank" rel="noopener noreferrer">
              <img class="responsive img-widget rounded" :src="media.img" alt="user-upload">      
            </a>
          </li>
        </ul>
      </div>
    </div>
  </vx-card>
</template>
<script>

export default {
  name: 'image-widget',
  props: ['media', 'title', 'blog'],
  computed: {
  }
}
</script>