<template>
  <div id="kof-potential-applicants">
    <div class="container-fluid">

      <vx-card v-if="!isProgram" class="mb-6">
        <div class="vx-row">
          <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold rg-title">Prospectos<span class="orange">.</span></h1>
          </div>
        </div>
      </vx-card>

      <vx-card v-else class="big-title-card-bg-black h-auto mb-6">
        <h1 class="rg-big-title extrabold rg-title">
            <span>Prospectos</span>.
        </h1>
      </vx-card>

      <vx-card class="mb-4" :title="isProgram ? '' : 'Tus próximos clientes.'">
        <div class="vx-row">
          <div v-if="isProgram" class="vx-col w-full mb-4">
            <p class="fix-align">
              Consulta a continuación la información más relevante de los prospectos que te han asignado.
              <br>
              <strong>Es importante que realices el seguimiento el estado en el que se encuentra el prospecto y realizar notas para mejorar la comunicación con el proveedor.</strong>
            </p>
            <vs-divider></vs-divider>
            <p class="bold mb-4 mt-4 pl-2">Filtros</p>

            <div class="vx-row">
              <div class="vx-col sm:w-full md:w-1/2 lg:w-1/4">
                <vs-select
                    v-if="programIdSelected !== null"
                    label="Zonas"
                    v-model="zoneIdSelected"
                    @change="addFilter('zone_id', $event)"
                    class="mr-2 w-full">
                  <vs-select-item text="Todas" value="0"></vs-select-item>
                  <vs-select-item
                      v-for="zone in programSelected.zones"
                      :text="zone.name"
                      :key="zone.id"
                      :value="zone.id"></vs-select-item>
                </vs-select>
              </div>
              <div class="vx-col sm:w-full md:w-1/2 lg:w-1/4">
                <vs-select
                    :disabled="zoneIdSelected == 0 && zoneSelected.regions.length < 1"
                    label="Regiones"
                    v-model="regionIdSelected"
                    class="w-full"
                    @change="addFilter('region_id', $event)">
                  <vs-select-item text="Todas" value="0"></vs-select-item>
                  <vs-select-item
                      v-for="region in zoneSelected.regions"
                      :text="region.name"
                      :key="region.id"
                      :value="region.id"></vs-select-item>
                </vs-select>
              </div>
              <div class="vx-col sm:w-full md:w-1/2 lg:w-1/4">
                <vs-select
                    label="Filtro por estado"
                    v-model="statusSelected"
                    class="align-item w-full">
                  <vs-select-item text="Todos" value="-1"></vs-select-item>
                  <vs-select-item
                      v-for="status in statusOption"
                      :text="status.text"
                      :key="status.value"
                      :value="status.value"></vs-select-item>
                </vs-select>
              </div>
              <div class="vx-col sm:w-full md:w-1/2 lg:w-1/4">
                <vs-select
                  label="Filtro por estado de proyecto"
                  v-model="projectStatusSelected"
                  class="align-item-project w-full">
                  <vs-select-item
                      v-for="status in projectStatus"
                      :text="status.text"
                      :key="status.value"
                      :value="status.value"></vs-select-item>
                </vs-select>
              </div>
            </div>

            <div class="flex pl-2">
              <vs-button @click.stop="getPotentialApplicantsByStatus()" type="filled" class="mt-6 ml-4" icon="search"></vs-button>
              <vs-button v-if="isProgramAdmin" @click.stop="getCSV()" type="filled" color="dark" class="mt-6 ml-4" icon="file_download"></vs-button>
            </div>
          </div>
          <div v-else class="vx-col w-full mb-4">
            <p class="fix-align">
              Consulta a continuación la información más relevante de los prospectos que podrán ser tus próximos clientes.
              <br>
              <strong>Es importante que actualices el estado en el que se encuentra el proceso de visita o venta y que asignes al vendedor a cargo del seguimiento.</strong>
            </p>
          </div>

          <div class="vx-col w-full">
            <vx-card>
              <vs-tabs>
                <vs-tab label="Prospectos Activos">
                  <!-- TODOS LOS PROSPECTOS -->
                  <vs-table ref="potentialApplicantsTable" :data="filteredPGMPotentialApplicants" stripe pagination search noDataText="No se han generado registros" :max-items="itemsPerPage">
                    <template slot="header">
                      <h3 class="mr-4">Lista de prospectos.</h3>
                      <div v-if="!isProgram" class="vx-row mr-1">
                        <div class="vx-col w-full">
                          <vs-select
                              label="Filtro por estado"
                              v-model="statusSelected"
                              class="align-item w-full">
                            <vs-select-item text="Todos" value="-1"></vs-select-item>
                            <vs-select-item
                                v-for="status in supplierStatusOption"
                                :text="status.text"
                                :key="status.value"
                                :value="status.value"></vs-select-item>
                          </vs-select>
                        </div>
                      </div>
                    </template>
                    <template slot="thead">
                      <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                        <vs-td :data="data[indextr].customer_uid">
                          {{ data[indextr].customer_uid }}
                        </vs-td>

                        <vs-td :data="data[indextr].customer_name">
                          {{ data[indextr].customer_name }}
                        </vs-td>

                        <vs-td :data="data[indextr].phone">
                          <strong>{{tr.owner_name}}</strong>
                          <br> tel. {{tr.country_calling_code}} {{phoneFormat(tr.phone)}}
                        </vs-td>

                        <vs-td :data="data[indextr].subregion_manager_id">
                              <strong>Zona:</strong> {{tr.subregion_manager_id !== null && tr.subregion_manager_id > 0 ? tr.subregion_manager.region_manager.region.zone.name : "Sin información"}}
                              <br> 
                              <strong>Región:</strong> {{tr.subregion_manager_id !== null && tr.subregion_manager_id > 0? tr.subregion_manager.region_manager.region.name : "Sin información"}}
                        </vs-td>

                        <vs-td v-if="tr.subregion_manager_id != null && tr.subregion_manager_id > 0">
                          <strong>{{tr.subregion_manager_id}} | {{getFullName(tr.subregion_manager.user.personal)}}</strong>
                          <br> {{tr.subregion_manager.user.email}}
                          <!-- <br> tel. {{tr.subregion_manager.user.personal.new_phone.country_calling_code}} {{phoneFormat(tr.subregion_manager.user.personal.new_phone.phone)}} -->
                          <br> tel. {{ getPhone(tr.subregion_manager) }}
                        </vs-td> 
                        <vs-td v-else>
                          Sin información
                        </vs-td>
                        <template v-if="!isProgram">
                          <vs-td :data="data[indextr].supplier_agent">
                            {{ data[indextr].supplier_agent != null ? data[indextr].supplier_agent.name : "No ha sido asignado."}}
                          </vs-td>
                        </template>
                        <template v-else>
                          <vs-td v-if="tr.supplier">
                            <strong>{{tr.supplier.id}} | {{tr.supplier.tradename}}</strong>
                            <br> {{tr.supplier.email}}
                            <br> tel. {{getPhone(tr.supplier)}}
                            <div style="cursor:pointer;" v-if="tr.supplier_agent" @click="openInfoAgent(tr.supplier_agent)">
                              <vs-chip transparent color="danger">Responsable</vs-chip>
                            </div>
                          </vs-td>
                          <vs-td v-else>
                            Sin información.
                          </vs-td>
                        </template>

                        <vs-td :data="data[indextr].status">
                          <div @click="openStatusPrompt(tr)" style="cursor:pointer;">
                            <vs-chip color="#28DE18">{{translateStatus(tr.status)}}</vs-chip>
                          </div>
                          <div v-if="tr.project" style="cursor:pointer;">
                            <vs-chip color="#f26d61">{{tr.project.status_named}}</vs-chip>
                          </div>
                        </vs-td>

                        <vs-td :data="data[indextr].id">
                          <div class="flex pl-2 mb-4">
                            <div>
                              <vx-tooltip
                                title="Asignar agente,"
                                color="dark"
                                style="cursor:pointer;margin-top:-8px"
                                class="mt-1"
                                text="Asigna al prospecto un agente par su seguimiento.">
                                <vs-button :disabled="isAgent" @click="openAgentPrompt(tr)" type="flat" class="p-2" icon="perm_identity"></vs-button>
                              </vx-tooltip>
                            </div>
                            <div>
                              <vx-tooltip
                                title="Editar prospecto."
                                color="dark"
                                style="cursor:pointer;margin-top:-8px"
                                class="mt-1"
                                text="Edita la información básica del prospecto.">
                                <vs-button @click="openEditModal(tr)" type="flat" icon="edit"></vs-button>
                              </vx-tooltip>
                            </div>
                            <vx-tooltip
                              title="Notas."
                              color="dark"
                              style="cursor:pointer;margin-top:-8px"
                              class="mt-1"
                              :class="needsPulse(tr.notes) ? 'pulse-success': ''"
                              text="Consulta las notas que se han creado para el prospectos.">
                              <vs-button @click="openNotesModal(tr)" type="flat" class="p-2" icon="message"></vs-button>
                            </vx-tooltip>
                            <vx-tooltip
                              v-if="!isProgram"
                              title="Asignar solicitud."
                              color="darprk"
                              style="float:left;cursor:pointer;margin-top:-8px"
                              class="mt-1"
                              text="Asigna una solicitud de crédito para este prospecto.">
                              <vs-button @click="openProjectModal(tr)" type="flat" class="p-2" icon="assignment"></vs-button>
                            </vx-tooltip>
                          </div>
                            <!-- <vs-button v-if="!isProgram" :disabled="isAgent" @click="openAgentPrompt(tr)" type="flat" class="p-2 black" icon="perm_identity"></vs-button> -->
                            <!-- <vs-button v-else @click="openEditModal(tr)" type="flat" class="rgred" icon="edit"></vs-button> -->
                        </vs-td>  

                        <template slot="expand">
                          <p>
                            <span class="p-4"><strong>Nombre de contacto: </strong>{{ data[indextr].owner_name }}</span>
                            <span class="p-4"><strong>Teléfono: </strong>{{tr.country_calling_code}} {{(tr.phone)}}</span>
                            <a target="_blank" :href="$sanitizeUrl(`https://www.google.es/maps?q=${data[indextr].address.geo_latitude},${data[indextr].address.geo_longitude}`)"><strong><u>Ubicación</u>  <feather-icon icon="MapPinIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current bold" /></strong></a>
                            <br>
                            <span class="p-4"><strong>Dirección: </strong>{{tr.address.full_address}}</span>
                          </p>
                        </template>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vs-tab>
      <!-- PROSPECTOS DESCARTADOS -->
                <vs-tab v-if="!isProgram" label="Prospectos Descartados">
                  <vs-table ref="potentialApplicantsTable" :data="discardedPGMPotentialApplicants" stripe pagination search noDataText="No se han generado registros" :max-items="itemsPerPage">
                    <template slot="header">
                      <h3 class="mr-4">Lista de prospectos.</h3>
                    </template>
                    <template slot="thead">
                      <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                        <vs-td :data="data[indextr].customer_uid">
                          {{ data[indextr].customer_uid }}
                        </vs-td>

                        <vs-td :data="data[indextr].customer_name">
                          {{ data[indextr].customer_name }}
                        </vs-td>

                        <vs-td :data="data[indextr].phone">
                          <strong>{{tr.owner_name}}</strong>
                          <br> tel. {{tr.country_calling_code}} {{phoneFormat(tr.phone)}}
                        </vs-td>

                        <vs-td :data="data[indextr].subregion_manager_id">
                              <strong>Zona:</strong> {{tr.subregion_manager_id !== null && tr.subregion_manager_id > 0 ? tr.subregion_manager.region_manager.region.zone.name : "Sin información"}}
                              <br> 
                              <strong>Región:</strong> {{tr.subregion_manager_id !== null && tr.subregion_manager_id > 0? tr.subregion_manager.region_manager.region.name : "Sin información"}}
                        </vs-td>

                        <vs-td v-if="tr.subregion_manager_id != null && tr.subregion_manager_id > 0">
                          <strong>{{tr.subregion_manager_id}} | {{getFullName(tr.subregion_manager.user.personal)}}</strong>
                          <br> {{tr.subregion_manager.user.email}}
                          <!-- <br> tel. {{tr.subregion_manager.user.personal.new_phone.country_calling_code}} {{phoneFormat(tr.subregion_manager.user.personal.new_phone.phone)}} -->
                          <br> tel. {{ getPhone(tr.subregion_manager) }}
                        </vs-td> 
                        <vs-td v-else>
                          Sin información
                        </vs-td>
                        <template v-if="!isProgram">
                          <vs-td :data="data[indextr].supplier_agent">
                            {{ data[indextr].supplier_agent != null ? data[indextr].supplier_agent.name : "No ha sido asignado."}}
                          </vs-td>
                        </template>
                        <template v-else>
                          <vs-td v-if="tr.supplier">
                            <strong>{{tr.supplier.id}} | {{tr.supplier.tradename}}</strong>
                            <br> {{tr.supplier.email}}
                            <br> tel. {{getPhone(tr.supplier)}}
                            <div style="cursor:pointer;" v-if="tr.supplier_agent" @click="openInfoAgent(tr.supplier_agent)">
                              <vs-chip transparent color="danger">Responsable</vs-chip>
                            </div>
                          </vs-td>
                          <vs-td v-else>
                            Sin información.
                          </vs-td>
                        </template>

                        <vs-td :data="data[indextr].status">
                          <div @click="openStatusPrompt(tr)" style="cursor:pointer;">
                            <vs-chip color="#28DE18">{{translateStatus(tr.status)}}</vs-chip>
                          </div>
                          <div v-if="tr.project" style="cursor:pointer;">
                            <vs-chip color="#f26d61">{{tr.project.status_named}}</vs-chip>
                          </div>
                        </vs-td>

                        <vs-td :data="data[indextr].id">
                          <div class="flex pl-2 mb-4">
                            <div>
                              <vx-tooltip
                                title="Asignar agente,"
                                color="dark"
                                style="cursor:pointer;margin-top:-8px"
                                class="mt-1"
                                text="Asigna al prospecto un agente par su seguimiento.">
                                <vs-button :disabled="isAgent" @click="openAgentPrompt(tr)" type="flat" class="p-2" icon="perm_identity"></vs-button>
                              </vx-tooltip>
                            </div>
                            <div>
                              <vx-tooltip
                                title="Editar prospecto."
                                color="dark"
                                style="cursor:pointer;margin-top:-8px"
                                class="mt-1"
                                text="Edita la información básica del prospecto.">
                                <vs-button @click="openEditModal(tr)" type="flat" icon="edit"></vs-button>
                              </vx-tooltip>
                            </div>
                            <vx-tooltip
                              title="Notas."
                              color="dark"
                              style="cursor:pointer;margin-top:-8px"
                              class="mt-1"
                              :class="needsPulse(tr.notes) ? 'pulse-success': ''"
                              text="Consulta las notas que se han creado para el prospectos.">
                              <vs-button @click="openNotesModal(tr)" type="flat" class="p-2" icon="message"></vs-button>
                            </vx-tooltip>
                            <vx-tooltip
                              v-if="!isProgram"
                              title="Asignar solicitud."
                              color="darprk"
                              style="float:left;cursor:pointer;margin-top:-8px"
                              class="mt-1"
                              text="Asigna una solicitud de crédito para este prospecto.">
                              <vs-button @click="openProjectModal(tr)" type="flat" class="p-2" icon="assignment"></vs-button>
                            </vx-tooltip>
                          </div>
                            <!-- <vs-button v-if="!isProgram" :disabled="isAgent" @click="openAgentPrompt(tr)" type="flat" class="p-2 black" icon="perm_identity"></vs-button> -->
                            <!-- <vs-button v-else @click="openEditModal(tr)" type="flat" class="rgred" icon="edit"></vs-button> -->
                        </vs-td>  

                        <template slot="expand">
                          <p>
                            <span class="p-4"><strong>Nombre de contacto: </strong>{{ data[indextr].owner_name }}</span>
                            <span class="p-4"><strong>Teléfono: </strong>{{tr.country_calling_code}} {{(tr.phone)}}</span>
                            <a target="_blank" :href="$sanitizeUrl(`https://www.google.es/maps?q=${data[indextr].address.geo_latitude},${data[indextr].address.geo_longitude}`)"><strong><u>Ubicación</u>  <feather-icon icon="MapPinIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current bold" /></strong></a>
                            <br>
                            <span class="p-4"><strong>Dirección: </strong>{{tr.address.full_address}}</span>
                          </p>
                        </template>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vs-tab>
      <!-- REPORTE GLOBAL -->
                <vs-tab v-if="!isProgram"  label="Reporte Global">
                  <progress-report-widget></progress-report-widget>
                </vs-tab>
              </vs-tabs>
            </vx-card>
          </div>
        </div>
      </vx-card>
    </div>

    <!-- POPUP ASIGNAR VENDEDOR -->
      <vs-popup
        title="Asignar solicitud al prospecto."
        :active.sync="showLoanRequestPrompt">
        <div class="vx-row">

        </div>
        <div class="vx-col w-full">
          <vx-card title="Asignar solicitud al prospecto.">
            <div class="vx-col w-full">
              <p class="bold mb-4">
                Asigna una solicitud de crédito a este prospecto y así mantener alimentado nuestro reporte de desempeño.
              </p>
            </div>
            <div class="vx-col w-full mb-4">
              <label for="" class="vs-input--label">Lista de solicitudes disponibles</label>
              <v-select
                label="text"
                class="vs-custom mb-4"
                v-model.lazy="project_id"
                name="agent_id"
                :options="getProjectList"
                :reduce="text => text.value"
                :clearable="false"
                :searchable="true"
                :placeholder="getProjectList.length < 1 ? noDataPlaceholder : selectPlaceholder"
                :disabled="getProjectList.length < 1"
              >
              <div slot="no-options">No hay coincidencias</div>
              </v-select>

              <div class="vx-col w-full mb-4">
                <p>Nota: Sólo se pueden visualizar solicitudes que no hayan sido rechazadas o archivadas. </p>
              </div>

              <vs-button :disabled="!project_id" @click="requestAssignProject()" color="primary" class="mr-5 mt-2">Asignar</vs-button>
              <vs-button @click="closeProjectPrompt()" type="flat" color="primary">Cerrar</vs-button>
            </div>
          </vx-card>
        </div>
      </vs-popup>

    <!-- POPUP ASIGNAR VENDEDOR -->
      <vs-popup
        id="agentsControl"
        title="Asignar responsable al prospecto"
        :active.sync="showAssignAgentPrompt">
        <div class="con-exemple-prompt">
          <label for="" class="vs-input--label">Lista de vendedores disponibles</label>
          <v-select
            label="text"
            class="vs-custom"
            v-model.lazy="agent_id"
            name="agent_id"
            :options="getAgentsList"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            :placeholder="getAgentsList.length < 1 ? noDataPlaceholder : selectPlaceholder"
            :disabled="getAgentsList.length < 1"
          >
          <div slot="no-options">No hay coincidencias</div>
          </v-select>

          <vs-button :disabled="!agent_id" @click="requestAssignAgent()" color="primary" class="mr-5 mt-2">Asignar</vs-button>
          <vs-button @click="closeAgentPrompt()" type="flat" color="primary">Cerrar</vs-button>
        </div>
      </vs-popup>
    
    <!-- ACTUALIZAR STATUS DE PROYECTO -->
    <vs-popup
      id="statusPopUp"
      title="Actualizar estado de prospecto"
      :active.sync="showStatusPrompt">
      <div v-if="currentCustomer != null" class="con-exemple-prompt">
        <div class="vx-row mb-6">
          <div class="vx-col w-1/2">
            <vx-card class="mb-4" title="Línea del tiempo.">
              <vx-timeline class="mb-4" :data="getTimeLineData()" :isCustom="true"/>
              <div v-if="currentCustomer.status == 1 || currentCustomer.status == 2">
                Este prospecto fue rechazado por la siguiente razón: 
                <p style="word-break: break-word;" >{{translateStatusTimeLine(currentCustomer.status)||"--"}}</p>
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-1/2">
            <vx-card title="Actualizar estado.">
              <!-- ESTATUS -->
              <div class="w-full mb-4">
                <label for="" class="vs-input--label">Estados disponibles</label>
                <v-select
                  label="text"
                  class="vs-custom"
                  v-model.lazy="status_id"
                  name="status_id"
                  :options="statusOption"
                  :reduce="text => text.value"
                  :clearable="false"
                  :searchable="true"
                  :placeholder="statusOption.length < 1 ? noDataPlaceholder : selectPlaceholder"
                  :disabled="statusOption.length < 1 || isDisabled"
                >
                <div slot="no-options">No hay coincidencias</div>
                </v-select>
              </div>
              <template v-if="status_id == 1 || status_id == 2">
                <!-- REJECTED_TYPE -->
                <div class="w-full mb-4">
                  <label for="" class="vs-input--label">Razón</label>
                  <v-select
                    label="text"
                    class="vs-custom"
                    v-model.lazy="rejectOption"
                    name="rol_option"
                    :options="getOptionsByRole(status_id)"
                    :reduce="text => text.value"
                    :clearable="false"
                    :searchable="true"
                    :placeholder="rolOption.length < 1 ? noDataPlaceholder : selectPlaceholder"
                    :disabled="rolOption.length < 1"
                  >
                  <div slot="no-options">No hay coincidencias</div>
                  </v-select>
                </div>
                <!-- OTHER -->
                <div v-if="rejectOption == 99" class="w-full mb-4">
                  <label for="">Otro</label>
                  <vs-textarea v-model.lazy="rejectText" name="reject_reason" id="reject_reson" cols="30" rows="5"></vs-textarea>
                </div>
              </template>
              <vs-button :disabled="status_id == null || isDisabled" @click="requestUpdateStatus()" :color="isProgram? 'dark':'primary'" class="mr-5 mt-2">Actualizar</vs-button>
              <vs-button @click="closeStatusPrompt()" type="flat" color="primary">Cerrar</vs-button>
            </vx-card>
          </div>
        </div>
      </div>
    </vs-popup>

    <vs-popup id="PApplicantControl" v-if="currentPotentialApplicant" :active.sync="showPotentialApplicantPromt" title="Información de prospecto">
      <create-potential-applicant-control
        ref="createPotentialApplicantControl"
        :currentPotentialApplicant="currentPotentialApplicant"
        @on-save="handleOnSave()"
        @on-close="showPotentialApplicantPromt = false"
      />
    </vs-popup>

    <!-- INFORMACIÓN DEL VENDEDOR -->
    <vs-popup v-if="agent" :active.sync="showAgentInfoPromt" title="Información del responsable">
      <div class="vx-row">
        <div class="vx-col w-full">
        <h6>Aquí se muestra la información del responsable que generó la solicitud</h6>
        </div>
        <div class="vx-col w-full mt-5">
            <vs-input
                class="w-full"
                label="Nombre"
                name="name"
                v-model.lazy="agent.name"
                icon-pack="feather"
                placeholder="Nombre del responsable"
                disabled
            />
        </div>
        <div class="vx-col w-full mt-5">
            <vs-input
                class="w-full"
                label="Teléfono"
                name="phone"
                v-model.lazy="agent.phone"
                icon-pack="feather"
                placeholder="Teléfono"
                disabled
            />
        </div>
        <div class="vx-col w-full mt-5">
            <vs-input
                class="w-full"
                label="Correo electónico"
                name="email"
                v-model.lazy="agent.user.email"
                icon-pack="feather"
                placeholder="Correo electónico"
                disabled
            />
        </div>
      </div>
    </vs-popup>

    <!-- NOTAS -->
    <vs-popup id="NotesControl" v-if="showNotesControl" :active.sync="showNotesControl" title="Notas de prospecto">
      <notes-control
        :notes="currentCustomer.notes"
        :status="currentCustomer.status"
        :isProgram="isProgram"
        :currentUserId="user.user_id"
        :potentialApplicantId="currentCustomer.id"
        :auxRol="user.auxGuardName ? user.auxGuardName : null"
        @on-save="reloadData()"
      />
    </vs-popup>

    <vs-popup id="ProjectsFWControl" ref="projects-wf-control" v-if="showPWFControl" :active.sync="showPWFControl" title="Información adicional">
      <projects-without-finance-control 
        :current-potential-applicant="currentCustomer"
        @on-save="finishProjectWF()"
        @on-close="finishProjectWF()"
      ></projects-without-finance-control>
    </vs-popup>

  </div>
</template>

<script>
  import formatHelper from "@mixins/formatHelper";
  import dateHelper from "@mixins/dateHelper";
  // src\views\programs\kof\modals\CreatePotentialApplicantControl.vue
  import CreatePotentialApplicantControl from "@views/programs/kof/modals/CreatePotentialApplicantControl.vue"
  import NotesControl from "@views/programs/kof/components/NotesControl.vue";
  import { mapState } from "vuex";
  import ProgressReportWidget from '../../../../components/supplier/widgets/ProgressReportWidget.vue';
  import ProjectsWithoutFinanceControl from "../../../programs/kof/modals/ProjectsWithoutFinanceControl.vue";
  
  const itemsPerPageDefinitions = [5,10,25,50];

  const columns = [
    { title: "CLIENTE", key: "customer_uid", sortable: true }
    , { title: "NOMBRE CLIENTE", key: "name", sortable: true }
    , { title: "PROSPECTO", key: "retailer", sortable: true }
    , { title: "ZONA/REGIÓN", key: "zone_region", sortable: true }
    , { title: "LÍDER DE VENTA", key: "subregion_manager", sortable: true }
    , { title: "RESPONSABLE", key: "supplier_agent", sortable: true }
    , { title: "ESTADO", key: "status", sortable: true }
    , { title: "ACCIONES", key: "", sortable: false }
  ];

  const statusOption = [
    { value: 0, text: "Pendientes de contactar" }
    , { value: 6, text: "Problema en comunicación" }
    , { value: 1, text: "Descartados por instalador" }
    , { value: 2, text: "Descartados sin interés del cliente" }
    , { value: 3, text: "Interesados en recibir propuesta" }
    , { value: 4, text: "Con propuesta realizada" }
    , { value: 5, text: "Instalado sin financiamiento" }
  ];  

  const supplierStatusOption = [
    { value: 0, text: "Pendientes de contactar" }
    , { value: 6, text: "Problema en comunicación" }
    , { value: 3, text: "Interesados en recibir propuesta" }
    , { value: 4, text: "Con propuesta realizada" }
  ]
  
  const rolOption = [
    { value: 0, text: "Bajo consumo", type:"supplier" }
    , { value: 1, text: "Servicio irregular con CFE", type:"supplier" }
    , { value: 2, text: "Inseguridad en la zona", type:"supplier" }
    , { value: 3, text: "Difícil acceso", type:"supplier" }
    , { value: 4, text: "Sin permiso del techo", type:"supplier" }
    , { value: 5, text: "Ya cuenta con paneles", type:"supplier" }
    , { value: 0, text: "Sin capacidad económica", type:"applicant" }
    , { value: 2, text: "Desconfianza en la tecnología", type:"applicant" }
    , { value: 3, text: "Rechazó por el costo del proyecto", type:"applicant" }
    , { value: 4, text: "Rechazó por el costo de financiamiento", type:"applicant" }
    , { value: 6, text: "No estaba enterado del programa", type:"applicant" }
    , { value: 99, text: "Otro", type:"both" }
  ];

  const projectStatus = [
    { value: "-1", text: "Todos" }
    , { value: "3", text: "Pre-aprobado" }
    , { value: "4", text: "Aprobado" }
    , { value: "5", text: "En fondeo" }
    , { value: "6", text: "Firma de contratos" }
    , { value: "7", text: "En instalación" }
    , { value: "8", text: "Entregado" }
];

export default {
  name: "potential-applicants",
  mixins: [formatHelper,dateHelper],
  props: {
    isProgram: { type: Boolean, required: false, default: false },
    currentStatus: { type: [Number, String], required: false, default: null },
    currentZoneId: { type: [Number, String], required: false, default: 0 },
    currentRegionId: { type: [Number, String], required: false, default: 0 },
    isFromPopUp: { type: Boolean, required: false, default: false },
  },
  components: {
    CreatePotentialApplicantControl
    , NotesControl
    , ProgressReportWidget
    , ProjectsWithoutFinanceControl
  },
  data() {
    return  {
      isMounted: false,
      potentialApplicants: [],
      itemsPerPageDefinitions: itemsPerPageDefinitions,
      itemsPerPage: itemsPerPageDefinitions[2],
      columns: columns
      , selected: []
      , showAssignAgentPrompt: false
      , agent_id: null
      , allAgents: []
      , currentCustomer: null
      , selectPlaceholder: "Seleccione una opción"
      , noDataPlaceholder: "No hay opciones disponibles"
      , showStatusPrompt : false
      , statusOption: statusOption
      , supplierStatusOption: supplierStatusOption
      , projectStatus: projectStatus
      , status_id : null
      , showPotentialApplicantPromt: false
      , currentPotentialApplicant: null
      , showAgentInfoPromt: false
      , agent: null
      , showNotesControl: false
      , rolOption: rolOption
      , rejectOption: null
      , rejectText: null
      , filters: {}
      , programs: []
      , zoneIdSelected: 0
      , regionIdSelected: 0
      , showLoanRequestPrompt: false
      , project_id: null
      , collections: {
        projects: []
      }
      , statusSelected: -1
      , notesToRead: []
      , projectStatusSelected: -1
      , showPWFControl: false
    }
  },
  async created() {
    if(this.isProgram){
      const {data} = await axios.get(`/api/pgm/me/programs`)
      this.programs = data;
      if(this.isFromPopUp){
        await this.getPotentialApplicantsByStatus();
      }
    } else {
      this.getCollections();
      this.getPotentialApplicants();
      if(!this.isAgent) {
        this.getAgentList();
      }
    }
    this.getNotesToRead();
    this.isMounted = true;
  },
  computed: {
    ...mapState("auth", ["user"]),
    currentItemsPage () {
      if (this.isMounted) {
        return this.$refs.potentialApplicantsTable.currentx;
      }
      return 0
    },
    queriedItems () {
      return this.$refs.potentialApplicantsTable ? this.$refs.potentialApplicantsTable.queriedResults.length : this.potentialApplicants.length
    },
    isAgent () {
      return this.$acl.check('isAgent');
    },
    getAgentsList () {
      return this.allAgents.map((agent) => ({ value: agent.id, text: agent.name}));
    },
    programSelected() {
      let program = this.programs.filter(p => p.id === this.programIdSelected)
      return program[0] ?? null;
    },
    zoneSelected() {
      let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
      return zone[0] ?? {regions: []};
    },
    isDisabled() {
      if(this.AuxGuardName == "executive_manager"){
        return true;
      } 
      if(this.currentCustomer.project != null){
        return true;
      }
      return false;
    },
    getProjectList() {
			return this.collections.projects.map((project) => ({ value: project.id, text: project.select_name }));
		},
    isProgramAdmin() {
      return this.$acl.check("isProgramAdmin");
    },
    filteredPGMPotentialApplicants() {
      if(this.isProgram){
        if(this.statusSelected == -1 ){
          if(this.projectStatusSelected == -1){
            return this.potentialApplicants;
          } else {
            let withProject = this.potentialApplicants.filter(x => x.project != null);
            return withProject.filter(y => y.project.status == this.projectStatusSelected);
          }
        }

        if(this.potentialApplicants.length > 0) {
          return this.potentialApplicants.filter(x => x.status == this.statusSelected);
        } 

        return [];
      } else {
        if(this.potentialApplicants.length > 0) {
          if(this.statusSelected == -1 ) {
            return this.potentialApplicants.filter(x => (x.status != 1 && x.status != 2 && x.status != 5));
          } else {
            return this.potentialApplicants.filter(x => (x.status == this.statusSelected));
          }
        } 
        return [];
      }
    },
    discardedPGMPotentialApplicants() {
      if(this.potentialApplicants.length > 0) {
        return this.potentialApplicants.filter(x => (x.status == 1 || x.status == 2 || x.status == 5));
      } 

      return [];
    },
    isProgramCommercialManager() {
      return this.$acl.check('isProgramCommercialManager');
		},
    isSupplierOrAgent(){
      return this.$acl.check('isSupplierOrAgent');
    },
    programIdSelected () {
        return this.currentProgramData.id;
    }
  },
  methods: {
    async getNotesToRead(){
      try {
        if(this.isProgramCommercialManager || this.isSupplierOrAgent){
          const res = await axios.get(`/api/kof/get/notes-to-read/${this.UserId}`);
          this.notesToRead = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    needsPulse (notes){
      let flag = false;
      for (let index = 0; index < notes.length; index++) {
        const element = notes[index];
        flag = this.notesToRead.some(x => x.pgm_pa_notes_id == element.id);
        if(flag){
          break;
        }
      }
      return flag;
    },
    async deleteNotesToRead(notes){
      try {
        if(this.needsPulse(notes)){
          const noteIds = notes.map(function(x) {return x.id});
          await axios.post(`/api/kof/delete/notes-to-read/${this.UserId}`, { 'note_ids' : noteIds });
          this.getNotesToRead();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPotentialApplicants() {
      try {
        this.showLoading(true);
        const role = this.isAgent ? "supplier_agent" : "supplier";
        const id = this.isAgent ? this.AgentId : this.SupplierId;
        const res = await axios.get(`/api/kof/get/potentialApplicants/${role}/${id}`);
        this.potentialApplicants = res.data;
        this.showLoading(false);
      } catch (error) {
        console.error(error);
        this.showLoading(false);
      }
    },
    async getAgentList() {
      try {
        this.showLoading(true);
        const res = await axios.get("/api/supplier/agents");
        this.allAgents = res.data;
        this.showLoading(false);
      } catch (error) {
        console.error(error);
        this.showLoading(false);
      }
    },
    async requestAssignAgent() {
      let ok = this.agent_id == null;
      if(ok){
        this.missingFieldsNotif();
        return;
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Asignar vendedor a prospecto.',
        text: `¿Estás seguro que deseas asignar el prospecto <<${this.currentCustomer.customer_uid} | ${this.currentCustomer.customer_name}>>}?`,
        acceptText: "Asignar agente",
        cancelText: 'Cancelar',
        accept: this.doPostAssignAgent
      });
    },
    async doPostAssignAgent(){
      this.showLoading(true);
      try {
        const payload = {supplier_agent_id: this.agent_id, customer_uids: [this.currentCustomer.customer_uid]};
        await axios.post('/api/kof/post/potentialApplicants/assign-supplier-agent', payload);
        await this.getPotentialApplicants();
        this.closeAgentPrompt();
        this.saveSuccessNotif();
      }
      catch (error) {
        this.warn(error);
        this.failedOperationNotif();
      }
      this.showLoading(false);
    },
    getOptionsByRole(status){
      let role = "applicant";
      if(status == 1 ){
        role = "supplier";
      }
      return rolOption.filter(x => (x.type==role || x.type =="both"))
    },
    async requestUpdateStatus() {
      let ok = this.status_id == null;
      if(ok){
        this.missingFieldsNotif();
        return;
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Actualizar estado prospecto.',
        text: `¿Estás seguro que deseas actualizar el estado para el prospecto <<${this.currentCustomer.customer_uid} | ${this.currentCustomer.customer_name}>>}?`,
        acceptText: "Actualizar estado",
        cancelText: 'Cancelar',
        accept: this.doUpdateStatus
      });
    },
    async doUpdateStatus(){
      if(this.status_id == 5){
        this.showStatusPrompt = false;
        this.showPWFControl = true;
        return;
      }

      this.showLoading(true);
      try {
        const payload = {
          status: this.status_id,
          customer_uids: [this.currentCustomer.customer_uid],
          reject_reason: this.rejectOption,
          reject_text: this.rejectText
          };
        await axios.put('/api/kof/put/potentialApplicants/status', payload);
        if(this.isProgram){
          await this.getPotentialApplicantsByStatus();
        } else {
          await this.getPotentialApplicants();
        }
        this.closeStatusPrompt();
        this.showPWFControl = false;
        this.saveSuccessNotif();
      }
      catch (error) {
        this.warn(error);
        this.failedOperationNotif();
      }
      this.showLoading(false);
    },
    translateStatus(status){
      let statusName = "N/A";
      switch(status){
        case 0: 
          statusName = "Pendientes de contactar";
          break;
        case 1: 
          statusName = "Descartados por instalador";
          break;
        case 2: 
          statusName = "Descartados sin interés del cliente";
          break;
        case 3: 
          statusName = "Interesados en recibir propuesta";
          break;
        case 4: 
          statusName = "Con propuesta realizada ";
          break;
        case 5: 
          statusName = "Instalado sin financiamiento";
          break;
        case 6: 
          statusName = "Problemas en comunicación";
          break;
      }

      return statusName;
    },
    openAgentPrompt(customer) {
      this.currentCustomer = customer;
      this.agent_id = null;
      this.showAssignAgentPrompt = true;
    },
    closeAgentPrompt() {
      this.showAssignAgentPrompt = false;
      this.agent_id = null;
      this.currentCustomer = null;
    },
    closeProjectPrompt() {
      this.showLoanRequestPrompt = false;
      this.project_id = null;
      this.currentCustomer = null;
    },
    openStatusPrompt(customer) {
      this.currentCustomer = customer;
      this.showStatusPrompt = true;
    },
    closeStatusPrompt() {
      this.rejectOption = null;
      this.rejectText = null;
      this.status_id = null;
      this.showStatusPrompt = false;
      this.currentCustomer = null;
    },

    async getPotentialApplicantsByStatus() {
      try {
        this.showLoading(true);
        let status = this.currentStatus;
        if(this.isFromPopUp){
          this.zoneIdSelected = this.currentZoneId;
          this.regionIdSelected = this.currentRegionId;
        }
        let zoneSelected = (this.zoneIdSelected != 0 && this.zoneIdSelected != "0" && this.zoneIdSelected != null) ? this.zoneIdSelected : null;
        const res = await axios.get(`/api/kof/get/potentialApplicants/${status}?region=${this.regionIdSelected}&programId=${this.currentProgramData.id}&zone=${zoneSelected}`);
        this.potentialApplicants = res.data;
        this.showLoading(false);
      } catch (error) {
        console.error(error);
        this.errorNotif(
          "Error al consultar la lista de prospectos.",
          "Ha ocurrido un error, por favor intente más tarde"
        );
        this.showLoading(false);
      }
    },
    getFullName(currentItem) {
      try {
        let fullName = currentItem.first_name;

        if(currentItem.second_name)
            fullName += ` ${currentItem.second_name}`;

        fullName += ` ${currentItem.last_name_1}`;

        if(currentItem.last_name_2)
            fullName += ` ${currentItem.last_name_2}`;

        return fullName;
      } catch (error) {
        console.log(currentItem)
        return "Sin información.";
      }
    },
    getPhone(currentItem) {
      try {
        let phone = "--";

        if(currentItem.user.personal){
          if(currentItem.user.personal.new_phone) {
            phone = currentItem.user.personal.new_phone.country_calling_code + " " + this.phoneFormat(currentItem.user.personal.new_phone.phone);
          }
        } else {
          if(currentItem.user.business.new_phone){
            phone = currentItem.user.business.new_phone.country_calling_code + " " + this.phoneFormat(currentItem.user.business.new_phone.phone);
          }
        }

        return phone;
      } catch (error) {
        return "--";
      }
    },
    openEditModal(currentItem) {  
      this.currentPotentialApplicant = currentItem;
      this.showPotentialApplicantPromt = true;
    },
    async handleOnSave() {
      if(this.isFromPopUp){
        await this.getPotentialApplicantsByStatus();
      } else {
        this.getCollections();
      }
      this.currentPotentialApplicant = null;
    },
    openInfoAgent(agent){
      this.agent = null;
      this.agent = agent;
      this.showAgentInfoPromt = true;
    },
    getTimeLineData () {

      let timeLineData = [];

      let dateKeys = Object.keys(this.currentCustomer).filter(x => x.includes("date"));
      dateKeys.shift();
      dateKeys.push("created_at");
      for(const dKey of dateKeys) {
        if(this.currentCustomer[dKey] !== null){
          timeLineData.push(this.translateDataByKeyDate(dKey));
        }
      }
      return timeLineData;
    },
    translateStatusTimeLine(status) {
      let value = null
      if(status == 1){
        value = this.rolOption.filter(x => ( x.type == "supplier" ||  x.type == "both") && x.value == this.currentCustomer.supplier_reason)[0];
        if(value != undefined) {
          value = value.text;
        }
        return this.currentCustomer.supplier_reason == 99 ? value + ": " + this.currentCustomer.supplier_reason_other : value;
      } else if(status == 2) {
        value =   this.rolOption.filter(x => ((x.type == "applicant" || x.type == "both") && x.value == this.currentCustomer.potential_applicant_reason))[0];
        if(value != undefined) {
          value = value.text;
        }
        return this.currentCustomer.potential_applicant_reason == 99 ? value + ": " + this.currentCustomer.potential_applicant_reason_other : value;
      }
      return value||"--";
    },
    translateDataByKeyDate(keyDate) {
      let propertyDate = {
        title: "",
        desc: null,
        color: "warning",
        icon: "AlertCircleIcon",
        time: this.timeAgoFormat(this.currentCustomer[keyDate])

      };
      switch(keyDate){
        case "created_at":
            propertyDate.title = "Pendientes de contactar"; 
            break;
        case "supplier_rejected_date":
            propertyDate.title = "Descartado por proveedor"; 
            break;
        case "potential_applicant_not_interested_date":
            propertyDate.title = "Descartados sin interés del cliente"; 
            break;
        case "interested_in_quote_date":
            propertyDate.title = "Interesados en recibir propuesta"; 
            break;
        case "quote_delivered_date":
            propertyDate.title = "Con propuesta realizada"; 
            break;
        case "communication_issues_date":
            propertyDate.title = "Problemas de comunicación"; 
            break;
            default:  propertyDate.title = "Sin definición"; 
      }

        return propertyDate;
    }, 
    openNotesModal(item){
      this.currentCustomer = item;
      this.showNotesControl = true;
      this.deleteNotesToRead(this.currentCustomer.notes);
    },
    async reloadData() {
      if(this.isProgram){
        await this.getPotentialApplicantsByStatus();
      } else {
        await this.getPotentialApplicants();
      }
    },
    async addFilter(filterName, ev) {
      if (ev.toString() === "0") {
        delete this.filters[filterName];
      } else {
        this.filters[filterName] = ev.toString();
      }

      if(filterName == "zone_id" && ev.toString() === "0"){
          this.zoneIdSelected = 0;
          this.regionIdSelected = 0;
      }
    },
    async getCollections(){
        try {
          const role = this.isAgent ? "supplier_agent" : "supplier";
          const id = this.isAgent ? this.AgentId : this.SupplierId;
          let res = await axios.get(`/api/get/v1/forms/loan-request/list/${role}/${id}`);
          this.collections.projects = res.data;
        }
        catch (e) {
          this.warn(e);
        }
    },
    async requestAssignProject() {
      let ok = this.project_id == null;
      if(ok){
        this.missingFieldsNotif();
        return;
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Asignar solicitud a prospecto.',
        text: `¿Estás seguro que deseas asignar el la solicitud a este prospecto?`,
        acceptText: "Asignar solicitud",
        cancelText: 'Cancelar',
        accept: this.doPostAssignProject
      });
    },
    async doPostAssignProject(){
      this.showLoading(true);
      try {
        const payload = {pgm_potential_applicant: this.currentCustomer.id, project_id: this.project_id};
        await axios.post('/api/kof/post/loan-request/assign', payload);
        await this.getPotentialApplicants();
        this.closeProjectPrompt();
        this.saveSuccessNotif();
      }
      catch (error) {
        this.warn(error);
        this.failedOperationNotif();
      }
      this.showLoading(false);
    },
    openProjectModal(item){
      if(item.project != null){
        this.project_id = item.project.id;
      } else {
        this.project_id = null;
      }
      this.currentCustomer = item;
      this.showLoanRequestPrompt = true;
    },
    getCSV() {
      try {
        let url = `${this.ApiDomain}/api/get/pgm-potential-applicants/csv?access_token=${this.AccessToken}`;
        window.open(url, '_blank');
      } catch (error) {
        console.log(error);
      }
    },
    finishProjectWF() {
      this.showPWFControl = false;
      this.getPotentialApplicants();
    }
  }
}
</script>

<style>
  p.fix-align {
    margin-left: 0.5rem;
  }

  .tr-expand td .content-tr-expand, .tr-expand td .tr-expand--close {
      align-items: flex-start !important;
      justify-content: flex-start !important;
  }

  div.vs-dialog-danger {
    z-index: 100010 !important;
  }

  #PApplicantControl {
    z-index: 100001 !important;
  }

  #NotesControl {
    z-index: 100001 !important;
  }

  #statusPopUp {
    z-index: 100001 !important;
  }

  #agentsControl {
    z-index: 100001 !important;
  }

  #statusPopUp div.vs-popup {
    width: 70rem !important;
    min-height: 35rem !important;
  }

  /* .align-item {
    position: absolute;
    right: 1.5rem;
  }
  .align-item-project {
    position: absolute;
    right: 18.5rem;
  } */
</style>