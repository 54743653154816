<template>
  <div id="knowledge">
      <div class="container-fluid">
          <div class="col-md-12 mb-4">
              <div class="alt_2"></div>
              <h1 class="big_title text-center">
                  Constancia de Conocimiento
              </h1>
          </div>
          <!-- SOLICITUD -->
          <div class="col-md-12 p-2 text-justify">
                <div class="vx-col w-full">
                    <h1 class="rg-big-title extrabold mb-4">Sobre la <span class="orange">Solicitud</span>.</h1>
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Filtro inicial del proveedor.</h4>
                        <p class="ml-3">
                            En caso de que detectes que tu cliente pueda buscar un fraude, actuar con dolo o tengas algún indicio que su proyecto pueda generar un problema de corto o mediano plazo, por favor absténte de solicitar el crédito o coméntale las observaciones a tu ejecutivo de cuenta.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            En RedGirasol consideramos a los instaladores como nuestros socios, nos complementamos para cerrar proyectos en los cuales ambas partes ganamos. También, pensamos en los instaladores como nuestros ojos y pies en los proyectos, por lo que agradecemos que nos ayuden a controlar el riesgo. Tener una alta cartera vencida compromete nuestro funcionamiento y la posibilidad de seguir otorgando créditos en el mediano y largo plazo.
                        </p>
                    </div>
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Cambios en condiciones de crédito.</h4>
                        <p class="ml-3">
                            Es posible que las condiciones de crédito que se generan de forma predeterminada en el simulador de pagos puedan ser más o menos favorables para tu cliente una vez que se realice el análisis del expediente completo.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            En RedGirasol buscamos ser lo más justos posible, por lo que buscamos que la tasa que reciben los inversionistas, sea la más adecuada en relación al riesgo que están asumiendo por proyecto invertido.
                        </p>
                    </div>
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Monto máximo a financiar de $600,000 en primer proyecto del instalador.</h4>
                        <p class="ml-3">
                            El primer proyecto que el instalador realice en la Red no podrá tener un monto a financiar mayor a los $600,000.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            Al iniciar la colaboración, la curva de aprendizaje entre el instalador y RedGirasol puede llevar tiempo, por lo que buscamos empezar a conocernos sin experimentar con proyectos de gran tamaño que pudieran afectar la relación con los inversionistas.
                        </p>
                    </div>
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Enganche en proyectos de $700,000 o mayores.</h4>
                        <p class="ml-3">
                            Los proyectos con un monto igual o mayor a $700,0000 contarán con un enganche mínimo del 5% que podría variar de acuerdo al análisis crediticio.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            Este enganche mínimo nos permitirá reducir el riesgo crediticio en nuestro análisis, sin tener que aumentar la cantidad y especificaciones en los requisitos de proyectos de mayor tamaño.
                        </p>
                    </div>
                </div>
          </div>
          <!-- SOLICITUD -->

          <!-- INSTALACIÓN -->
          <div class="col-md-12 p-2 text-justify">
                <div class="vx-col w-full">
                    <h1 class="rg-big-title extrabold mb-4">Sobre la <span class="orange">Instalación</span>.</h1>
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Forma de pago (anticipo y finiquito).</h4>
                        <p class="ml-3">
                            En RedGirasol liquidamos los proyectos en dos pagos, un anticipo y un finiquito. El anticipo corresponde a un 76% (menos la comisión sobre venta) de la factura, para esto será necesario contar con el proyecto fondeado, los contratos correctamente firmados y la factura por el 100% del proyecto subida a la plataforma. El finiquito restante se entregará una vez que los anexos faltantes se encuentren firmados y en caso de los proyectos solares, se hayan interconectado.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            Queremos que instalar el proyecto no te represente un costo financiero, por lo que liberamos un anticipo que cubre prácticamente el costo del mismo en su totalidad. En el caso de los proyectos solares, la liquidación se da posterior a la interconexión, ya que nos ayuda a que los instaladores den un mayor seguimiento a los trámites con CFE.
                        </p>
                    </div>
                    <!-- <div class="mb-4">
                        <h4 class="ml-1 mb-2">Comisión sobre venta.</h4>
                        <p class="ml-3">
                            RedGirasol cobrará al instalador una comisión del 6% sobre el valor total de la factura del proyecto. Este cobro se descuenta en el pago del anticipo. 
                            <br>
                            <strong>¿Por qué?</strong><br>
                            RedGirasol no genera ingresos por los intereses pagados por el cliente, por lo que estamos obligados a cobrar comisiones a todos los usuarios.
                        </p>
                    </div> -->
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Penalización por retraso en los trabajos del proyecto.</h4>
                        <p class="ml-3">
                            En el supuesto de que existan retrasos en la entrega de todos los trabajos del proyecto que impidan concluir con la instalación o ingreso del trámite de interconexión en los 30 días posteriores a la entrega del anticipo, se generará una penalización al instalador correspondiente al 1% de la factura por cada semana de retraso, misma que se descontará del último pago y se entregará íntegramente al solicitante para cubrir sus mensualidades.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            En RedGirasol promovemos el trabajo profesional y con atención al detalle. Es por esto que nos interesa que el instalador, que realiza un proyecto que financiemos, tenga la dedicación necesaria para cumplir con la entrega en el tiempo y forma estipulados, previendo que no exista algún trabajo, trámite o cualquier otra situación adicional que impida lo anteriormente mencionado.
                        </p>
                    </div>
                </div>
          </div>
          <!-- INSTALACIÓN -->

          <!-- ENTREGA -->
          <div class="col-md-12 p-2 text-justify">
                <div class="vx-col w-full">
                    <h1 class="rg-big-title extrabold mb-4">Sobre la <span class="orange">Entrega</span>.</h1>
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Garantía de generación.</h4>
                        <p class="ml-3">
                            Todos los proyectos son acompañados de un contrato de compraventa el cual es firmado entre el instalador y el solicitante. En dicho contrato se incluyen las siguientes garantías: 
                            <br>
                            1. Fallo de operación por mala instalación: En caso de un fallo de operación por mala instalación, el instalador será responsable de cualquier costo relacionado con el mantenimiento o reposición total o parcial de equipos. 
                            <br>
                            2. Fallo de operación por defecto de fabricante: En caso de un fallo de operación por defecto de fabricante el instalador ejercerá las garantías de fabricantes correspondientes para dar mantenimiento o reponer los equipos afectados. 
                            <br>
                            3. Garantía de generación: En caso de los proyectos solares, se refiere a la falta de cumplimiento con la generación anual estimada de energía a partir del día de puesta en operación normal del sistema solar.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            La principal causa de incumplimiento de pago es inconformidad con la instalación, funcionamiento u otros problemas relacionados al proyecto. El ofrecimiento de estas garantías nos ayuda a asegurar el mismo estándar de alta calidad en la instalación y servicios en todos los proyectos financiados. Esto es fundamental ya que en RedGirasol trabajamos con más de 200 instaladores distribuidos por todo el país.
                        </p>
                    </div>
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Obligación de atención.</h4>
                        <p class="ml-3">
                            El instalador deberá responder a la solicitud de atención que realice el acreditado en máximo 72 horas hábiles y realizar la entrega de todos los trabajos correspondientes a los escenarios para un fallo de operación en el plazo máximo de un mes contado a partir de la fecha en la que el acreditado le notifique por correo electrónico con acuse de recibo.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            La percepción de atención es fundamental para la conformidad del solicitante. Necesitamos que todos los instaladores cuenten con el mismo compromiso de servicio postventa con sus clientes.
                        </p>
                    </div>
                    <div class="mb-4">
                        <h4 class="ml-1 mb-2">Apoyo en cobranza.</h4>
                        <p class="ml-3">
                            El instalador deberá realizar las visitas que sean necesarias al domicilio del solicitante cuando este se encuentre ilocalizable.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            Debido a que el instalador tuvo un contacto personal y presencial durante el proceso de la venta, tiene mayor probabilidad de lograr identificar el problema de comunicación que pueda existir con el solicitante. RedGirasol es una financiera que opera de forma 100% digital, por lo que trabajando juntos podremos mantener una mejor comunicación con los solicitantes y de esta forma, que nuestros inversionistas continúen fondeando los proyectos que nos benefician a todos.
                        </p>
                    </div><div class="mb-4">
                        <h4 class="ml-1 mb-2">Obligación de recuperación.</h4>
                        <p class="ml-3">
                            En caso de que un crédito se encuentre vencido por falta de pago por parte del solicitante, correrá por cuenta del instalador realizar la recuperación del sistema del domicilio del solicitante y resguardarlo mientras se lleva a cabo la recompra.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            Históricamente, solamente 7 de cada 100 proyectos financiados llegan a una etapa de vencimiento, para su posterior recuperación. En la mayoría de los casos, la falta de pagos se debe al incumplimiento del instalador en algunos de los puntos mencionados anteriormente. Además, esto es algo que se pide en reciprocidad y empatía con los cientos de inversionistas que ayudaron a fondear el proyecto. En términos económicos, el instalador es el usuario que mayor margen gana por proyecto fondeado.
                        </p>
                    </div><div class="mb-4">
                        <h4 class="ml-1 mb-2">Mejores condiciones por desempeño de la cartera.</h4>
                        <p class="ml-3">
                            La metodología de riesgo crediticio considera el desempeño histórico de la cartera del instalador como medida de análisis. Los instaladores cuyos solicitantes tienen buen comportamiento de pago, acceden a tasas más bajas para futuros proyectos, mientras que los instaladores cuyos solicitantes cuentan con mal historial de pagos, acceden a tasas más altas.
                            <br>
                            <strong>¿Por qué?</strong><br>
                            Hemos detectado una relación directa entre proyectos vencidos e instaladores, por lo que concluímos que el instalador debe ser considerado para estimar el riesgo de los proyectos y por lo tanto, de la tasa asignada a los mismos.
                        </p>
                    </div>
                </div>
          </div>
          <!-- ENTREGA -->
          
      </div>
  </div>
</template>

<script>
export default {
    name: 'KnowledgeClauses',
    props: ['tradename']
}
</script>

<style>

</style>