<template>
    <div id="supplier-program-report">
        <vx-card class="mb-base h-auto" title="Resumen de programa.">
            <vs-tabs>
                <vs-tab label="General" icon-pack="feather" icon="icon-book-open">
                    <div class="vx-row">
                        <!-- TABLE ONE -->
                        <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full">
                            <vx-card class="h-auto" title="Prospectos.">
                                <vs-table stripe :data="globalReport">
                                        <div slot="header">
                                            <!-- TODO -->
                                        </div>
                                        <template slot="thead">
                                            <vs-th class="bold" v-for="(col, idx) in columnsOne" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                        </template>

                                        <template slot-scope="{data}">
                                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                                <template  v-if="tr.type == 'potential_applicants'">
                                                    <vs-td :data="data[indextr].status_named">
                                                        <div v-html="data[indextr].status_named">
                                                        </div>
                                                    </vs-td>
                                                    <vs-td :data="data[indextr].value">
                                                        <p style="float:right;">
                                                            {{ data[indextr].value}}
                                                        </p>                        
                                                    </vs-td>
                                                    <vs-td :data="data[indextr].high_range">
                                                        <p style="float:right;">
                                                            {{ data[indextr].percentage.toFixed(0) }} %
                                                        </p> 
                                                    </vs-td>
                                                </template>
                                            </vs-tr>
                                        </template>
                                </vs-table>  
                            </vx-card>
                        </div>
                        <!-- TABLE TWO -->
                        <div class="vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full">
                            <vx-card class="h-auto" title="Proyectos.">
                                <vs-table stripe :data="globalReport">
                                        <div slot="header">
                                            <!-- TODO -->
                                        </div>
                                        <template slot="thead">
                                            <vs-th class="bold" v-for="(col, idx) in columnsOne" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                        </template>

                                        <template slot-scope="{data}">
                                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                                <template  v-if="tr.type == 'project'">
                                                    <vs-td :data="data[indextr].status_named">
                                                        <div v-html="data[indextr].status_named">
                                                        </div>
                                                    </vs-td>
                                                    <vs-td :data="data[indextr].value">
                                                        <p style="float:right;">
                                                            {{ data[indextr].value}}
                                                        </p>                        
                                                    </vs-td>
                                                    <vs-td :data="data[indextr].high_range">
                                                        <p style="float:right;">
                                                            {{ data[indextr].percentage.toFixed(0) }} %
                                                        </p> 
                                                    </vs-td>
                                                </template>
                                            </vs-tr>
                                            <vs-tr>
                                                <vs-td><div class="bold" style="float:right;">Total General</div></vs-td>
                                                <vs-td class="bold" style="float:right;">{{data[0].total}}</vs-td>
                                                <vs-td class="bold">
                                                    <p class="bold" style="float:right;">
                                                        100%
                                                    </p>
                                                </vs-td>
                                            </vs-tr>
                                        </template>
                                </vs-table>  
                            </vx-card>
                        </div>
                    </div>
                </vs-tab>
                <vs-tab label="Descartados" icon-pack="feather" icon="icon-user-x">
                    <discarded-dashboard
                        :supplierId="SupplierId"></discarded-dashboard>
                </vs-tab>
            </vs-tabs>
        </vx-card>
        <!-- DETALLE -->
        <vs-popup v-if="openPopUp" fullscreen :title="title" :active.sync="openPopUp">
            <potential-applicants 
            :isProgram="true" 
            :currentStatus="currentStatus"
            :isFromPopUp="true"
            :currentZoneId="zoneIdSelected"
            :currentRegionId="regionIdSelected"></potential-applicants>
        </vs-popup>
    </div>
</template>

<script>
import PotentialApplicants from "@views/supplier/programs/kof/PotentialApplicants.vue";
import DiscardedDashboard from "@views/programs/kof/components/analytics/DiscardedDashboard.vue";

const columnsOne = [
    { title: "ESTADO", key: "progress", sortable: false}
    , { title: "CANTIDAD", key: "value", sortable: false}
    , { title: "PORCENTAJE", key: "percentage", sortable: false}
];

export default {
    name: "ProgressReportWidget",
    components: {
        PotentialApplicants,
        DiscardedDashboard
    },
    data() {
        return {
            globalReport: [],
            fakeData: [],
            columnsOne: columnsOne,
            openPopUp: false,
            title: "Detalle",
            currentStatus: null
            , filters: {}
            , programs: []
            , programIdSelected: 1
            , zoneIdSelected: 0
            , regionIdSelected: 0
        }
    },
    async created() {
        this.getGlobalReport();
    },
    computed: {
        programSelected() {
            let program = this.programs.filter(p => p.id === this.programIdSelected)
            return program[0] ?? null;
        },
        zoneSelected() {
            let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
            return zone[0] ?? {regions: []};
        }
    },
    methods: {
        showDetails(item) {
            if(item.type != 'project'){
                this.title = item.status_named;
                this.currentStatus = item.status;
                this.openPopUp = true;
            }
        },
        handleClose() {
            this.currentStatus = null;
        },
        async getGlobalReport() {
            try {
                this.showLoading(true);
                const programId = 1;
                const res = await axios.get(`/api/kof/get/pgm-reports/${programId}/global-report?region=0&supplierId=${this.SupplierId}`);
                this.globalReport = res.data;
                this.showLoading(false);
            } catch (error) {
                this.errorNotif(
                    "Error al consultar el reporte de avance.",
                    "Ha ocurrido un error, por favor intente más tarde"
                );
                console.error(error);
                this.showLoading(false);
            }
                this.showLoading(false);
        },
        async getGlobalReportFiltered() {
            try {
                this.showLoading(true);
                const programId = 1;
                const res = await axios.get(`/api/kof/get/pgm-reports/${programId}/global-report?region=${this.regionIdSelected}`);
                this.fakeData = res.data;
                this.showLoading(false);
            } catch (error) {
                this.errorNotif("Ha ocurrido un error en la operación.", error.response.data);
                console.error(error.response);
                this.showLoading(false);
            }
                this.showLoading(false);
        },
        async addFilter(filterName, ev) {
            if (ev.toString() === "0") {
                delete this.filters[filterName];
            } else {
                this.filters[filterName] = ev.toString();
            }

            if(filterName == "zone_id" && ev.toString() === "0"){
                this.zoneIdSelected = 0;
                this.regionIdSelected = 0;
            }
        },
    }
}
</script>

<style>
    #filtered-report td:nth-child(3n+1) {
        cursor: pointer;
    }
</style>