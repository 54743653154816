<template>
    <div id="supplier_fiscal_profile" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <!-- <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">¡Listo!</h1>
                                            </div>
                                        </div> -->
                                        <!-- CONTENT -->
                                        <div class="main-form">
                                            <p class="mb-8">
                                                <strong>¡Felicidades!</strong> Has completado toda la información necesaria para la apertura de tu cuenta.
                                                Envíanos un mensaje a nuestro WhatsApp <strong>{{phoneFormat("8115398784")}}</strong> para terminar el proceso con el acompañamiento
                                                de un asesor RedGirasol.
                                                <br>
                                                <br>
                                                <strong>¡Ya falta poco!</strong>
                                            </p>

                                            <vs-button @click.stop="goToHome()" color="primary" class="mr-2">Envíar WhatsApp</vs-button>
                                            <vs-button @click.stop="goToCall()" color="dark">Llamar al {{ phoneFormat("8115398784") }}</vs-button>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper.js"
export default {
    name: "SupplierOnboardingCompleted",
    props: ["onboardingStepData", "isMoral"],
    mixins: [formatHelper],
    data() {
        return {

        };
    },
    async mounted() {

    },
    computed: {
        isAgent () {
            return this.$acl.check('isAgent');
        },
    },
    watch: {

    },
    methods: {
        async goToHome() {
            window.open("https://wa.me/528115398784/?text=¡Hola, he terminado con el proceso para la apertura de mi cuenta!", "_blank")
        },
        async goToCall() {
            window.open("tel:8115398784")
        }
    },
};
</script>

<style>

</style>
