<template>
    <div id="supplier_social_network" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-3/5 xxl:w-2/3 m-0">
        <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full pb-10 pt-10" style="min-height: 200px">
                                    <!-- MAIN-DIV -->
                                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                                        <!-- HEADER -->
                                        <div class="vx-row mb-4 align-center">
                                            <div class="vx-col w-full">
                                                <h1 class="text-2xl card-sub-title">Redes sociales</h1>
                                                <p>
                                                    Usaremos esta información para poder compartirla con la Red al momento de que publiquemos algún proyecto tuyo.
                                                </p>
                                            </div>
                                        </div>
                                        <!-- CONTENT -->
                                        <div v-if="isMounted" class="main-form">
                                            <div class="vx-row">
                                                <!-- EJEMPLO -->
                                                <!-- <div class="w-full mb-4 social-network">
                                                    <p>
                                                        <strong>Facebook: </strong> <a
                                                            href="https://www.facebook.com/redgirasol/">https://www.facebook.com/redgirasol/</a>
                                                    </p>
                                                </div> -->
                                                <!-- forms -->
                                                <div class="w-full mb-4">
                                                    <p class="bold mb-2">Agrega tus redes sociales</p>
                                                    <div class="vx-row">
                                                        <div class="vx-col sm:w-full md:w-2/5">
                                                            <!-- TIPO DE RED SOCIAL -->
                                                            <vs-select 
                                                                placeholder="Selecciona una opción" 
                                                                class="w-full"
                                                                label="Red social" 
                                                                v-model.lazy="socialNetwork"
                                                                name="socialNetwork" 
                                                                v-validate="requiredRules"
                                                                :danger="hasError('socialNetwork')"
                                                                :danger-text="errorText('socialNetwork')"
                                                                :success="isSuccess('socialNetwork')">
                                                                <vs-select-item :key="index" :value="item.value"
                                                                    :text="item.label"
                                                                    v-for="(item, index) in collections.socialNetworkList" />
                                                            </vs-select>
                                                        </div>
                                                        <div class="vx-col sm:w-full md:w-2/5">
                                                            <!-- URL DE LA RED SOCIAL -->
                                                            <vs-input 
                                                                id="url"
                                                                class="w-full mb-4" 
                                                                label="Url" 
                                                                type="url"
                                                                name="url"
                                                                v-validate="requiredRulesUrl"
                                                                :danger="hasError('url')"
                                                                :danger-text="errorText('url')"
                                                                :success="isSuccess('url')"
                                                                v-model.lazy="url" />
                                                                <vs-input-hint><i>https://www.example.com/red-social/</i></vs-input-hint>
                                                        </div>
                                                        <div class="vx-col sm:w-full md:w-1/5">
                                                            <!-- ACCIONES -->
                                                            <vs-button @click.stop="addSocialNetwork()" color="dark" class="mt-6">Agregar</vs-button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <vs-divider></vs-divider>
                                                <!-- LISTA DE REDES SOCIALES -->
                                                <div class="w-full mb-4">
                                                    <div v-for="(sn, index) in socialNetworkArray" class="mb-2 social-network">
                                                        <div class="close-btn" @click.stop="deleteSocialNetwork(index)">
                                                            <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current cursor-pointer" />
                                                        </div>
                                                        <p> 
                                                            <feather-icon v-if="hasSocialMediaIcon(sn.snNamed)" :icon="`${sn.snNamed}Icon`" svgClasses="w-5 h-4 stroke-current" class="mt-1 mr-1" />
                                                            <feather-icon v-else icon="GlobeIcon" svgClasses="w-5 h-4 stroke-current" class="mt-1 mr-1" />
                                                            <strong>{{ sn.snNamed }}:</strong> <a :href="sn.url">{{ sn.url }}</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <vs-alert v-if="successMssg" icon-pack="feather" icon="icon-check" class="mb-5"
                                                color="success">
                                                <span class="font-regular">{{ successMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row mt-10">
                                                <div class="vx-col w-full">
                                                    <!-- BOTONES -->
                                                    <div class="flex justify-center">
                                                        <vs-button type="border" class="mt-2 vs-button-dark mr-4"
                                                            @click="requestSkipSocialNetwork()">Saltar</vs-button>
                                                        <vs-button class="mt-2 vs-button-dark"
                                                            @click="saveSocialNetwork()">Continuar</vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END CONTENT DIV -->
                                    </div>
                                    <!-- END MAIN-DIV -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import VsInputHint from "@components/vsInput/VsInputHint.vue";
const socialNetworkList = [
    { value: "sn_01_facebook", label: "Facebook" },
    { value: "sn_02_twitter", label: "Twitter" },
    { value: "sn_03_instagram", label: "Instagram" },
    { value: "sn_04_linkedin", label: "LinkedIn" },
    { value: "sn_05_tiktok", label: "TikTok" },
    { value: "sn_06_youtube", label: "Youtube" },
    { value: "sn_07_web", label: "Página Web" },
]

export default {
    name: "SupplierOnboardingSocialNetwork",
    props: ["onboardingStepData", "isMoral"],
    components: {
        VsInputHint
    },
    data() {
        return {
            requiredRules: "required",
            requiredRulesUrl: "required|url",
            errorMssg: null,
            successMssg: null,
            isMounted: false,
            loading: false,
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: "(Requerido)",
            isMainOnboardingStepVisible: true,
            socialNetwork: null,
            url: null,
            socialNetworkArray: [],
            collections: {
                socialNetworkList: socialNetworkList
            }
        };
    },
    async mounted() {
        if (this.base.social_networks != null) {
            this.socialNetworkArray = JSON.parse(this.base.social_networks);
        }
        this.isMounted = true;

    },
    computed: {
        base() {
            return this.onboardingStepData;
        },
        personal() {
            return this.base.user.personal;
        },
        business() {
            return this.base.user.business;
        },
    },
    watch: {
        loading: function (newVal, oldVal) {
            if (newVal == true) {
                this.$vs.loading();
            }
            else {
                this.$vs.loading.close();
            }
        }
    },
    methods: {
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        isValidUrl(url) {
            try {
                new URL(url);
                return true;
            } catch (err) {
                return false;
            }
        },
        async addSocialNetwork() {
            if (this.socialNetwork == null || this.url == null || this.url == "") {
                this.missingFieldsNotif();
                return;
            }

            if (!this.isValidUrl(this.url)) {
                this.missingFieldsNotif(null, "La URL proporcionada no tiene el formato necesario.");
                return;
            }

            let res2 = await this.$validator.validateAll();
            if (!res2) {
                return;
            }

            const urlResponse = await this.validate_url_access(this.url);
            if(!urlResponse){
                this.missingFieldsNotif(null, "No hemos recibido una respuesta positiva (200 - success) de la URL que nos proporcionaste.");
                return;
            }
            const index = this.socialNetworkArray.length;
            const newSocialNetwork = {
                index: index, 
                type: this.socialNetwork,
                url: this.url,
                snNamed: this.getSocialNetworkName(this.socialNetwork).label
            };

            this.socialNetworkArray.push(newSocialNetwork);
            this.socialNetwork = null;
            this.url = null;
            this.$validator.reset();
        },
        getSocialNetworkName(socialNetwork) {
            return this.collections.socialNetworkList.find(sn => sn.value == socialNetwork);
        },
        async validate_url_access(url)
        {
            try {
                // The custom URL entered by user
                await fetch(url, {mode: 'no-cors'});
                return true;
            } catch (error) {
                return false
            }
        },
        deleteSocialNetwork(id) {
            this.socialNetworkArray.splice(id,1);
        },
        async saveSocialNetwork(){
            if(this.socialNetworkArray.length <= 0 || this.socialNetworkArray == null) {
                this.errorMssg = "Antes de continuar, cuentanos sobre tus redes sociales.";
            } else {
                try {
                    this.showLoading(true, "Guardando información sobre las redes sociales...");
                    const res = await axios.post(`/api/supplier-onboarding/post/supplier-social-network`, {
                        supplier_id: this.SupplierId,
                        social_networks: JSON.stringify(this.socialNetworkArray)
                    });
                    this.showLoading(false);
                    await this.checkIfCanUpdateSupplierOnboardingStep("social_network");
                    await this.$emit("updated", 1);
                    this.saveSuccessNotif(null, "Tus redes sociales han sido guardadas.");
                } catch (error) {
                    this.showLoading(false);
                    this.errorMssg = "Tuvimos problemas para guardar la información sobre tus redes sociales. Nos puedes contactar a través del chat para recibir ayuda."
                }
            }
        },
        hasSocialMediaIcon(socialNetwork) {
            return !(
                socialNetwork != "Facebook"
                && socialNetwork != "Instagram"
                && socialNetwork != "Linkedin"
                && socialNetwork != "Twitter"
            );
        },
        requestSkipSocialNetwork() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'warning',
                title: 'Saltar redes sociales',
                text: `¿Realmente deseas saltar la información sobre tus redes sociales? Es importante agregar sitios de consulta para que la Red te conozca.`,
                acceptText: "Saltar",
                cancelText: 'Cancelar',
                accept: this.doSkipSocialNetwork
            });
        },
        async doSkipSocialNetwork(){
            try {
                this.showLoading(true, "Estamos guardando la información sobre las redes sociales.");
                const res = await axios.put("/api/supplier-onboarding/update/supplier-social-network/skip", {
                    supplier_id: this.SupplierId,
                });
                this.showLoading(false);
                await this.checkIfCanUpdateSupplierOnboardingStep("social_network");
                await this.$emit("updated", 1);
                this.saveSuccessNotif(null, "Tu información ha sido actualizada.");
            } catch (error) {
                this.showLoading(false);
                this.failedOperationNotif();
                console.log(error);
            }
        }
    }
};
</script>

<style>
.social-network {
    border: 1px solid;
    border-radius: 15px;
    padding: 0.5rem;
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
    border-color: #28DE18;
    position: relative;
}

    .social-network p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }


    .close-btn {
        position: absolute;
        background-color: #3b3a3e;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 25px;
        right: -10px;
        top: -10px;
        color: #FFF;
        padding-top: 4px;
    }

div.vs-input {
    margin-bottom: 0rem !important;
}
</style>
