export default {
  methods: {
    async getInvestorData(iid, requiredObjects){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v2/investor/${iid}?${params}`);
        return response.data;
      }
      catch(e){
        this.warn(e);
        return null;
      }
    },
    moralTypeValue(){
      return 0;
    },
    moralForeignTypeValue(){
      return 3;
    },
    moralFinancialTypeValue(){
      return 4;
    },
    personalTypeValue(){
      return 1;
    },
    personalForeignTypeValue(){
      return 2;
    },
    userIsMoral(person_type){
      return (person_type === this.moralTypeValue() || person_type === this.moralForeignTypeValue() || person_type === this.moralFinancialTypeValue());
    },
    userIsForeign(person_type){
      return (person_type === this.moralForeignTypeValue() || person_type === this.personalForeignTypeValue());
    },
    getInvestorType(base){
      return base.user.person_type;
    },
  },
}